import sortBy from 'lodash/sortBy'
import moment from 'moment'

const userTimezone = moment.tz.guess()

const sortMessagesByDate = messages => {
  if (messages.length === 0) {
    return []
  }
  // TODO: this needs optimization
  const filteredMessages = messages.filter(
    message =>
      getDateForMessage({
        message: message.contents,
        asMoment: false,
      }) !== undefined,
  )

  if (filteredMessages.length === 0) {
    return []
  }

  return sortBy(filteredMessages, [
    message => {
      return getDateForMessage({
        message: message.contents,
        asMoment: false,
      })
    },
  ]).map(message => message.contents)
}

const getDateForMessage = ({ message, asMoment = true }) => {
  let date = null
  switch (message.type) {
    case 'outstanding-feedback':
    case 'empty-review':
      date = message.cycle.endDate
      break

    default:
      break
  }

  if (date) {
    const momentDate = moment.unix(date).tz(userTimezone)
    if (moment().tz(userTimezone).isAfter(momentDate)) {
      return undefined
    } else {
      return asMoment ? momentDate : date
    }
  } else {
    return undefined
  }
}

const groupMessagesByDueDate = ({ messages }) => {
  const messagesGroupedByDays = []

  messages.forEach(message => {
    let currentDueDate = getDateForMessage({ message })

    if (currentDueDate) {
      if (messagesGroupedByDays.length === 0) {
        // first element to be added
        messagesGroupedByDays.push([message])
      } else {
        const lastDayGroup =
          messagesGroupedByDays[messagesGroupedByDays.length - 1]
        const previousDueDate = getDateForMessage({
          message: lastDayGroup[lastDayGroup.length - 1],
        })

        // group by the time things happen
        if (previousDueDate.isSame(currentDueDate, 'hour')) {
          lastDayGroup.push(message)
        } else {
          messagesGroupedByDays.push([message])
        }
      }
    }
  })

  return messagesGroupedByDays
}

export default {
  sortMessagesByDate,
  getDateForMessage,
  groupMessagesByDueDate,
}
