import gql from 'graphql-tag'

export const GET_FEEDBACK_FORMS = gql`
  {
    formsFromOrg {
      id
      title
      formQuestions {
        id
        question {
          id
          description
          template {
            id
            type
          }
        }
      }
    }
  }
`

export const GET_QUESTION_TEMPLATES = gql`
  {
    questionTemplatesFromOrg {
      id
      type
      settings
    }
  }
`

export const CREATE_NEW_FORM = gql`
  mutation addForm(
    $formTitle: String!
    $formDescription: String!
    $newQuestions: [NewQuestionInput!]!
  ) {
    createForm(
      newForm: {
        title: $formTitle
        description: $formDescription
        questions: $newQuestions
      }
    ) {
      id
      title
    }
  }
`

export const GET_COLLEAGUES = gql`
  query colleaguesFromOrg {
    colleaguesFromOrg: getUsersFromOrg {
      id
      firstName
      lastName
      email
      avatarUrl
      avatar
      jobTitle
      groupUsers {
        id
        group {
          id
          type
          name
        }
      }
    }
  }
`
