import { Button, Modal, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import formatFileSize from '../_helpers/formatBytes'
import CropImage from './CropImage'

export default ({
  visible,
  onClose,
  onSubmit,
  src,
  maxBytes = 10 * 1000000,
  accept = '',
}) => {
  const fileInput = useRef(null)
  const cropChange = useRef(null)
  const [imageSrc, setImageSrc] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const isImage = mimeType => {
    return (
      ['image/jpeg', 'image/gif', 'image/png'].findIndex(
        x => x === mimeType,
      ) !== -1
    )
  }

  const handleClose = () => {
    if (!onClose) {
      return
    }
    setImageSrc(src)
    onClose()
  }

  const handleSave = () => {
    if (isSaving || !onSubmit) {
      return
    }
    setIsSaving(true)
    onSubmit({
      image: cropChange.current.image,
      crop: cropChange.current.crop,
    })
      .catch(e => {
        notification.warning({
          message: 'Oops!',
          description: `Something went wrong, but we're not sure what. Please try again.`,
        })
        console.error(e)
      })
      .finally(() => setIsSaving(false))
  }

  const onBrowseClick = () => {
    openFileSelector()
  }

  const openFileSelector = () => {
    fileInput.current.click()
  }

  const onCropLoadError = e => {
    notification.warning({
      message: 'Oops!',
      description: e.message,
    })
  }

  const onFileChange = () => {
    const file = fileInput.current.files[0]
    if (!file) {
      return
    }
    if (!isImage(file.type)) {
      notification.warning({
        message: 'Oops!',
        description: `That doesn't look like an image. Please pick another file.`,
      })
      return
    }
    if (file.size > maxBytes) {
      notification.warning({
        message: 'Oops!',
        description: `That image is too big. Please upload something smaller than ${formatFileSize(
          maxBytes,
        )}.`,
      })
      return
    }

    const reader = new FileReader()

    reader.onloadend = () => {
      setImageSrc(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const onCropChange = change => {
    cropChange.current = change
  }

  useEffect(() => {
    setImageSrc(src)
  }, [src])
  const footerButtons = [
    <Button
      key="browse"
      onClick={onBrowseClick}
      type="ghost"
      style={{ float: 'left' }}
    >
      Browse
    </Button>,
    <Button key="close" onClick={handleClose} type="ghost">
      Cancel
    </Button>,
    <Button key="save" onClick={handleSave} type="primary" loading={isSaving}>
      Save
    </Button>,
  ]

  return (
    <>
      <Modal
        visible={visible}
        centered
        closable={false}
        footer={footerButtons}
        afterClose={handleClose}
        width={720}
        destroyOnClose={true}
      >
        <CropImage
          src={imageSrc}
          shape="circle"
          onChange={onCropChange}
          onLoadError={onCropLoadError}
        />
      </Modal>
      )
      <input
        type="file"
        ref={fileInput}
        onChange={onFileChange}
        accept={accept}
        style={{ position: 'absolute', top: '-100%' }}
      />
    </>
  )
}
