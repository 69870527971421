const CommonActions = [
  {
    key: 'personal360Remote',
    feature: 'Personal 360',
    title: 'Working from home',
    description: `Ensure you're making a good impression`,
    route: '/personal360/create/1',
    routeState: {
      formId: process.env.REACT_APP_WFH_FORM_ID
        ? `${process.env.REACT_APP_WFH_FORM_ID}`
        : undefined,
    },
  },
  {
    key: 'personal360VideoCalls',
    feature: 'Personal 360',
    title: 'Doing video calls',
    description: `Have more impact in online meetings`,
    route: '/personal360/create/1',
    routeState: {
      formId: process.env.REACT_APP_VIDEO_FORM_ID
        ? `${process.env.REACT_APP_VIDEO_FORM_ID}`
        : undefined,
    },
  },
]

export const AdminActions = [
  ...CommonActions,
  {
    key: 'team360First',
    feature: 'Team 360',
    title: `Your team's first 360`,
    description: 'Know where every teammate stands',
    route: '/team360/create/1',
    routeState: {},
  },
  {
    key: 'team360Board',
    feature: 'Team 360',
    title: `Board of Directors`,
    description: 'Bridge contribution & communication gaps',
    route: '/team360/create/1',
    routeState: {
      formId: process.env.REACT_APP_BOARD_FORM_ID
        ? `${process.env.REACT_APP_BOARD_FORM_ID}`
        : undefined,
    },
  },
]

export const UserActions = [...CommonActions]
