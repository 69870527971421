import { useQuery } from '@apollo/react-hooks'
import { Card, Typography } from 'antd'
import sortBy from 'lodash/sortBy'
import React, { useContext } from 'react'
import Emoji from 'react-emoji-render'

import CyclesList from './../../_components/CycleCard/CyclesList'
import { handleGetCyclesResponse } from '../../Team360/helpers'
import { UserContext } from '../../UserContext'
import { GET_CYCLES_PERSONAL } from '../queries'

const { Text } = Typography

export default props => {
  const user = useContext(UserContext)

  const {
    data: dataCycles,
    error: errorCycles,
    loading: loadingCycles,
  } = useQuery(GET_CYCLES_PERSONAL, {
    variables: {
      type: 'personal',
      running: false,
    },
  })

  if (loadingCycles) {
    return <p>Loading…</p>
  }

  if (errorCycles) {
    return <p>Error!</p>
  }

  let cycles = []

  if (!loadingCycles && !errorCycles) {
    if (dataCycles && dataCycles.cycles && dataCycles.cycles.length > 0) {
      cycles = handleGetCyclesResponse({ data: dataCycles, userId: user.id })
    }
  }

  cycles = sortBy(cycles, [cycle => cycle.endDate]).reverse()

  return (
    <div className="cycles">
      <Card className="top-title">
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Personal 360
        </Text>
      </Card>
      {props.menu}
      {cycles && cycles.length > 0 ? (
        <CyclesList cycles={cycles} />
      ) : (
        <div
          style={{
            color: '#77779f',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 300,
          }}
        >
          <div style={{ margin: '16px 0 0' }}>
            Nothing to see here.
            <Emoji text={':shrug:'} style={{ fontSize: '24px' }} />
          </div>
          <div>To get the ball rolling please create a new event.</div>
        </div>
      )}
    </div>
  )
}
