import { Card, Typography } from 'antd'
import React from 'react'

const { Text } = Typography

export default props => {
  return (
    <Card {...props} className="top-title">
      <Text
        style={{
          fontFamily: 'museo, serif',
          color: '#1c1047',
          fontSize: '18px',
        }}
      >
        {props.children}
      </Text>
    </Card>
  )
}
