import React from 'react'

export const Persona360Context = {
  id: null,
  name: '',
  startDate: null,
  endDate: null,
  timezone: null,
  requestedUsers: [],
  form: null,

  setContext: personal360Details => {},
}

export default React.createContext()
