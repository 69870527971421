import gql from 'graphql-tag'

export const GET_SUBSCRIBER_MESSAGES = gql`
  query {
    messages {
      id
      contents
    }
  }
`
