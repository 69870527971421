import gql from 'graphql-tag'

export const GET_COMPANY_USERS = gql`
  query userColleagues {
    users: getUsersFromOrg {
      id
      status
      firstName
      lastName
      email
      jobTitle
      avatarUrl
      userRoles {
        id
        role {
          id
          name
          type
        }
      }
      orgRelationsFrom {
        id
        type
        to {
          id
        }
      }
      orgRelationsTo {
        id
        type
        from {
          id
          firstName
          lastName
          email
        }
      }
      groupUsers {
        id
        group {
          id
          name
          type
        }
      }
    }
  }
`

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails(
    $id: ID!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $teamId: ID
    $email: String
    $managedBy: [String]
    $manages: [String]
    $roles: [String]
  ) {
    updateUser(
      updateUserInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        teamId: $teamId
        email: $email
        managedBy: $managedBy
        manages: $manages
        roles: $roles
      }
    ) {
      id
      firstName
      lastName
      jobTitle
      email
    }
  }
`

export const SEND_INVITE = gql`
  mutation sendInvite($userId: String!) {
    resendInvite(userId: $userId)
  }
`
