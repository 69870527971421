import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { Button, Card, Collapse, Icon } from 'antd'
import shuffle from 'lodash/shuffle'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import { Avatar, AvatarName } from '../../Avatar'
import BackButton from '../../BackButton'
import Title from '../../CycleCard/Title'
import FeedbackAnswers from '../FeedbackAnswers/FeedbackAnswers'
import { GET_MANAGER_REVIEWS } from '../queries'

const { Panel } = Collapse
const DEFAULT_SPACING = 8

export default props => {
  const colleagues = props.location.state && props.location.state.colleagues
  const [reviews, setReviews] = useState([])

  const { data, error, loading } = useQuery(GET_MANAGER_REVIEWS, {
    variables: {
      cycleId: props.match.params.cycleId,
      requestedUsers: colleagues
        ? colleagues.map(colleague => colleague.key)
        : [],
    },
  })

  useEffect(() => {
    if (data && data.reviewsForManager) {
      if (data.reviewsForManager.length === 0) {
        props.history.push('/home')
      } else {
        setReviews(
          data.reviewsForManager.map(review => {
            review.feedbacks = shuffle(review.feedbacks)
            return review
          }),
        )
      }
    }
  }, [data])

  if (loading) return <p>Loading…</p>
  if (error) return <p>Error!</p>
  return (
    <div className="feedback-response">
      {reviews && reviews.length > 0 && (
        <div>
          <div className="top-title">
            <Card>
              <div style={{ fontSize: 14, color: '#f95c4b' }}>
                Your direct reports’ feedback
              </div>
              <Title
                cycle={reviews[0].cycle}
                startDate={moment.unix(reviews[0].cycle.startDate)}
                endDate={moment.unix(reviews[0].cycle.endDate)}
              />
            </Card>
          </div>
          <Collapse
            className="collapse-direct-report"
            style={{ marginTop: DEFAULT_SPACING * 4 }}
            bordered={false}
            expandIconPosition="right"
            expandIcon={panelProps => {
              return (
                <Button
                  type="primary"
                  style={{
                    width: 32,
                    height: 32,
                    padding: 0,
                    borderRadius: 10,
                  }}
                >
                  <Icon
                    type={panelProps.isActive ? 'up' : 'down'}
                    style={{ color: 'white' }}
                  />
                </Button>
              )
            }}
          >
            {reviews.map(review => (
              <Panel
                header={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Avatar user={review.user} />
                    <div style={{ marginLeft: 12 }}>
                      <AvatarName
                        style={{ fontWeight: 500, fontFamily: 'museo' }}
                        user={review.user}
                      />
                      <div style={{ fontWeight: 300 }}>
                        {review.user.jobTitle}
                      </div>
                    </div>
                  </div>
                }
                key={review.id}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '0 0 16px 16px',
                    padding: `${DEFAULT_SPACING}px ${DEFAULT_SPACING * 4}px ${
                      DEFAULT_SPACING * 2
                    }px`,
                  }}
                >
                  <div
                    className="small-letter"
                    style={{
                      marginBottom: DEFAULT_SPACING,
                    }}
                  >
                    Combined feedback from:
                  </div>
                  <div>
                    {review.feedbacks.map(feedback => (
                      <Avatar
                        key={feedback.id}
                        user={feedback.user}
                        withRightMargin
                        withBottomMargin
                      />
                    ))}
                  </div>
                </div>
                <FeedbackAnswers key={review.id} review={review} />
              </Panel>
            ))}
          </Collapse>
        </div>
      )}
      <BackButton style={{ marginTop: DEFAULT_SPACING * 3 }} />
    </div>
  )
}
