import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { Badge, Button, Card, Col, Icon, Input, List, Popover, Row } from 'antd'
import groupBy from 'lodash/groupBy'
import React, { useContext, useEffect, useState } from 'react'

import { GET_COLLEAGUES } from '../../PrivateFeedback/queries'
import { UserContext } from '../../UserContext'
import Avatar, { AvatarJobTitle, AvatarName } from '../Avatar'

const { Search } = Input

const DEFAULT_SPACING = 8
const STYLE_SELECTS_All = {
  fontWeight: 300,
  fontFamily: 'Europa',
  color: '#77779f',
  marginTop: '-16px',
  height: 'auto',
}
const STYLE_SELECTS_GROUPS = {
  ...STYLE_SELECTS_All,
  padding: 0,
}
const HOVER_TEXT_MANAGER = (
  <span className="small-letter">
    Your manager(s) are
    <br />
    automatically included
    <br />
    in a Team 360 by default.
  </span>
)
const HOVER_TEXT_SUBMITTED = (
  <span className="small-letter">
    Your colleague
    <br />
    already gave you
    <br />
    feedback
  </span>
)

// please
// keep in
// mind the
// nice pattern
// for props we have
// for this component
// and add new props mindfully
export default ({
  height,
  withSearch,
  withSelectAll,
  handleSelectMultiple,
  handleDeselectMultiple,
  selectedColleagues,
  includeUser = false,
  selectColleagueCallback,
  managersMandatory,
  selectManagerCallback,
  showAvatarSelected,
  totalNumberColleagues,
}) => {
  const user = useContext(UserContext)

  const [completedListCollegues, setCompletedListColleagues] = useState([])

  const [colleagues, setColleagues] = useState([])

  const [searchText, setSearchText] = useState('')

  const [selectedAll, setSelectedAll] = useState(false)

  const [selectedTeams, setSelectedTeams] = useState([])

  const { data, error, loading } = useQuery(GET_COLLEAGUES)

  const isColleagueSelected = colleague =>
    selectedColleagues.find(c => c.key === colleague.key)

  const isManager = colleague =>
    user.orgRelationsTo.find(
      orgRelation =>
        orgRelation.type === 'MANAGER' && colleague.id === orgRelation.from.id,
    )

  useEffect(() => {
    if (data && data.colleaguesFromOrg) {
      // remove the current logged in user, because a user can't ask feedback from themselves
      const filteredColleagues = includeUser
        ? data.colleaguesFromOrg
        : data.colleaguesFromOrg.filter(c => c.id !== user.id)

      let mappedColleagues = filteredColleagues.map(mapColleague)

      //Logic for select managers automatically
      if (managersMandatory) {
        const selectedManagers = []
        mappedColleagues = mappedColleagues.map(colleague => {
          if (isManager(colleague)) {
            selectedManagers.push(colleague)
            return { ...colleague, manager: true }
          }
          return { ...colleague, manager: false }
        })
        selectManagerCallback(selectedManagers)
      }

      if (totalNumberColleagues) {
        totalNumberColleagues(mappedColleagues.length)
      }

      setCompletedListColleagues(mappedColleagues)
      setColleagues(mapTeams(mappedColleagues))
    }
  }, [data])

  useEffect(() => {
    if (data && data.colleaguesFromOrg) {
      const totalColleagues = includeUser
        ? data.colleaguesFromOrg.length
        : data.colleaguesFromOrg.length - 1

      setSelectedAll(totalColleagues === selectedColleagues.length)
    }
  }, [data, selectedColleagues])

  useEffect(() => {
    if (colleagues && Object.keys(colleagues).length > 0) {
      const initialSelectedTeams = []

      Object.keys(colleagues).forEach(team => {
        const usersSelectedFromTeam = colleagues[team].filter(colleague =>
          selectedColleagues.find(
            selectedColleague => selectedColleague.id === colleague.id,
          ),
        )

        if (usersSelectedFromTeam.length === colleagues[team].length) {
          initialSelectedTeams.push(team)
        }
      })

      setSelectedTeams(initialSelectedTeams)
    }
  }, [selectedColleagues, colleagues])

  const mapColleague = colleague => {
    return {
      ...colleague,
      submitted: selectedColleagues.find(
        c => c.key === colleague.id && c.submitted,
      )
        ? true
        : false,
      key: colleague.id,
    }
  }

  const onSearch = e => {
    const searchTerm = e.target.value

    setSearchText(searchTerm)

    const mappedColleagues = completedListCollegues.filter(colleague => {
      return (
        (colleague.firstName &&
          colleague.firstName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (colleague.lastName &&
          colleague.lastName
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (colleague.email &&
          colleague.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (colleague.firstName &&
          colleague.lastName &&
          (colleague.firstName + ' ' + colleague.lastName)
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
      )
    })

    setColleagues(mapTeams(mappedColleagues))
  }

  const mapTeams = colleagues => {
    const usersWithoutTeams = colleagues.filter(
      colleague =>
        !colleague.groupUsers.find(
          groupUser => groupUser.group.type === 'TEAM',
        ),
    )
    const usersWithTeams = colleagues.filter(colleague =>
      colleague.groupUsers.find(groupUser => groupUser.group.type === 'TEAM'),
    )

    const usersGroupedWithTeams = groupBy(usersWithTeams, item => {
      const teamRelation = item.groupUsers.find(
        groupUser => groupUser.group.type === 'TEAM',
      )

      return teamRelation.group.name
    })

    if (usersWithoutTeams.length > 0) {
      usersGroupedWithTeams['No team'] = usersWithoutTeams
    }
    return usersGroupedWithTeams
  }

  const selectAllClick = () => {
    setSelectedAll(true)

    let colleaguesArray = []

    Object.keys(colleagues).forEach(team => {
      colleagues[team].forEach(colleagueItem =>
        colleaguesArray.push(colleagueItem),
      )
    })

    handleSelectMultiple(colleaguesArray)
  }

  const deselectAllClick = () => {
    setSelectedAll(false)
    handleDeselectMultiple()
    setSelectedTeams([])
  }

  const selectColleaguesInTeam = team => {
    handleSelectMultiple(colleagues[team])
    setSelectedTeams([...selectedTeams, team])
  }

  const deselectColleaguesInTeam = team => {
    handleDeselectMultiple(colleagues[team])
    setSelectedTeams(selectedTeams.filter(teamItem => teamItem !== team))
  }

  const ColleagueItemList = ({ colleague, managersMandatory }) => {
    return (
      <Row gutter={12} type="flex" align="middle">
        <Col span={2}>
          <Avatar user={colleague} />
        </Col>
        <Col span={18}>
          <div>
            <AvatarName
              user={colleague}
              style={{ fontFamily: 'museo', color: '#1c1047', fontWeight: 300 }}
            />
          </div>
          <div>
            <AvatarJobTitle
              user={colleague}
              style={{ color: '#281765', fontWeight: 300 }}
            />
          </div>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          {(managersMandatory && colleague.manager) || colleague.submitted ? (
            <Popover
              content={
                managersMandatory && colleague.manager
                  ? HOVER_TEXT_MANAGER
                  : HOVER_TEXT_SUBMITTED
              }
            >
              <Button
                disabled={true}
                type="ghost"
                style={{ borderColor: 'transparent' }}
              >
                Cancel
              </Button>
            </Popover>
          ) : (
            <Button
              type={isColleagueSelected(colleague) ? 'ghost' : 'light-purple'}
              onClick={() => selectColleagueCallback(colleague)}
            >
              {isColleagueSelected(colleague) ? 'Cancel' : 'Select'}
            </Button>
          )}
        </Col>
      </Row>
    )
  }

  if (error) return <span></span>
  if (loading) return <div>Loading...</div>

  return (
    <Card
      className="colleague-list"
      title={
        (withSearch || withSelectAll) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent:
                withSearch && withSelectAll
                  ? 'space-between'
                  : withSelectAll
                  ? 'flex-end'
                  : 'flex-start',
              marginRight: -2 * DEFAULT_SPACING,
            }}
          >
            {withSearch && (
              <Search
                className="search-colleague"
                placeholder="Search here"
                allowClear
                onChange={onSearch}
                style={{
                  width: DEFAULT_SPACING * 31,
                  height: DEFAULT_SPACING * 5,
                }}
              />
            )}
            {withSelectAll && (
              <div>
                {searchText === '' &&
                  (selectedAll ? (
                    <Button
                      type="link"
                      onClick={deselectAllClick}
                      style={STYLE_SELECTS_All}
                    >
                      Deselect all
                    </Button>
                  ) : (
                    <Button
                      type="link"
                      onClick={selectAllClick}
                      style={STYLE_SELECTS_All}
                    >
                      Select all
                    </Button>
                  ))}
              </div>
            )}
          </div>
        )
      }
      bodyStyle={{ padding: '24px 0' }}
    >
      {showAvatarSelected && (
        <div style={{ borderBottom: '1px solid #e9e8ef', padding: '0 24px' }}>
          {selectedColleagues.length !== 0 ? (
            <div style={{ marginBottom: DEFAULT_SPACING }}>
              {selectedColleagues.map(colleague => (
                <Badge
                  key={colleague.key}
                  count={
                    (!isManager(colleague) || !managersMandatory) &&
                    !colleague.submitted ? (
                      <Icon
                        className="badge-avatar"
                        type="close-circle"
                        theme="filled"
                        style={{ color: '#f95c4b', fontSize: 20 }}
                        onClick={() => selectColleagueCallback(colleague)}
                      />
                    ) : (
                      <Icon
                        className="badge-avatar"
                        type="close-circle"
                        theme="filled"
                        style={{
                          color: '#e4e4ec',
                          fontSize: 20,
                          cursor: 'not-allowed',
                        }}
                      />
                    )
                  }
                >
                  <Avatar
                    size="default"
                    user={colleague}
                    withRightMargin
                    withBottomMargin
                  />
                </Badge>
              ))}
            </div>
          ) : (
            <div
              style={{
                marginBottom: DEFAULT_SPACING * 4,
                marginTop: DEFAULT_SPACING,
              }}
            >
              You haven't selected any colleagues yet
            </div>
          )}
        </div>
      )}

      {
        <List
          dataSource={Object.keys(colleagues)}
          style={
            height
              ? {
                  height: height,
                  overflow: 'auto',
                  overscrollBehaviorY: 'contain',
                  padding: '0 24px',
                }
              : ''
          }
          size="large"
          itemLayout="vertical"
          split={false}
          renderItem={team => (
            <List.Item>
              <div style={{ display: 'flex' }}>
                <div
                  style={{ fontSize: 18, color: '#1c1047', marginBottom: 16 }}
                >
                  {team}
                </div>
                <span style={{ margin: '0 8px 0 auto', cursor: 'pointer' }}>
                  {searchText === '' && (
                    <span>
                      {selectedTeams.indexOf(team) !== -1 ? (
                        <Button
                          type="link"
                          style={STYLE_SELECTS_GROUPS}
                          onClick={() => deselectColleaguesInTeam(team)}
                        >
                          Deselect team
                        </Button>
                      ) : (
                        <Button
                          type="link"
                          style={STYLE_SELECTS_GROUPS}
                          onClick={() => selectColleaguesInTeam(team)}
                        >
                          Select team
                        </Button>
                      )}
                    </span>
                  )}
                </span>
              </div>
              {colleagues[team].map(colleague => (
                <div key={colleague.id} style={{ padding: '8px 6px' }}>
                  {ColleagueItemList({ colleague, managersMandatory })}
                </div>
              ))}
            </List.Item>
          )}
        />
      }
    </Card>
  )
}
