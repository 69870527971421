import './style.less'

import { Button, Col, Input, List, Modal, Row, Slider, Typography } from 'antd'
import React from 'react'

import SafePlaceNotice from '../SafePlaceNotice'

const { TextArea } = Input
const { Title } = Typography

const DEFAULT_SPACING = 8

export default ({ form, modalVisible, onClose, onSelect }) => {
  const footerButtons = [
    <Button type="ghost" key="close" onClick={() => onClose()}>
      Close
    </Button>,
  ]

  onSelect &&
    footerButtons.push(
      <Button key="use" type="primary" onClick={onSelect}>
        Use this template
      </Button>,
    )

  const getQuestionsForSection = section => {
    return section.questions.map(question => {
      const formQuestion = form.formQuestions.find(
        fq => fq.question.id === question.id,
      )

      if (formQuestion) {
        const questionDetails = formQuestion.question
        let questionInput

        if (questionDetails.template.type === 'MULTI_CHOICE') {
          const optionsArr = questionDetails.template.settings.options
          const optionsToSet = {}
          const step = 100 / (optionsArr.length - 1)

          optionsArr.forEach((option, index) => {
            if (index === optionsArr.length - 1) {
              optionsToSet[100] = option
            } else {
              optionsToSet[index * step] = option
            }
          })

          questionInput = (
            <div style={{ paddingBottom: DEFAULT_SPACING * 2 }}>
              <Slider
                marks={optionsToSet}
                step={null}
                tipFormatter={null}
                initialValue={0}
                disabled
              />
            </div>
          )
        }

        if (
          ['TEXT', 'SHORT_TEXT'].indexOf(questionDetails.template.type) > -1
        ) {
          questionInput = (
            <div>
              <TextArea
                disabled
                placeholder={question.placeholder}
                style={{
                  backgroundColor: 'white',
                  boxShadow: 'rgba(119, 119, 159, 0.12) 0px 16px 24px 0px',
                  resize: 'none',
                }}
              />
              <div
                className="small-letter"
                style={{
                  textAlign: 'right',
                  marginTop: DEFAULT_SPACING * 2.5,
                }}
              >
                {questionDetails.template.settings.characterLimit} characters
                remaining
              </div>
            </div>
          )
        }

        return (
          <div key={question.id} style={{ marginTop: DEFAULT_SPACING * 2 }}>
            <div
              style={{
                fontWeight: 300,
                fontSize: 18,
                color: '#1c1047',
                marginBottom: DEFAULT_SPACING * 2,
              }}
            >
              {questionDetails.titleVariants[0].title}
              {!question.required && ' (Optional)'}
            </div>
            {questionInput}
          </div>
        )
      } else {
        return <span></span>
      }
    })
  }

  return (
    <Modal
      title={
        <div style={{ fontFamily: 'museo', fontWeight: 500 }}>
          {form && form.title}
        </div>
      }
      visible={modalVisible}
      centered
      onCancel={onClose}
      footer={footerButtons}
      afterClose={() => onClose()}
      width={720}
      style={{ marginTop: DEFAULT_SPACING * 3 }}
    >
      {form && (
        <div>
          <Row
            style={{
              marginBottom: DEFAULT_SPACING * 3,
              borderBottom: '1px solid #cac8dc',
              paddingBottom: DEFAULT_SPACING * 3,
            }}
          >
            <Col span={8}>
              <div
                style={{
                  marginBottom: DEFAULT_SPACING * 1.5,
                  fontWeight: 500,
                  fontFamily: 'museo',
                }}
              >
                Who is this for?
              </div>
              <div style={{ fontWeight: 300 }}>{form.recipients}</div>
            </Col>
            <Col span={16}>
              <div
                style={{
                  marginBottom: DEFAULT_SPACING * 1.5,
                  fontWeight: 500,
                  fontFamily: 'museo',
                }}
              >
                How is this helpful?
              </div>
              <div style={{ fontWeight: 300 }}>{form.description}</div>
            </Col>
          </Row>
          <div>
            <div
              style={{
                color: '#f95c4b',
                fontSize: 18,
                marginBottom: DEFAULT_SPACING * 2,
              }}
            >
              People invited to give feedback are going to see this:
            </div>
            <SafePlaceNotice />
          </div>
          <div style={{ marginTop: -DEFAULT_SPACING }}>
            <List
              itemLayout="vertical"
              dataSource={form && form.sections}
              renderItem={(section, index) => (
                <List.Item key={index}>
                  <Title
                    level={4}
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    {section.title}
                  </Title>
                  <Row>{getQuestionsForSection(section)}</Row>
                </List.Item>
              )}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
