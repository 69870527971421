import { useQuery } from '@apollo/react-hooks'
import { Card, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Emoji from 'react-emoji-render'

import CyclesList from '../../_components/CycleCard/CyclesList'
import { GET_SCHEDULED_CYCLES } from '../../Team360/queries'

const { Text } = Typography

export default props => {
  const [cycles, setCycles] = useState([])

  const { data } = useQuery(GET_SCHEDULED_CYCLES, {
    variables: {
      type: '360',
    },
  })

  useEffect(() => {
    if (data && data.cycles) {
      setCycles(data.cycles)
    }
  }, [data])

  const handleDelete = cycleId => {
    const cycleIndex = cycles.findIndex(cycle => cycle.id === cycleId)

    if (cycleIndex > -1) {
      const newCycles = [...cycles]
      newCycles.splice(cycleIndex, 1)
      setCycles(newCycles)
    }
  }

  return (
    <div className="cycles">
      <Card className="top-title">
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Team 360
        </Text>
      </Card>
      {props.menu}
      {cycles && cycles.length > 0 ? (
        <CyclesList cycles={cycles} handleDelete={handleDelete} />
      ) : (
        <div
          style={{
            color: '#77779f',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 300,
          }}
        >
          <div style={{ margin: '16px 0 0' }}>
            Nothing to see here.
            <Emoji text={':shrug:'} style={{ fontSize: '24px' }} />
          </div>
          <div>To get the ball rolling please create a new event.</div>
        </div>
      )}
    </div>
  )
}
