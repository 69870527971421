import {
  Alert,
  Badge,
  Button,
  Icon,
  InputNumber,
  Typography,
  notification,
} from 'antd'
import unionWith from 'lodash/unionWith'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { ReactComponent as inviteButtonImg } from '../../../_assets/img/invite-icon.svg'
import AddPeopleModal from '../../../_components/AddPeopleModal/'
import ColleagueList from '../../../_components/ColleagueList/ColleagueList'
import ButtonsBottom360 from '../../../_components/CycleCreationEditButtonsBottom'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import ThreeSixtyContext from '../360Context'
import ThreeSixtyStepsContext from '../360StepsContext'

const { Title } = Typography

const DEFAULT_SPACING = 8

export default props => {
  const user = useContext(UserContext)
  const context = useContext(ThreeSixtyContext)
  const stepsContext = useContext(ThreeSixtyStepsContext)
  const minInput = useRef(null)
  const maxInput = useRef(null)

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedColleagues, setSelectedColleagues] = useState(
    context.enrolledUsers,
  )
  const [error, setError] = useState(false)
  const [totalNumberColleagues, setTotalNumberColleagues] = useState(null)

  useEffect(() => {
    if (context.enrolledUsers.length !== selectedColleagues.length) {
      setSelectedColleagues(context.enrolledUsers)
    }
  }, [context.enrolledUsers])

  useEffect(() => {
    if (totalNumberColleagues && !context.maxRequests) {
      context.setContext({ maxRequests: totalNumberColleagues - 1 })
    }
    if (error && error.type === 1) {
      setError(
        totalNumberColleagues < 3
          ? {
              text: renderTotalNumberColleagueError(totalNumberColleagues),
              type: 1,
            }
          : null,
      )
    }
  }, [totalNumberColleagues])

  const selectColleague = selectedColleague => {
    const selectedColleagueIndex = selectedColleagues.findIndex(colleague => {
      return colleague.key === selectedColleague.key
    })

    if (selectedColleagueIndex === -1) {
      const newColleagues = [...selectedColleagues, selectedColleague]
      setSelectedColleagues(newColleagues)
      context.setContext({ enrolledUsers: newColleagues })
    } else {
      const selectedColleaguesCopy = [...selectedColleagues]
      selectedColleaguesCopy.splice(selectedColleagueIndex, 1)
      setSelectedColleagues(selectedColleaguesCopy)
      context.setContext({ enrolledUsers: selectedColleaguesCopy })
    }
  }

  const renderTotalNumberColleagueError = number => {
    return `You need to invite at least ${3 - number} ${
      3 - number === 1 ? 'person' : 'people'
    } to use Howamigoing to start a 360 feedback event.`
  }

  const handleSelectMultiple = allColleagues => {
    const newColleagues = unionWith(
      selectedColleagues,
      allColleagues,
      (firstVal, secondVal) => {
        return firstVal.key === secondVal.key
      },
    )
    setSelectedColleagues(newColleagues)
    context.setContext({ enrolledUsers: newColleagues })
  }

  const handleDeselectMultiple = (colleagues = false) => {
    if (colleagues) {
      const filteredColleagues = selectedColleagues.filter(
        colleague =>
          !colleagues.find(colleagueItem => colleagueItem.id === colleague.id),
      )

      setSelectedColleagues(filteredColleagues)
      context.setContext({ enrolledUsers: filteredColleagues })
    } else {
      setSelectedColleagues([])
      context.setContext({ enrolledUsers: [] })
    }
  }

  const handleMinimumNumberChange = value => {
    if (!value) {
      value = 2
    }
    const normalizesValue = parseInt(value)
    context.setContext({ minRequests: normalizesValue })
    track('team360.cycle.minimum', {
      user: user.email,
      org: user.org.name,
      min: normalizesValue,
    })
    if (
      context.maxRequests < normalizesValue &&
      normalizesValue < context.enrolledUsers.length
    ) {
      context.setContext({ maxRequests: normalizesValue })
    }
  }

  const handleMaximumNumberChange = value => {
    if (!value) {
      value = 2
    }
    context.setContext({ maxRequests: parseInt(value) })
    track('team360.cycle.maximum', {
      user: user.email,
      org: user.org.name,
      max: value,
    })
  }

  const handleForm = () => {
    if (totalNumberColleagues < 3) {
      setError({
        text: renderTotalNumberColleagueError(totalNumberColleagues),
        type: 1,
      })
      return
    }
    if (context.enrolledUsers.length < 1) {
      setError({
        text: 'Please select at least 1 colleague to start a 360.',
        type: 2,
      })
      return
    }
    if (context.minRequests < 2) {
      setError({ text: 'The minimum requests should be at least 2.', type: 3 })
      return
    }
    if (context.maxRequests < 2) {
      setError({ text: 'The maximum requests should be at least 2.', type: 4 })
      return
    }
    if (context.minRequests > context.maxRequests) {
      setError({
        text:
          'The minimum requests cannot be greater than the maximum of requests.',
        type: 5,
      })
      return
    }
    if (context.minRequests > totalNumberColleagues - 1) {
      setError({
        text:
          'The maximum requests cannot be greater than the total of users in company.',
        type: 6,
      })
      return
    }
    if (context.maxRequests > totalNumberColleagues - 1) {
      setError({
        text:
          'The maximum requests cannot be greater than the total of users in company.',
        type: 7,
      })
      return
    }

    setError(null)
    track('team360.cycle.enrolPeopleNextStep', {
      user: user.email,
      org: user.org.name,
      amountEnrolledPeople: context.enrolledUsers.length,
      min: context.minRequests,
      max: context.maxRequests,
    })
    const newUrl = context.id
      ? `/team360/edit/${context.id}/3`
      : '/team360/create/3'
    stepsContext.setStep(2)
    props.history.push(newUrl)
  }

  const onUsersAdded = invitedUsers => {
    let message
    let grammaticalNumberText

    track('team360.cycle.invitePeopleSend', {
      user: user.email,
      org: user.org.name,
      amountInvites: invitedUsers.length,
    })

    if (invitedUsers.length > 1) {
      message = 'Invite sent!'
      grammaticalNumberText = 'invites were'
    } else {
      message = 'Invites sent!'
      grammaticalNumberText = 'invite was'
    }

    notification.success({
      message: message,
      description: `${invitedUsers.length} new ${grammaticalNumberText} just sent!`,
    })

    setModalVisible(false)
  }

  return (
    <div
      className="new-cycle-enroll-users"
      style={{ paddingTop: DEFAULT_SPACING }}
    >
      <Title level={4} style={{ marginBottom: DEFAULT_SPACING }}>
        Who is the feedback for?
        <Badge
          showZero
          overflowCount={1000}
          count={selectedColleagues.length}
          style={{
            background: '#f95c4b',
            fontFamily: 'Europa',
            marginLeft: DEFAULT_SPACING,
            fontSize: DEFAULT_SPACING * 2,
          }}
        />
      </Title>
      <div style={{ marginBottom: DEFAULT_SPACING * 3, fontWeight: 300 }}>
        The people you enrol below will be able to request feedback from any
        colleague.
      </div>

      <ColleagueList
        height={400}
        withSearch
        withSelectAll
        handleSelectMultiple={handleSelectMultiple}
        handleDeselectMultiple={handleDeselectMultiple}
        includeUser
        selectedColleagues={selectedColleagues}
        selectColleagueCallback={selectColleague}
        totalNumberColleagues={setTotalNumberColleagues}
      />

      {error && (
        <Alert
          style={{
            color: '#f95c4b',
            marginTop: DEFAULT_SPACING * 3,
            backgroundColor: 'rgba(249, 92, 75, 0.12)',
            borderColor: '#f95c4b',
          }}
          icon={<Icon type="close-circle" style={{ color: '#f95c4b' }} />}
          description={error.text}
          type="error"
          showIcon
        />
      )}

      <div style={{ paddingTop: DEFAULT_SPACING * 3 }}>
        <div
          style={{
            fontSize: 18,
            color: '#1c1047',
            marginBottom: DEFAULT_SPACING * 2,
          }}
        >
          Not listed above?
        </div>
        <Button
          type="link"
          onClick={() => setModalVisible(true)}
          style={{
            color: '#f95c4b',
            fontWeight: 'normal',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            component={inviteButtonImg}
            className="box-shadow-primary"
            style={{
              fontSize: 40,
              height: 40,
              borderRadius: 20,
            }}
          />
          <span style={{ textDecoration: 'underline' }}>
            Invite more people to use Howamigoing
          </span>
        </Button>
        <AddPeopleModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onSuccess={onUsersAdded}
        />
      </div>

      <div style={{ paddingTop: DEFAULT_SPACING * 4 }}>
        <Title
          level={4}
          style={{
            fontSize: 18,
            color: '#1c1047',
            marginBottom: DEFAULT_SPACING * 3,
          }}
        >
          How many colleagues should they request feedback from?
        </Title>
        <div style={{ fontWeight: 300 }}>
          <span style={{ fontWeight: 'normal', fontSize: 18, marginRight: 25 }}>
            Minimum{' '}
          </span>
          <InputNumber
            style={{
              width: DEFAULT_SPACING * 6,
              boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
              marginRight: DEFAULT_SPACING * 2,
            }}
            ref={minInput}
            min={2}
            max={totalNumberColleagues >= 3 ? totalNumberColleagues - 1 : 2}
            precision={0}
            value={context.minRequests}
            defaultValue={context.minRequests}
            onChange={handleMinimumNumberChange}
            onPressEnter={e => {
              minInput.current.blur()
            }}
          />{' '}
          At least 2 people need to give feedback so that we can anonymise the
          responses.
        </div>
        <div style={{ padding: `${DEFAULT_SPACING * 2}px 0`, fontWeight: 300 }}>
          <span style={{ fontWeight: 'normal', fontSize: 18, marginRight: 21 }}>
            Maximum{' '}
          </span>
          <InputNumber
            style={{
              width: DEFAULT_SPACING * 6,
              boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
              marginRight: DEFAULT_SPACING * 2,
            }}
            ref={maxInput}
            onPressEnter={e => {
              maxInput.current.blur()
            }}
            precision={0}
            min={
              context.minRequests && context.minRequests < totalNumberColleagues
                ? context.minRequests
                : 2
            }
            max={
              !totalNumberColleagues && context.maxRequests
                ? context.maxRequests
                : totalNumberColleagues && totalNumberColleagues >= 3
                ? totalNumberColleagues - 1
                : 2
            }
            value={
              context.maxRequests
                ? context.maxRequests
                : totalNumberColleagues > 3
                ? totalNumberColleagues - 1
                : 2
            }
            onChange={handleMaximumNumberChange}
          />{' '}
          We recommend asking 5-6 people to get a good range of responses.
        </div>
      </div>

      <ButtonsBottom360
        step={2}
        handleContinueClick={handleForm}
        contextId={context.id}
        typePath={'/team360'}
        fromWBYHT={context.fromWBYHT}
      />
    </div>
  )
}
