import gql from 'graphql-tag'

export const CREATE_CYCLE_WITH_REVIEWS = gql`
  mutation addCycleWithReviews(
    $startDate: Int!
    $endDate: Int!
    $formId: String!
    $requestor: String!
    $requestedUsers: [String!]!
  ) {
    createCycleWithReviews(
      newCycleWithReviews: {
        startDate: $startDate
        endDate: $endDate
        formId: $formId
        requestor: $requestor
        requestedUsers: $requestedUsers
      }
    ) {
      id
      type
      name
      startDate
      endDate
    }
  }
`

export const GET_REVIEW = gql`
  query getReview($reviewId: String!) {
    review(id: $reviewId) {
      id
      cycle {
        id
        settings
        form {
          id
          title
          recipients
          description
          sections
          formQuestions {
            id
            question {
              id
              titleVariants
              description
              template {
                id
                type
                settings
              }
            }
          }
        }
      }
      feedbacks {
        id
        submittedAt
        user {
          id
          firstName
          lastName
          email
          avatarUrl
          avatar
        }
      }
    }
  }
`

export const UPDATE_REVIEW_FORCE_MANAGER = gql`
  mutation updateReviewWithForceManager(
    $reviewId: ID!
    $requestedUsers: [String!]!
  ) {
    updateReviewWithForceManager(
      updateReview: { id: $reviewId, requestedUsers: $requestedUsers }
    ) {
      id
      feedbacks {
        id
        user {
          id
          firstName
          lastName
          email
          avatarUrl
          avatar
        }
      }
    }
  }
`
