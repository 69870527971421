import React, { useEffect, useRef, useState } from 'react'

import Tag from './Tag'
import { Tooltip } from 'antd'
import extractEmailsFromString from '../_helpers/extractEmailsFromString'
import { uniq } from 'lodash'

export default React.forwardRef(
  (
    { value = [], onChange, placeholder, tagValidator, closable, ...props },
    ref,
  ) => {
    const createTags = () => {
      if (!value) {
        return null
      }
      return value.map((val, i) => {
        const errorMessage = tagValidator ? tagValidator(val, value) : null
        const tagKey = `tag-${i}`
        const isSelected = selectedTagKeys.find(key => key === tagKey)
        const component = (
          <Tooltip title={errorMessage}>
            <Tag
              closable={true}
              editable={true}
              selected={!!isSelected}
              key={tagKey}
              type={errorMessage ? 'error' : 'primary'}
              truncateAt={392}
              style={{ margin: 4 }}
              onClose={evt => {
                evt.stopPropagation()
                handleTagClose(val, i, tagKey, component)
              }}
              onEdit={val => handleTagEdit(val, i)}
              onEdited={val => handleTagEdited(val, i)}
              value={val}
            />
          </Tooltip>
        )
        return {
          key: tagKey,
          value: val,
          component,
        }
      })
    }

    const [selectedTagKeys, setSelectedTagKeys] = useState([])
    const [tags, setTags] = useState(createTags(value))
    const [inputValue, setInputValue] = useState('')
    const inputRef = useRef(null)
    const containerRef = useRef(null)
    const editingRef = useRef(false)

    const extractInput = str => {
      if (!str) {
        return []
      }
      const entries = extractEntries(str)
      if (!entries) {
        return []
      }
      return entries
    }

    const extractEntries = str => {
      const pattern = /([^,\n\t\s\v\0]+)/g
      return str.match(pattern)
    }

    const isEditing = () => {
      return editingRef.current
    }

    const handleTagClose = (val, i, key, component) => {
      const valueWithoutClosed = [...value]
      valueWithoutClosed.splice(i, 1)
      deselectTag(component)
      onChange(valueWithoutClosed)
    }

    const handleTagEdit = (val, i) => {
      editingRef.current = true
    }

    const handleTagEdited = (val, i) => {
      const editedValues = [...value]
      editedValues[i] = val
      editingRef.current = false
      onChange(editedValues)
    }

    const handleOnChange = val => {
      const lastChar = val[val.length - 1]
      const lastCharIsSpace = lastChar === ' '
      const lastCharIsComma = lastChar === ','
      const hasFinishedTyping =
        val && val.length > 0 && (lastCharIsSpace || lastCharIsComma)
      if (hasFinishedTyping) {
        onInputFinish(val)
        return
      }
      setInputValue(val)
    }

    const handleOnContainerKeyDown = evt => {
      const backspaceKeyCode = 8
      const deleteKeyCode = 46

      if (isEditing()) {
        return
      }

      if (evt.key === 'a' && (evt.ctrlKey || evt.metaKey)) {
        evt.preventDefault()
        selectAllTags()
        return
      }

      switch (evt.keyCode) {
        case backspaceKeyCode:
        case deleteKeyCode:
          if (selectedTagKeys.length === 0) {
            const tag = tags[tags.length - 1]
            selectTag(tag)
            return
          }
          const unselectedTags = tags.filter(tag => {
            return !selectedTagKeys.find(
              selectedTagKey => selectedTagKey === tag.key,
            )
          })
          setSelectedTagKeys([])
          inputRef.current.focus()
          onChange(unselectedTags.map(tag => tag.value))
          break
        default:
      }
    }

    const handleOnInputKeyDown = evt => {
      const backspaceKeyCode = 8
      const returnKeyCode = 13
      const tabKeyCode = 9

      evt.stopPropagation()

      if (
        evt.key === 'a' &&
        (evt.ctrlKey || evt.metaKey) &&
        inputValue === ''
      ) {
        evt.preventDefault()
        selectAllTags()
        return
      }

      switch (evt.keyCode) {
        case backspaceKeyCode:
          if (inputValue !== '') {
            return
          }
          const tag = tags[tags.length - 1]
          selectTag(tag)
          break
        case returnKeyCode:
        case tabKeyCode:
          evt.preventDefault()
          onInputFinish(inputValue)
          break
        default:
      }
    }

    const selectTag = tag => {
      const allSelected = [...selectedTagKeys, tag ? tag.key : undefined]
      containerRef.current.focus()
      setSelectedTagKeys(allSelected)
    }

    const deselectTag = tag => {
      const tagsWithoutDeselect = tags.filter(x => x.key !== tag.key)
      containerRef.current.focus()
      setSelectedTagKeys(tagsWithoutDeselect)
    }

    const selectAllTags = () => {
      const selected = tags.map(tag => tag.key)
      selected.length > 0
        ? containerRef.current.focus()
        : inputRef.current.focus()
      setSelectedTagKeys(selected)
    }

    const handleOnPaste = evt => {
      const htmlItem = Object.keys(evt.clipboardData.items)
        .map(i => evt.clipboardData.items[i])
        .find(item => item.type === 'text/html')

      if (!htmlItem) {
        const parsedFromText = extractInput(evt.clipboardData.getData('text'))
        onChange([...value, ...parsedFromText])
        setInputValue('')
        return
      }

      htmlItem.getAsString(htmlStr => {
        // const parser = new DOMParser()
        // const doc = parser.parseFromString(htmlStr, 'text/html')
        // debugger
        // const htmlTexts = Object.keys(doc.all)
        //   .map(i => doc.all[i])
        //   .filter(ele => ele.children.length === 0)
        //   .map(ele => ele.innerText.trim())
        //   .filter(val => val && val.length > 0)
        // TODO: This could use some work. Ideally it should validate all email-like text
        // from the HTML, however, there's no consistent rule to follow. For the time being
        // only valid emails are pasted in.
        const parsedFromHtml = uniq(extractEmailsFromString(htmlStr))
        onChange([...value, ...parsedFromHtml])
        setInputValue('')
      })
    }

    const handleOnInputFocus = evt => {
      setSelectedTagKeys([])
    }

    const handleOnInputBlur = evt => {
      const val = evt.currentTarget.value
      onInputFinish(val)
    }

    const onInputFinish = val => {
      const parsedInputs = extractInput(val)
      onChange([...value, ...parsedInputs])
      setInputValue('')
    }

    const containerStyles = {
      minHeight: 48,
      height: 'auto',
      cursor: 'pointer',
      display: 'flex',
      flexWrap: 'wrap',
      padding: '6px 4px',
      overflowY: 'auto',
      ...props.style,
    }

    useEffect(() => {
      setTags(createTags())
    }, [value])

    useEffect(() => {
      setTags(createTags())
    }, [selectedTagKeys])

    return (
      <div
        className="ant-input"
        style={containerStyles}
        ref={containerRef}
        onClick={() => {
          if (selectedTagKeys.length === 0) {
            inputRef.current.focus()
          } else {
            setSelectedTagKeys([])
          }
        }}
        onKeyDown={handleOnContainerKeyDown}
        tabIndex="0"
      >
        {tags.map(tag => tag.component)}
        <input
          type="text"
          style={{
            flex: '1 0 auto',
            outline: 'none',
            border: 0,
            height: '32px',
            lineHeight: '29px',
            fontWeight: 300,
            padding: '0 12px',
            margin: 4,
          }}
          autocorrect="off"
          autocapitalize="none"
          onKeyDown={handleOnInputKeyDown}
          onChange={evt => handleOnChange(evt.currentTarget.value)}
          onPaste={handleOnPaste}
          onFocus={handleOnInputFocus}
          onBlur={handleOnInputBlur}
          value={inputValue}
          ref={inputRef}
          placeholder={
            placeholder && tags && tags.length === 0 ? placeholder : null
          }
        />
      </div>
    )
  },
)
