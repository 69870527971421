import { Avatar, AvatarName } from '../_components/Avatar'
import { Button, Card, Form, Modal, Typography, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { AutoSaveContext } from '../AutoSaveContext'
import BackButton from '../_components/BackButton'
import { COMPLETE_FEEDBACK } from './queries'
import FormQuestionMultiChoice from './FormQuestionMultiChoice'
import FormQuestionText from './FormQuestionText'
import { GET_CYCLES } from '../Team360/queries'
import { GET_CYCLES_PERSONAL } from '../Personal360/queries'
import { GET_SUBSCRIBER_MESSAGES } from '../hooks/graphql/Messages/queries'
import GiveFeedbackContext from './giveFeedbackContext'
import { UserContext } from '../UserContext'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'
import { withRouter } from 'react-router'

const { Paragraph, Title, Text } = Typography

const createForm = ({
  history,
  form,
  formStructure,
  cycleType: cycleTypeProp,
}) => {
  const user = useContext(UserContext)
  const giveFeedbackContext = useContext(GiveFeedbackContext)
  const autoSaveContext = useContext(AutoSaveContext)
  const [cycleType, setCycleType] = useState({})
  const [modalVisible, setModalVisible] = useState(false)
  useEffect(() => {
    switch (cycleTypeProp) {
      case 'THREESIXTY':
        setCycleType({ track: 'team360', query: '360' })
        break
      case 'PERSONAL':
        setCycleType({ track: 'personal360', query: 'personal' })
        break
      case 'PROBATION':
        setCycleType({ track: 'probation', query: 'probation' })
        break
      case 'PULSE':
        setCycleType({ track: 'pulse', query: 'pulse' })
        break
      default:
        setCycleType({ track: 'team360', query: '360' })
    }
  }, [cycleTypeProp])

  const getAnswers = () => {
    const values = form.getFieldsValue()
    return Object.keys(values).map(key => {
      return {
        questionId: key,
        data: { value: values[key] },
      }
    })
  }

  const [createFeedbackMutation] = useMutation(COMPLETE_FEEDBACK, {
    variables: {
      feedbackId: giveFeedbackContext.feedbackId,
      responses: getAnswers(),
    },
    refetchQueries: [
      {
        query: GET_CYCLES,
        variables: {
          running: true,
        },
      },
      {
        query: GET_CYCLES_PERSONAL,
        variables: {
          type: 'personal',
          running: true,
        },
      },
      {
        query: GET_SUBSCRIBER_MESSAGES,
      },
    ],
  })

  const { getFieldDecorator } = form

  const getQuestion = sectionQuestion => {
    const formQuestion = formStructure.formQuestions.find(
      fq => fq.question.id === sectionQuestion.id,
    )
    return formQuestion
      ? Object.assign(sectionQuestion, formQuestion.question)
      : undefined
  }

  const submitForm = () => {
    createFeedbackMutation()
      .then(() => {
        autoSaveContext.clearDraft(giveFeedbackContext.feedbackId)
        setModalVisible(false)
        track(cycleType.track + '.feedback.submit', {
          provider: user.email,
          org: user.org.name,
          feedbackId: giveFeedbackContext.feedbackId,
        })
        history.push('/home')
      })
      .catch(() => {
        notification.error({
          message: 'Oops!',
          description:
            'There was a problem submitting your feedback. Please try again. Let us know if you need any help :)',
        })
      })
  }

  const handleSubmitAttempt = e => {
    e.preventDefault()
    form.validateFieldsAndScroll({ scroll: { offsetTop: 70 } }, err => {
      if (!err) {
        track(cycleType.track + '.feedback.submitAttempt', {
          provider: user.email,
          org: user.org.name,
          feedbackId: giveFeedbackContext.feedbackId,
        })
        setModalVisible(true)
      }
    })
  }

  useEffect(() => {
    if (
      giveFeedbackContext &&
      giveFeedbackContext.feedbackId &&
      autoSaveContext &&
      !autoSaveContext.enabled
    ) {
      autoSaveContext.enable(giveFeedbackContext.feedbackId, form)
    }
  }, [])

  return (
    <div>
      <Form layout="vertical" onSubmit={handleSubmitAttempt}>
        {formStructure.sections.map((section, sectionIndex) => (
          <Card
            title={
              <Title level={4} style={{ marginBottom: 0 }}>
                {section.title}
              </Title>
            }
            style={{ marginBottom: '32px' }}
            key={sectionIndex}
          >
            <div style={{ padding: '0px 8px' }}>
              {section.questions.map(sectionQuestion => {
                const question = getQuestion(sectionQuestion)

                switch (question.template.type) {
                  case 'SHORT_TEXT':
                  case 'TEXT':
                    return (
                      <FormQuestionText
                        key={question.id}
                        question={question}
                        fieldDecorator={getFieldDecorator}
                        target="other"
                      />
                    )

                  case 'MULTI_CHOICE':
                    return (
                      <FormQuestionMultiChoice
                        key={question.id}
                        question={question}
                        fieldDecorator={getFieldDecorator}
                        target="other"
                      />
                    )

                  default:
                    return <div></div>
                }
              })}
            </div>
          </Card>
        ))}
        <Form.Item style={{ textAlign: 'right', marginTop: 8 }}>
          <BackButton style={{ marginRight: 8 }} />
          <Button type="primary" shape="round" size="large" htmlType="submit">
            Submit feedback
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={
          <div style={{ margin: '-10px 0' }}>
            <Avatar user={formStructure.user} withRightMargin disableTooltip />
            <Text
              style={{
                fontFamily: 'museo, serif',
                color: '#1c1047',
                fontSize: '18px',
              }}
            >
              Giving feedback to{' '}
              <AvatarName
                user={formStructure.user}
                style={{ color: '#f95c4b' }}
              />
            </Text>
          </div>
        }
        visible={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="back"
            type="ghost"
            onClick={() => {
              track(cycleType.track + '.feedback.submitLater', {
                provider: user.email,
                org: user.org.name,
                feedbackId: giveFeedbackContext.feedbackId,
              })
              setModalVisible(false)
            }}
          >
            Submit later
          </Button>,
          <Button key="submit" type="primary" onClick={submitForm}>
            Submit now
          </Button>,
        ]}
        style={{ textAlign: 'center' }}
      >
        <Paragraph style={{ textAlign: 'left' }}>
          Are you happy with your feedback? <br />
          Once you submit feedback you can no longer edit it.
        </Paragraph>
      </Modal>
    </div>
  )
}

export default withRouter(Form.create({ name: 'createFeedback' })(createForm))
