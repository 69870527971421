import React, { useContext } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import InvitePeople from './InvitePeople'
import { Layout } from 'antd'
import SetJobTitle from './SetJobTitle'
import SetPassword from './SetPassword'
import SetTeam from './SetTeam'
import { UserContext } from '../UserContext'
import Welcome from './WelcomeTerms'

const { Content } = Layout

const getSteps = user => {
  if (user && user.data && user.data.onboarding) {
    return user.data.onboarding
  }
  return {}
}

const hasStartedOnboarding = steps => {
  return Object.keys(steps).length > 0
}

const shouldRenderTermsStep = steps => {
  return steps.onboardingTermsStep !== 'complete'
}

const shouldRenderPasswordStep = steps => {
  return (
    !shouldRenderTermsStep(steps) && steps.onboardingPasswordStep !== 'complete'
  )
}

const shouldRenderJobTitleStep = steps => {
  return !shouldRenderPasswordStep(steps) && !steps.onboardingJobTitleStep
}

const shouldRenderTeamStep = steps => {
  return !shouldRenderJobTitleStep(steps) && !steps.onboardingTeamStep
}

const shouldRenderInvitePeopleStep = steps => {
  return !shouldRenderTeamStep(steps) && !steps.onboardingInvitePeopleStep
}

const shouldRenderPath = user => {
  const prefix = '/onboarding'
  const defaultPath = `${prefix}/welcome`
  const steps = getSteps(user)
  if (shouldRenderTermsStep(steps)) {
    return defaultPath
  }
  if (shouldRenderPasswordStep(steps)) {
    return `${prefix}/set-password`
  }
  if (shouldRenderJobTitleStep(steps)) {
    return `${prefix}/set-job-title`
  }
  if (shouldRenderTeamStep(steps)) {
    return `${prefix}/set-team`
  }
  if (shouldRenderInvitePeopleStep(steps)) {
    return `${prefix}/invite-people`
  }
  return defaultPath
}

const hasCompletedOnboarding = user => {
  if (user.status === 'ACTIVE') {
    return true
  }
  const steps = getSteps(user)
  if (!hasStartedOnboarding(steps)) {
    return false
  }

  return (
    steps.onboardingTermsStep === 'complete' &&
    steps.onboardingPasswordStep === 'complete' &&
    steps.onboardingJobTitleStep &&
    steps.onboardingInvitePeopleStep
  )
}

const AdminOnboardingRoute = ({ path, render, component: Component }) => {
  const user = useContext(UserContext)
  return (
    <Route
      path={path}
      render={props => {
        const { location } = props
        if (hasCompletedOnboarding(user)) {
          return <Redirect to="/home" />
        }
        const targetPath = shouldRenderPath(user)
        if (!location.pathname.startsWith(targetPath)) {
          return (
            <Redirect
              to={{
                pathname: targetPath,
                state: { from: location },
              }}
            />
          )
        }
        return render ? render(props) : <Component {...props} />
      }}
    />
  )
}

export default ({ finishOnboarding }) => {
  const path = page => '/onboarding/' + page
  const user = useContext(UserContext)
  const history = useHistory()

  if (!user.isAuthenticated) {
    return (
      <p>Please click the link sent to your email to verify your account</p>
    )
  }

  const onSubmit = async () => {
    await user.refetchUser()
    const targetPath = shouldRenderPath(user)
    history.push(`${targetPath}`)
  }

  return (
    <Content className="app-onboarding">
      <Switch>
        <AdminOnboardingRoute
          path={path('welcome')}
          render={() => <Welcome onSubmit={onSubmit} />}
        />
        <AdminOnboardingRoute
          path={path('set-password')}
          render={() => (
            <SetPassword
              stepIndex={1}
              totalSteps={4}
              onSubmit={onSubmit}
              onSkip={onSubmit}
            />
          )}
        />
        <AdminOnboardingRoute
          path={path('set-job-title')}
          render={() => (
            <SetJobTitle
              stepIndex={2}
              totalSteps={4}
              onSubmit={onSubmit}
              onSkip={onSubmit}
            />
          )}
        />
        <AdminOnboardingRoute
          path={path('set-team')}
          component={() => <SetTeam onSubmit={onSubmit} onSkip={onSubmit} />}
        />
        <AdminOnboardingRoute
          path={path('invite-people')}
          render={() => (
            <InvitePeople
              onSubmit={finishOnboarding}
              onSkip={finishOnboarding}
            />
          )}
        />
        <AdminOnboardingRoute path="/" />
      </Switch>
    </Content>
  )
}
