import { Button, Form, Typography } from 'antd'
import { GET_COLLEAGUES, GET_COMPANY_USERS } from './queries'
import React, { useState } from 'react'

import { INVITE_PEOPLE } from './queries'
import TagInput from '../TagInput'
import isEmail from '../../_helpers/isEmail'
import { useMutation } from '@apollo/react-hooks'

const { Text } = Typography

const InvitePeopleForm = ({
  form,
  onSubmit,
  onCancel,
  submitLabel = 'Send invites',
  cancelLabel = 'Cancel',
  preSubmitText,
}) => {
  const { getFieldDecorator, validateFields, getFieldError } = form

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [invitePeople] = useMutation(INVITE_PEOPLE, {
    refetchQueries: [{ query: GET_COLLEAGUES }, { query: GET_COMPANY_USERS }],
  })

  const handleSubmit = async e => {
    e.preventDefault()

    validateFields(async (err, values) => {
      if (err) {
        return
      }

      const emails = values.emails

      setIsSubmitting(true)
      await invitePeople({
        variables: {
          users: emails,
        },
      })

      setIsSubmitting(false)

      if (onSubmit) {
        onSubmit(emails)
      }
    })
  }

  const handleCancel = () => {
    setIsCancelling(true)
    if (onCancel) {
      onCancel()
    }
  }

  const isTouched = field => {
    return form.isFieldTouched(field)
  }

  const hasErrors = field => {
    const errors = getFieldError(field)
    if (!errors) {
      return false
    }
    return errors.length > 0
  }

  const checkEmail = (currentValue, collection) => {
    if (!currentValue) {
      return 'No email specified'
    }
    if (!isEmail(currentValue)) {
      return `This email address isn't valid`
    }
    if (collection.filter(x => x === currentValue).length > 1) {
      return 'This email has been duplicated'
    }
    return
  }

  const checkAllEmails = (rule, emails, callback) => {
    if (emails.length === 0) {
      return callback('You need to enter a least one email address')
    }
    const invalidEmails = emails.filter(email => checkEmail(email, emails))
    if (invalidEmails.length > 0) {
      return callback(
        `You need to correct the invalid email address${
          invalidEmails.length > 1 ? 'es' : ''
        }`,
      )
    }
    return callback()
  }

  const extra = preSubmitText ? (
    <Text
      style={{
        fontSize: '14px',
        fontWeight: 300,
        marginTop: 16,
        color: '#6b6b8f',
      }}
    >
      {preSubmitText}
    </Text>
  ) : null

  return (
    <Form hideRequiredMark colon={false} onSubmit={handleSubmit}>
      <Form.Item label="Email addresses" extra={extra}>
        {getFieldDecorator('emails', {
          initialValue: [],
          required: true,
          rules: [{ validator: checkAllEmails }],
        })(
          <TagInput
            placeholder="name1@company.com, name2@company.com ..."
            style={{ minHeight: 96, maxHeight: 374 }}
            tagValidator={checkEmail}
          />,
        )}
      </Form.Item>

      <div style={{ marginTop: '24px' }}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={
            isSubmitting ||
            isCancelling ||
            hasErrors('emails') ||
            !isTouched('emails')
          }
          loading={isSubmitting}
        >
          {submitLabel}
        </Button>
        <Button
          type="ghost"
          style={{ marginLeft: '10px' }}
          disabled={isSubmitting || isCancelling}
          onClick={handleCancel}
          loading={isCancelling}
        >
          {cancelLabel}
        </Button>
      </div>
    </Form>
  )
}

export default Form.create({ name: 'InvitePeopleForm' })(InvitePeopleForm)
