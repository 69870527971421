import './Onboarding/styles.less'

import React, { useContext } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import AdminOnboarding from './Onboarding/AdminOnboarding'
import { SET_ONBOARDING_STATUS } from './Onboarding/queries'
import { UserContext } from './UserContext'
import UserOnboarding from './Onboarding/UserOnboarding'
import logo from './_assets/img/logo.svg'
import useMobileOptimized from './hooks/device/useMobileOptimized'
import { useMutation } from '@apollo/react-hooks'
import useQuery from './hooks/routing/useQuery'

const AppOnboarding = () => {
  const user = useContext(UserContext)
  const history = useHistory()
  const query = useQuery()

  useMobileOptimized(false)

  const [finishOnboardingMutation] = useMutation(SET_ONBOARDING_STATUS, {
    variables: {
      onboardingStatus: false,
    },
  })

  const finishOnboarding = async () => {
    const redirect = query.has('redirect') ? query.get('redirect') : undefined
    await finishOnboardingMutation()
    await user.refetchUser()
    history.push(redirect ? redirect : '/welcome')
  }

  return (
    <div id="onboarding">
      <div style={{ padding: '40px 0 0' }}>
        <img
          src={logo}
          alt="Howamigoing"
          style={{ display: 'block', margin: '0 auto', maxHeight: '40px' }}
        />
      </div>
      {user.isOwner() ? (
        <AdminOnboarding finishOnboarding={finishOnboarding} />
      ) : (
        <UserOnboarding finishOnboarding={finishOnboarding} />
      )}
    </div>
  )
}

export default withRouter(AppOnboarding)
