import gql from 'graphql-tag'

export const GET_CYCLES_PERSONAL = gql`
  query getCyclesCreatedByUser($type: String!, $running: Boolean!) {
    cycles: getCyclesCreatedByUser(running: $running, type: $type) {
      id
      type
      name
      startDate
      endDate
      settings
      creator {
        id
      }
      reviews {
        id
        user {
          id
        }
        feedbacks {
          id
          submittedAt
          user {
            id
            avatarUrl
            avatar
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`

export const GET_SCHEDULED_CYCLES_PERSONAL = gql`
  query getScheduledCyclesPersonal($type: String!) {
    cycles: getScheduledCyclesPersonal(type: $type) {
      id
      type
      name
      startDate
      endDate
      settings
      reviews {
        id
        feedbacks {
          id
          user {
            id
            avatarUrl
            avatar
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`

export default {
  GET_CYCLES_PERSONAL,
  GET_SCHEDULED_CYCLES_PERSONAL,
}
