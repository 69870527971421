import { Alert, Badge, Button, Icon, Typography, notification } from 'antd'
import unionWith from 'lodash/unionWith'
import React, { useContext, useEffect, useState } from 'react'

import { ReactComponent as inviteButtonImg } from '../../../_assets/img/invite-icon.svg'
import AddPeopleModal from '../../../_components/AddPeopleModal'
import ColleagueList from '../../../_components/ColleagueList/ColleagueList'
import ButtonsBottomPersonal360 from '../../../_components/CycleCreationEditButtonsBottom'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import Personal360Context from '../Personal360Context'
import Personal360StepsContext from '../Personal360StepsContext'

const { Title } = Typography

const DEFAULT_SPACING = 8

export default props => {
  const user = useContext(UserContext)
  const context = useContext(Personal360Context)
  const stepsContext = useContext(Personal360StepsContext)

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedColleagues, setSelectedColleagues] = useState(
    context.requestedUsers,
  )
  const [error, setError] = useState(false)

  useEffect(() => {
    if (context.requestedUsers.length !== selectedColleagues.length) {
      setSelectedColleagues(context.requestedUsers)
    }
  }, [context.requestedUsers])

  const selectColleague = selectedColleague => {
    const selectedColleagueIndex = selectedColleagues.findIndex(colleague => {
      return colleague.key === selectedColleague.key
    })

    if (selectedColleagueIndex === -1) {
      const newColleagues = [...selectedColleagues, selectedColleague]
      setSelectedColleagues(newColleagues)
      context.setContext({ requestedUsers: newColleagues })
    } else {
      const selectedColleaguesCopy = [...selectedColleagues]
      selectedColleaguesCopy.splice(selectedColleagueIndex, 1)
      setSelectedColleagues(selectedColleaguesCopy)
      context.setContext({ requestedUsers: selectedColleaguesCopy })
    }
  }

  const handleSelectMultiple = allColleagues => {
    const newColleagues = unionWith(
      selectedColleagues,
      allColleagues,
      (firstVal, secondVal) => {
        return firstVal.key === secondVal.key
      },
    )
    setSelectedColleagues(newColleagues)
    context.setContext({ requestedUsers: newColleagues })
  }

  const handleDeselectMultiple = (colleagues = false) => {
    if (colleagues) {
      const filteredColleagues = selectedColleagues.filter(
        colleague =>
          !colleagues.find(colleagueItem => colleagueItem.id === colleague.id),
      )

      setSelectedColleagues(filteredColleagues)
      context.setContext({ requestedUsers: filteredColleagues })
    } else {
      setSelectedColleagues([])
      context.setContext({ requestedUsers: [] })
    }
  }

  const handleForm = () => {
    if (context.requestedUsers.length < 2) {
      setError('Please select at least 2 colleagues to start a Personal 360.')
      return
    }
    setError(null)
    track('personal360.cycle.feedbackProvidersNextStep', {
      user: user.email,
      org: user.org.name,
      amountFeedbackProviders: context.requestedUsers.length,
    })
    const newUrl = context.id
      ? `/personal360/edit/${context.id}/3`
      : '/personal360/create/3'
    stepsContext.setStep(2)
    props.history.push(newUrl)
  }

  const onUsersAdded = invitedUsers => {
    let message
    let grammaticalNumberText

    track('personal360.cycle.invitePeopleSend', {
      user: user.email,
      org: user.org.name,
      amountInvites: invitedUsers.length,
    })

    if (invitedUsers.length > 1) {
      message = 'Invite sent!'
      grammaticalNumberText = 'invites were'
    } else {
      message = 'Invites sent!'
      grammaticalNumberText = 'invite was'
    }

    notification.success({
      message: message,
      description: `${invitedUsers.length} new ${grammaticalNumberText} just sent!`,
    })

    setModalVisible(false)
  }

  return (
    <div
      className="new-cycle-enroll-users"
      style={{ paddingTop: DEFAULT_SPACING }}
    >
      <Title level={4} style={{ marginBottom: DEFAULT_SPACING }}>
        Who would you like to receive feedback from?
        <Badge
          showZero
          overflowCount={1000}
          count={selectedColleagues.length}
          style={{
            background: '#f95c4b',
            fontFamily: 'Europa',
            marginLeft: DEFAULT_SPACING,
            fontSize: DEFAULT_SPACING * 2,
          }}
        />
      </Title>

      <ColleagueList
        height={400}
        withSearch
        withSelectAll
        handleSelectMultiple={handleSelectMultiple}
        handleDeselectMultiple={handleDeselectMultiple}
        selectedColleagues={selectedColleagues}
        selectColleagueCallback={selectColleague}
      />

      {error && (
        <Alert
          style={{
            color: '#f95c4b',
            marginTop: DEFAULT_SPACING * 3,
            backgroundColor: 'rgba(249, 92, 75, 0.12)',
            borderColor: '#f95c4b',
          }}
          icon={<Icon type="close-circle" style={{ color: '#f95c4b' }} />}
          description={error}
          type="error"
          showIcon
        />
      )}

      <div style={{ paddingTop: DEFAULT_SPACING * 3 }}>
        <div style={{ fontSize: 18, color: '#1c1047' }}>Not listed above?</div>
        <Button
          type="link"
          onClick={() => setModalVisible(true)}
          style={{
            color: '#f95c4b',
            fontWeight: 'normal',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Icon
            component={inviteButtonImg}
            className="box-shadow-primary"
            style={{
              fontSize: 40,
              height: 40,
              borderRadius: 20,
            }}
          />
          <span style={{ textDecoration: 'underline' }}>
            Invite more people to use Howamigoing
          </span>
        </Button>
        <AddPeopleModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
          onSuccess={onUsersAdded}
        />
      </div>

      <ButtonsBottomPersonal360
        step={2}
        handleContinueClick={handleForm}
        contextId={context.id}
        typePath={'/personal360'}
        fromWBYHT={context.fromWBYHT}
      />
    </div>
  )
}
