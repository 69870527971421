import { Card, Typography } from 'antd'
import React from 'react'

import Running360 from '../../_components/Running360/'

const { Text } = Typography

export default props => {
  return (
    <div className="cycles">
      <Card className="top-title">
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Team 360
        </Text>
      </Card>
      {props.menu}
      <Running360 />
    </div>
  )
}
