import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Col, Icon, Row, Typography, notification } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as pencilButtonImg } from '../../../_assets/img/pencil.svg'
import ButtonsBottom360 from '../../../_components/CycleCreationEditButtonsBottom'
import { track } from '../../../_helpers/analytics'
import QueriesFor360 from '../../../Team360/queries'
import { UserContext } from '../../../UserContext'
import ThreeSixtyContext from '../360Context'
import ThreeSixtyStepsContext from '../360StepsContext'
import ThreeSixtyUpdateContext from '../360UpdateContext'
import { CREATE_CYCLE, GET_CYCLE, UPDATE_CYCLE } from '../queries'

const { Title } = Typography

const DEFAULT_SPACING = 8
const sectionTitleType = 4
const gutterSize = 16
const COLOR_DARK = '#281765'
const STYLE_LABEL = { fontSize: 18, marginBottom: 0 }
const STYLE_LETTER_BIG = {
  fontSize: 16,
  fontWeight: 300,
  color: COLOR_DARK,
}
const STYLE_LETTER_BIG_MARGIN = {
  ...STYLE_LETTER_BIG,
  marginBottom: DEFAULT_SPACING * 2,
}
const STYLE_ROW = {
  borderBottom: '1px solid #e8e8e8',
  paddingBottom: DEFAULT_SPACING * 3,
  marginBottom: DEFAULT_SPACING * 3,
}

export default props => {
  const context = useContext(ThreeSixtyContext)
  const updateContext = useContext(ThreeSixtyUpdateContext)
  const stepsContext = useContext(ThreeSixtyStepsContext)
  const user = useContext(UserContext)

  const [momentStartDate, setMomentStartDate] = useState(false)
  const [cycleStartsToday, setCycleStartsToday] = useState(false)
  const [timezone, setTimezone] = useState(false)

  useEffect(() => {
    if (context.timezone) {
      const momentTimezone = moment.tz(context.timezone)
      const gmt = momentTimezone.format('Z').split(':')
      setTimezone(
        context.timezone.split('_').join(' ') +
          ` (GMT ${gmt[0] + (gmt[1] !== '00' ? ':' + gmt[1] : '')})`,
      )
    }
    if (context.startDate && context.timezone) {
      const startDate = moment.unix(context.startDate).tz(context.timezone)
      setMomentStartDate(startDate)
      setCycleStartsToday(startDate.isSame(moment.tz(context.timezone), 'day'))
    }
  }, [])

  const createCycleData = {
    variables: {
      type: 'THREESIXTY',
      orgId: user.org.id,
      name: context.name,
      startDate: context.startDate,
      endDate: context.endDate,
      formId: context.form && context.form.id,
      requesters:
        context.enrolledUsers && context.enrolledUsers.map(user => user.key),
      minRequests: context.minRequests,
      maxRequests: context.maxRequests,
      timezone: context.timezone,
    },
  }

  if (cycleStartsToday) {
    createCycleData.refetchQueries = [
      {
        query: QueriesFor360.GET_CYCLES,
        variables: {
          type: '360',
          running: true,
        },
      },
    ]
  } else {
    createCycleData.refetchQueries = [
      {
        query: QueriesFor360.GET_SCHEDULED_CYCLES,
        variables: {
          type: '360',
        },
      },
    ]
  }

  const [createCycle] = useMutation(CREATE_CYCLE, createCycleData)

  // TODO: change refetchQueries when we'll update running cycles as well
  const [updateCycle] = useMutation(UPDATE_CYCLE, {
    variables: {
      ...updateContext,
      cycleId: context.id,
      setUpdateContext: undefined,
    },
    refetchQueries: [
      {
        query: QueriesFor360.GET_SCHEDULED_CYCLES,
        variables: {
          type: '360',
        },
      },
      {
        query: GET_CYCLE,
        variables: {
          cycleId: context.id,
        },
      },
    ],
  })

  const submitCycle = () => {
    try {
      const handleSuccess = () => {
        cycleStartsToday
          ? props.history.push('/team360/ongoing')
          : props.history.push('/team360/scheduled')
      }

      if (context.id) {
        updateCycle().then(handleSuccess)
      } else {
        createCycle().then(handleSuccess)
      }
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description: 'It appears an error ocurred. Please try again later.',
      })
    }

    if (context.id) {
      track('team360.cycle.update', {
        updater: user.email,
        org: user.org.name,
        cycleName: context.name,
      })
    } else {
      track('team360.cycle.create', {
        creator: user.email,
        org: user.org.name,
        cycleName: context.name,
      })
    }
  }

  const buttonEdit = step => {
    return (
      <Link
        to={
          context.id
            ? `/team360/edit/${context.id}/${step}`
            : `/team360/create/${step}`
        }
      >
        <Button
          type="link"
          style={{ padding: 0, height: 'auto', color: '#f95c4b', fontSize: 14 }}
          onClick={() => stepsContext.setStep(step - 1)}
        >
          <Icon
            component={pencilButtonImg}
            style={{
              fontSize: 12,
              backgroundColor: '#ffeeec',
              borderRadius: 8,
              padding: '4px 6px',
            }}
          />
          Edit
        </Button>
      </Link>
    )
  }

  return (
    momentStartDate && (
      <div className="new-cycle-summary">
        <Card style={{ marginBottom: DEFAULT_SPACING * 2 }}>
          <div style={{ padding: DEFAULT_SPACING }}>
            <Row gutter={gutterSize} style={STYLE_ROW}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  Selected form:
                </Title>
              </Col>

              <Col span={14}>
                <div style={STYLE_LETTER_BIG}>{context.form.title}</div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                {buttonEdit(1)}
              </Col>
            </Row>
            <Row gutter={gutterSize} style={STYLE_ROW}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  People enrolled:
                </Title>
              </Col>
              <Col span={14}>
                <div style={STYLE_LETTER_BIG_MARGIN}>
                  {context.enrolledUsers.length} people
                </div>
                <div className="small-letter">
                  Enrolled people need to ask a{' '}
                  <span style={{ color: COLOR_DARK }}>
                    minimum of {context.minRequests}
                  </span>{' '}
                  and a{' '}
                  <span style={{ color: COLOR_DARK }}>
                    maximum of {context.maxRequests}
                  </span>{' '}
                  colleagues for feedback.
                </div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                {buttonEdit(2)}
              </Col>
            </Row>
            <Row gutter={gutterSize}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  Event details:
                </Title>
              </Col>

              <Col span={14}>
                <div
                  className="small-letter"
                  style={{ marginBottom: DEFAULT_SPACING }}
                >
                  Name
                </div>
                <div style={STYLE_LETTER_BIG_MARGIN}>{context.name}</div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                {buttonEdit(3)}
              </Col>
            </Row>
            <Row gutter={gutterSize} style={STYLE_ROW}>
              <Col offset={6} span={18}>
                <Row gutter={gutterSize}>
                  <Col span={5}>
                    <div
                      className="small-letter"
                      style={{ marginBottom: DEFAULT_SPACING }}
                    >
                      Start date
                    </div>
                    <div style={STYLE_LETTER_BIG}>
                      {moment
                        .unix(context.startDate)
                        .tz(context.timezone)
                        .format('DD MMM YYYY')}
                    </div>
                  </Col>
                  <Col span={5}>
                    <div
                      className="small-letter"
                      style={{ marginBottom: DEFAULT_SPACING }}
                    >
                      End date
                    </div>
                    <div style={STYLE_LETTER_BIG}>
                      {moment
                        .unix(context.endDate)
                        .tz(context.timezone)
                        .format('DD MMM YYYY')}
                    </div>
                  </Col>
                  <Col span={14}>
                    <div
                      className="small-letter"
                      style={{ marginBottom: DEFAULT_SPACING }}
                    >
                      Timezone
                    </div>
                    <div style={STYLE_LETTER_BIG}>{timezone}</div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={gutterSize}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  Notifications:
                </Title>
              </Col>
              <Col span={18}>
                <div style={STYLE_LETTER_BIG}>
                  We'll take care of notifying your team about this event :-)
                  <br />
                  They'll receive email reminders to request and give feedback
                  on Tuesdays and Fridays, and to view feedback when the event
                  ends.
                </div>
              </Col>
            </Row>
          </div>
        </Card>
        <ButtonsBottom360
          step={4}
          handleContinueClick={submitCycle}
          contextId={context.id}
          typePath={'/team360'}
          fromWBYHT={context.fromWBYHT}
        />
      </div>
    )
  )
}
