import { useQuery } from '@apollo/react-hooks'
import { Card, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { Avatar, AvatarName } from '../_components/Avatar'
import SafePlaceNotice from '../_components/SafePlaceNotice'
import AutoSaveContextProvider from '../AutoSaveContext'
import FormWrapper from './FormWrapper'
import GiveFeedbackContext from './giveFeedbackContext'
import { GET_FEEDBACK } from './queries'

const { Text } = Typography

export default ({ match, history }) => {
  const [user, setUser] = useState()
  const [form, setForm] = useState()
  const [cycleType, setCycleType] = useState()

  const GiveFeedbackContextData = {
    feedbackId: match.params.id,
  }

  const { data, error, loading } = useQuery(GET_FEEDBACK, {
    variables: {
      feedbackId: match.params.id,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data && data.feedback) {
      if (data.feedback.submittedAt) {
        history.push('/home')
      }

      setUser(data.feedback.review.user)
      setForm(data.feedback.review.cycle.form)
      setCycleType(data.feedback.review.cycle.type)
    }
  }, [data])

  if (loading) return <p>Loading…</p>
  if (error) return <p>Error!</p>

  return (
    <div className="give-feedback">
      <GiveFeedbackContext.Provider value={GiveFeedbackContextData}>
        <AutoSaveContextProvider>
          <Card className="top-title" style={{ marginBottom: 32 }}>
            <Avatar user={user} withRightMargin disableTooltip />
            <Text
              style={{
                fontFamily: 'museo, serif',
                color: '#1c1047',
                fontSize: '18px',
              }}
            >
              Giving feedback to{' '}
              <AvatarName user={user} style={{ color: '#f95c4b' }} />
            </Text>
          </Card>

          <SafePlaceNotice />

          {form && (
            <FormWrapper form={{ ...form, user: user }} cycleType={cycleType} />
          )}
        </AutoSaveContextProvider>
      </GiveFeedbackContext.Provider>
    </div>
  )
}
