import { useQuery } from '@apollo/react-hooks'
import { Card, Typography } from 'antd'
import deepClone from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import React, { useContext } from 'react'
import Emoji from 'react-emoji-render'

import CyclesList from './../../_components/CycleCard/CyclesList'
import { UserContext } from '../../UserContext'
import { handleGetCyclesResponse } from '../helpers'
import { GET_CYCLES } from '../queries'
import { GET_MANAGER_CYCLES } from './queries'

const { Text } = Typography

export default props => {
  const user = useContext(UserContext)

  const isUserManager = user.isManager()

  const {
    data: dataCycles,
    error: errorCycles,
    loading: loadingCycles,
  } = useQuery(GET_CYCLES, {
    variables: {
      type: '360',
      running: false,
    },
  })

  const {
    data: dataManager,
    error: errorManager,
    loading: loadingManager,
  } = useQuery(GET_MANAGER_CYCLES, {
    variables: { type: '360' },
    skip: !isUserManager,
    fetchPolicy: 'no-cache',
  })

  if (loadingCycles || loadingManager) {
    return <p>Loading…</p>
  }

  if (errorCycles || errorManager) {
    return <p>Error!</p>
  }

  let cycles = []

  if (!loadingCycles && !loadingManager && !errorCycles && !errorManager) {
    if (dataCycles && dataCycles.cycles && dataCycles.cycles.length > 0) {
      cycles = handleGetCyclesResponse({ data: dataCycles, userId: user.id })
    }

    if (isUserManager && dataManager && dataManager.cycles) {
      dataManager.cycles.forEach(managerCycle => {
        const cycleIndex =
          cycles.length > 0 &&
          cycles.findIndex(cycle => cycle.id === managerCycle.managerCycleId)

        if (cycleIndex !== false && cycleIndex > -1) {
          const cycleClone = deepClone(cycles[cycleIndex])
          cycleClone.isManager = true
          cycleClone.managerReviews = managerCycle.reviews
          cycles[cycleIndex] = cycleClone
        } else {
          const managerCycleClone = deepClone(managerCycle)
          managerCycleClone.id = managerCycleClone.managerCycleId
          managerCycleClone.reviews = []
          managerCycleClone.isManager = true
          managerCycleClone.managerReviews = managerCycle.reviews
          cycles.push(managerCycleClone)
        }
      })
    }
  }

  cycles = sortBy(cycles, [cycle => cycle.endDate]).reverse()

  return (
    <div className="cycles">
      <Card className="top-title">
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Team 360
        </Text>
      </Card>
      {props.menu}
      {cycles && cycles.length > 0 ? (
        <CyclesList cycles={cycles} />
      ) : (
        <div
          style={{
            color: '#77779f',
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 300,
          }}
        >
          <div style={{ margin: '16px 0 0' }}>
            Nothing to see here.
            <Emoji text={':shrug:'} style={{ fontSize: '24px' }} />
          </div>
          <div>
            {user.isOwner() ||
            user.isManager() ||
            user.isAdmin() ||
            user.isSuperUser()
              ? 'To get the ball rolling please create a new event.'
              : 'Ask your Manager or your company’s Howamigoing account owner to create a new Team 360 feedback event, or go to Personal 360 to request some feedback for yourself.'}
          </div>
        </div>
      )}
    </div>
  )
}
