import { useQuery } from '@apollo/react-hooks'
import {
  Button,
  Card,
  Col,
  Icon,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import CreateForm from '../_components/CreateForm'
import PrivateCycleContext from './privateCycleContext'
import { GET_FEEDBACK_FORMS } from './queries'

const { TextArea } = Input
const { Paragraph, Title } = Typography

const dummyForm = {
  id: 'new',
  title: 'Create a new form',
}

export default ({ visible }) => {
  const privateCycleContext = useContext(PrivateCycleContext)
  const [modalVisible, setModalVisible] = useState(false)
  const [previewModalVisible, setPreviewModalVisible] = useState(false)
  const [forms, setForms] = useState([dummyForm])
  const [questionsToPreview, setQuestionsToPreview] = useState([])

  const {
    data: formsData,
    error: formsError,
    loading: formsLoading,
  } = useQuery(GET_FEEDBACK_FORMS)

  useEffect(() => {
    if (formsData.formsFromOrg && formsData.formsFromOrg.length > 0) {
      setForms([dummyForm, ...formsData.formsFromOrg])
    }
  }, [formsData])

  function selectFormClick(formId) {
    if (formId === 'new') {
      setModalVisible(true)
    } else {
      privateCycleContext.setCycleFormId(formId)
      privateCycleContext.setShowRequestColleaguesStep(true)
    }
  }

  function previewFormClick(formId) {
    const form = forms.find(element => element.id === formId)

    if (form) {
      if (form.formQuestions.length > 0) {
        setQuestionsToPreview([...form.formQuestions])
        setPreviewModalVisible(true)
      } else {
        notification.warning({
          message: 'Oops!',
          description:
            "It seems the form you selected doesn't have any questions. Please pick another one!",
        })
      }
    }
  }

  if (!visible) return <span></span>
  if (formsLoading) return <span></span>
  if (formsError) return <span></span>

  return (
    <div className="get-feedback">
      <Card title={<Title>Private feedback</Title>}>
        <Paragraph>Welcome to Private Feedback</Paragraph>
      </Card>

      <Row type="flex" gutter={16} style={{ marginTop: '30px' }}>
        {forms.map((form, key) => (
          <Col key={form.id} xs={6} style={{ marginBottom: '30px' }}>
            <Card style={{ textAlign: 'center', height: '100%' }}>
              <Icon
                style={{ fontSize: '30px', marginBottom: '15px' }}
                type="form"
              />

              <Paragraph>{form.title}</Paragraph>

              <div>
                <Button
                  key={form.id}
                  type="primary"
                  onClick={() => selectFormClick(form.id)}
                >
                  Select
                </Button>
              </div>

              {key > 0 && (
                <div style={{ marginTop: '15px' }}>
                  <Button
                    key={form.id}
                    type="ghost"
                    onClick={() => previewFormClick(form.id)}
                  >
                    Preview
                  </Button>
                </div>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title="Create a new form"
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <CreateForm onSubmit={() => setModalVisible(false)} />
      </Modal>

      <Modal
        title="Preview the form"
        visible={previewModalVisible}
        footer={null}
        onCancel={() => setPreviewModalVisible(false)}
      >
        <Paragraph>Here are the questions included in the form</Paragraph>
        {questionsToPreview.map(formQuestion => (
          <TextArea
            key={formQuestion.id}
            placeholder="Lorem ipsum dolor sit amet consectetur adipiscing elit aenean."
            autosize={{ minRows: 3, maxRows: 6 }}
            disabled={true}
            style={{ marginBottom: '20px', resize: 'none' }}
          />
        ))}
      </Modal>
    </div>
  )
}
