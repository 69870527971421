import React, { useEffect, useRef, useState } from 'react'

import { Icon } from 'antd'

const Tag = ({
  type = 'primary',
  value,
  closable = false,
  editable = false,
  selected = false,
  onClose,
  onEdit,
  onEdited,
  truncateAt,
  ...props
}) => {
  const [editing, setEditing] = useState(false)
  const textRef = useRef(null)

  const sharedStyles = {
    height: '32px',
    lineHeight: '30px',
    borderRadius: '12px',
    display: 'inline-block',
    padding: '0 12px',
    position: 'relative',
  }

  const primaryStyles = {
    background: '#f3f2f7',
    border: '1px solid #f3f2f7',
    color: '#1c1047',
  }
  const errorStyles = {
    background: 'none',
    border: '1px solid #ed4758',
    color: '#ed4758',
  }

  const editingStyles = {
    ...primaryStyles,
    background: 'none',
  }

  const closableStyles = {
    paddingRight: 30,
  }

  const selectedStyles = {
    background: '#dcd9e8',
    border: '1px solid #dcd9e8',
  }

  const containerStyle = {
    ...sharedStyles,
    ...(type === 'primary' ? primaryStyles : errorStyles),
    ...props.style,
    ...(closable ? closableStyles : undefined),
    ...(editing ? editingStyles : undefined),
    ...(selected ? selectedStyles : undefined),
  }

  const closableContainerStyle = {
    height: sharedStyles.height,
    width: closableStyles.paddingRight,
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const truncateStyles = {
    display: 'block',
    maxWidth: truncateAt,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }

  const textStyles = {
    outline: 'none',
    ...(truncateAt ? truncateStyles : undefined),
    ...(editable ? { cursor: 'text' } : undefined),
  }

  const handleOnDoubleClick = evt => {
    evt.stopPropagation()
    setEditing(true)
  }

  const handleOnKeyDown = evt => {
    // evt.preventDefault()
    // evt.stopPropagation()
    if (onEdit) {
      onEdit(textRef.current.innerText)
    }
  }

  const handleOnBlur = evt => {
    evt.stopPropagation()
    if (!editing) {
      return
    }
    setEditing(false)
    if (onEdited) {
      onEdited(textRef.current.innerText)
    }
  }

  const handleOnContainerClick = evt => {
    evt.stopPropagation()
  }

  useEffect(() => {
    setEditing(editing)
    textRef.current.focus()
  }, [editing])

  return (
    <div {...props} style={containerStyle} onClick={handleOnContainerClick}>
      <span
        onClick={handleOnDoubleClick}
        contentEditable={editing}
        onKeyDown={handleOnKeyDown}
        onBlur={handleOnBlur}
        style={textStyles}
        ref={textRef}
      >
        {value}
      </span>
      {closable && (
        <div style={closableContainerStyle} onClick={onClose}>
          <Icon type="close" style={{ fontSize: '0.7rem' }} />
        </div>
      )}
    </div>
  )
}

export default Tag
