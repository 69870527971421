import gql from 'graphql-tag'

const formObject = `
    id
    title
    recipients
    description
    sections
    formQuestions {
      id
      question {
        id
        titleVariants
        description
        template {
          id
          type
          settings
        }
      }
    }
`

export const GET_FORMS = gql`
  query defaultForms($type: String!) {
    defaultForms(type: $type) {
      ${formObject}
    }
    formsFromOrg(type: $type) {
      ${formObject}
    }
  }
`
