import { Card, Progress, Typography } from 'antd'
import React, { useContext } from 'react'

import InvitePeopleForm from '../_components/InvitePeopleForm'
import { SET_ONBOARDING_STEP_STATUS } from './queries'
import { UserContext } from '../UserContext'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'

const { Title, Text } = Typography

export default ({ onSubmit, onSkip }) => {
  const user = useContext(UserContext)

  const [markAsSkippedInvite] = useMutation(SET_ONBOARDING_STEP_STATUS, {
    variables: {
      step: 'INVITE_PEOPLE',
      status: 'SKIPPED',
    },
  })

  const [markAsInvitedPeople] = useMutation(SET_ONBOARDING_STEP_STATUS, {
    variables: {
      step: 'INVITE_PEOPLE',
      status: 'COMPLETE',
    },
  })

  const handleSkip = async () => {
    await markAsSkippedInvite()
    track('admin.onboarding.invitePeopleSkipped', {
      user: user.email,
      org: user.org.name,
    })
    if (onSkip) {
      onSkip()
    }
  }

  const handleSubmit = async emails => {
    await markAsInvitedPeople()
    track('admin.onboarding.invitePeople', {
      user: user.email,
      org: user.org.name,
      numberPeopleInvited: emails.length,
    })
    if (onSubmit) {
      onSubmit()
    }
  }

  return (
    <div className="onboarding-invite-people">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontSize: '24px', fontWeight: 500 }}
        >
          It takes two to tango (and to get feedback). Let's add some people to
          get the ball rolling...
        </Title>

        <InvitePeopleForm
          submitLabel="Send invites"
          cancelLabel="Skip for now"
          preSubmitText="Don't worry, you can add others later on."
          onCancel={handleSkip}
          onSubmit={handleSubmit}
        />
      </Card>

      <div style={{ marginTop: '48px' }}>
        <Text style={{ fontSize: '14px', fontWeight: 300 }}>
          Step 4 out of 4
        </Text>
        <Progress
          style={{
            marginTop: '8px',
          }}
          strokeColor={'#77779f'}
          percent={100}
          showInfo={false}
        />
      </div>
    </div>
  )
}
