import gql from 'graphql-tag'

export const GET_CYCLES = gql`
  query getCyclesForUser($type: String, $running: Boolean!) {
    cycles: getCyclesForUser(running: $running, type: $type) {
      id
      type
      name
      startDate
      endDate
      settings
      creator {
        id
      }
      reviews {
        id
        user {
          id
          avatar
          avatarUrl
          firstName
          lastName
          email
          jobTitle
        }
        feedbacks {
          id
          submittedAt
          user {
            id
            avatarUrl
            avatar
            firstName
            lastName
            email
            jobTitle
          }
        }
      }
    }
  }
`

export const GET_SCHEDULED_CYCLES = gql`
  query scheduledCycles($type: String!) {
    cycles: getScheduledCycles(type: $type) {
      id
      type
      name
      startDate
      endDate
      settings
      reviews {
        id
        user {
          id
          firstName
          lastName
          email
          avatarUrl
          avatar
        }
      }
    }
  }
`

export default {
  GET_CYCLES,
  GET_SCHEDULED_CYCLES,
}
