import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import {
  CREATE_NEW_FORM,
  GET_FEEDBACK_FORMS,
  GET_QUESTION_TEMPLATES,
} from '../PrivateFeedback/queries'

const createForm = props => {
  const { getFieldDecorator } = props.form
  const [formSaving, setFormSaving] = useState(false)
  const [formTitle, setFormTitle] = useState('')
  const [formDescription, setFormDescription] = useState('')
  const [formQuestions, setFormQuestions] = useState([])

  const {
    data: questionTemplatesData,
    error: questionTemplatesError,
    loading: questionTemplatesLoading,
  } = useQuery(GET_QUESTION_TEMPLATES, {
    skip: !!props.questions,
  })

  const [createFormMutation] = useMutation(CREATE_NEW_FORM, {
    variables: {
      formTitle,
      formDescription,
      newQuestions: formQuestions.map(fq => {
        const { templateSettings, ...restFq } = fq
        return restFq
      }),
    },
    refetchQueries: [
      {
        query: GET_FEEDBACK_FORMS,
      },
    ],
  })

  useEffect(() => {
    if (!props.questions) {
      if (
        questionTemplatesData &&
        questionTemplatesData.questionTemplatesFromOrg
      ) {
        // an org needs to have at least one 'SHORT_TEXT' question template
        const questionTemplate = questionTemplatesData.questionTemplatesFromOrg.find(
          qt => qt.type === 'SHORT_TEXT',
        )

        if (questionTemplate) {
          // create an array of 4 identical questions
          // from creates an array from an entity that has a length property
          // afterwards we map over each element
          setFormQuestions(
            Array.from({ length: 4 }, el => {
              return {
                titleVariants: [{ title: '', target: 'other' }],
                description: '',
                templateId: questionTemplate.id,
                templateSettings: questionTemplate.settings,
              }
            }),
          )
        }
      }
    } else {
      setFormQuestions(
        props.questions.map(question => {
          return {
            titleVariants: [{ title: '', target: 'other' }],
            description: '',
            templateId: question.templateId,
            templateSettings: question.templateSettings,
          }
        }),
      )
    }
  }, [props.questions, questionTemplatesData])

  const updateFormQuestion = (index, value) => {
    let formQuestionsCopy = [...formQuestions]
    formQuestionsCopy[index].titleVariants[0].title = value
    setFormQuestions(formQuestionsCopy)
  }

  const handleSubmit = e => {
    e.preventDefault()
    props.form.validateFields(err => {
      if (!err) {
        setFormSaving(true)
        createFormMutation().then(() => {
          setFormSaving(false)
          props.onSubmit()
        })
      }
    })
  }

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Form.Item label="Name your form">
        {getFieldDecorator('title', {
          rules: [
            { required: true, message: 'Please input the form title!' },
            { max: 40, message: 'Your form title is too long!' },
          ],
        })(
          <Input
            placeholder="What do you want to call this form?"
            onChange={e => setFormTitle(e.target.value)}
          />,
        )}
      </Form.Item>

      <Form.Item label="Give this form a short description">
        {getFieldDecorator('description', {
          rules: [
            { required: true, message: 'Please input the form description!' },
            { max: 200, message: 'Your form title is too long!' },
          ],
        })(
          <Input
            placeholder="Type here"
            onChange={e => setFormDescription(e.target.value)}
          />,
        )}
      </Form.Item>

      {questionTemplatesLoading && <span>Loading form questions...</span>}

      {!questionTemplatesLoading && questionTemplatesError && (
        <span>There was an error loading the form questions :(</span>
      )}

      {!questionTemplatesLoading &&
        !questionTemplatesError &&
        formQuestions.map((question, key) => (
          <Form.Item
            key={key}
            label={'Question ' + (key + 1) + ' - Type your question in here'}
          >
            {getFieldDecorator('question' + (key + 1), {
              rules: [
                { required: false, message: 'Please input your question!' },
                {
                  max: question.templateSettings.characterLimit,
                  message: 'Your question is too long!',
                },
              ],
            })(
              <Input
                placeholder="How do you want to phrase it?"
                onChange={e => updateFormQuestion(key, e.target.value)}
              />,
            )}
          </Form.Item>
        ))}

      <Form.Item style={{ textAlign: 'center' }}>
        <Button type="primary" htmlType="submit" loading={formSaving}>
          Save form
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'createForm' })(createForm)
