import React, { useContext } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { Layout } from 'antd'
import SetFullName from './SetFullName'
import SetJobTitle from './SetJobTitle'
import SetPassword from './SetPassword'
import { UserContext } from '../UserContext'
import Welcome from './WelcomeTerms'
import useQuery from '../hooks/routing/useQuery'

const { Content } = Layout

const getSteps = user => {
  if (user && user.data && user.data.onboarding) {
    return user.data.onboarding
  }
  return {}
}

const hasStartedOnboarding = steps => {
  return Object.keys(steps).length > 0
}

const shouldRenderTermsStep = steps => {
  return steps.onboardingTermsStep !== 'complete'
}

const shouldRenderPasswordStep = steps => {
  return (
    !shouldRenderTermsStep(steps) && steps.onboardingPasswordStep !== 'complete'
  )
}

const shouldRenderNameStep = steps => {
  return !shouldRenderTermsStep(steps) && !steps.onboardingFullNameStep
}

const shouldRenderJobTitleStep = steps => {
  return !shouldRenderPasswordStep(steps) && !steps.onboardingJobTitleStep
}

const shouldRenderPath = user => {
  const prefix = '/onboarding'
  const defaultPath = `${prefix}/welcome`
  const steps = getSteps(user)
  if (shouldRenderTermsStep(steps)) {
    return defaultPath
  }
  if (shouldRenderNameStep(steps)) {
    return `${prefix}/set-full-name`
  }
  if (shouldRenderPasswordStep(steps)) {
    return `${prefix}/set-password`
  }
  if (shouldRenderJobTitleStep(steps)) {
    return `${prefix}/set-job-title`
  }
  return defaultPath
}

const hasCompletedOnboarding = user => {
  if (user.status === 'ACTIVE') {
    return true
  }
  const steps = getSteps(user)
  if (!hasStartedOnboarding(steps)) {
    return false
  }
  return (
    steps.onboardingTermsStep === 'complete' &&
    steps.onboardingFullNameStep === 'complete' &&
    steps.onboardingPasswordStep === 'complete' &&
    steps.onboardingJobTitleStep
  )
}

const UserOnboardingRoute = ({ path, render, component: Component }) => {
  const user = useContext(UserContext)
  return (
    <Route
      path={path}
      render={props => {
        const { location } = props
        if (hasCompletedOnboarding(user)) {
          return <Redirect to="/home" />
        }
        const targetPath = shouldRenderPath(user)
        if (!location.pathname.startsWith(targetPath)) {
          const query = new URLSearchParams(location.search)
          const redirectPath = query.get('redirect')
          const redirectParams = redirectPath ? `?redirect=${redirectPath}` : ''
          return (
            <Redirect
              to={{
                pathname: targetPath,
                search: redirectParams,
                state: { from: location },
              }}
            />
          )
        }
        return render ? render(props) : <Component {...props} />
      }}
    />
  )
}

export default ({ finishOnboarding }) => {
  const path = page => `/onboarding/${page}*`
  const user = useContext(UserContext)
  const query = useQuery()
  const history = useHistory()

  if (!user.isAuthenticated) {
    return <p>Please click the link in your invitation email to continue</p>
  }

  const onSubmit = async () => {
    const redirect = query.has('redirect')
      ? `?redirect=${query.get('redirect')}`
      : ''
    await user.refetchUser()
    const targetPath = shouldRenderPath(user)
    history.push(`${targetPath}${redirect}`)
  }

  return (
    <Content className="app-onboarding">
      <Switch>
        <UserOnboardingRoute
          path={path('welcome')}
          render={() => <Welcome onSubmit={onSubmit} />}
        />
        <UserOnboardingRoute
          path={path('set-full-name')}
          render={() => <SetFullName onSubmit={onSubmit} />}
        />
        <UserOnboardingRoute
          path={path('set-password')}
          render={() => (
            <SetPassword stepIndex={2} totalSteps={3} onSubmit={onSubmit} />
          )}
        />
        <UserOnboardingRoute
          path={path('set-job-title')}
          render={() => (
            <SetJobTitle
              stepIndex={3}
              totalSteps={3}
              onSubmit={finishOnboarding}
              onSkip={finishOnboarding}
            />
          )}
        />
        <UserOnboardingRoute path="/" />
      </Switch>
    </Content>
  )
}
