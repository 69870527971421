import { Card, Typography } from 'antd'
import {
  Personal360CreateEditRoutes,
  RouteWithSubRoutes,
} from '../../AppRoutes'
import React, { useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import CycleContextProvider from './Personal360ContextProvider'
import CycleStepsContext from './Personal360StepsContext'
import Steps from './Steps'

const { Text } = Typography

const DEFAULT_SPACING = 8

export default props => {
  const cycleId = props.match.params.cycleId
  const location = useLocation()

  const selectedRouteIndex = Personal360CreateEditRoutes.routes.findIndex(
    route => matchPath(props.location.pathname, { path: route.path }),
  )

  const [currentStep, setCurrentStep] = useState(selectedRouteIndex)

  const StepsContext = {
    ...CycleStepsContext,
    setStep: i => setCurrentStep(i),
  }

  return (
    <div>
      <Card className="top-title">
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Get some feedback for myself
        </Text>
      </Card>
      <CycleContextProvider
        cycleId={cycleId}
        formId={location.state && location.state.formId}
        fromWBYHT={location.state && location.state.fromWBYHT}
      >
        <CycleStepsContext.Provider value={StepsContext}>
          <div className="new-cycle" style={{ marginTop: DEFAULT_SPACING * 5 }}>
            <Steps currentStep={currentStep} setCurrentStep={setCurrentStep} />
            <div style={{ marginTop: DEFAULT_SPACING * 4 }}>
              {Personal360CreateEditRoutes.routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </div>
          </div>
        </CycleStepsContext.Provider>
      </CycleContextProvider>
    </div>
  )
}
