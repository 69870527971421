import gql from 'graphql-tag'

export const INVITE_PEOPLE = gql`
  mutation invitePeople($users: [String!]!) {
    invitePeople(invitedPeople: { users: $users })
  }
`

export const GET_COLLEAGUES = gql`
  query colleaguesFromOrg {
    colleaguesFromOrg: getUsersFromOrg {
      id
      firstName
      lastName
      email
      avatarUrl
      avatar
      jobTitle
      groupUsers {
        id
        group {
          id
          type
          name
        }
      }
    }
  }
`

export const GET_COMPANY_USERS = gql`
  query userColleagues {
    users: getUsersFromOrg {
      id
      status
      firstName
      lastName
      email
      jobTitle
      avatarUrl
      userRoles {
        id
        role {
          id
          name
          type
        }
      }
      orgRelationsFrom {
        id
        type
        to {
          id
        }
      }
      orgRelationsTo {
        id
        type
        from {
          id
          firstName
          lastName
          email
        }
      }
      groupUsers {
        id
        group {
          id
          name
          type
        }
      }
    }
  }
`
