import {
  Card,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Row,
  Tooltip,
  Typography,
} from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import ButtonsBottomPersonal360 from '../../../_components/CycleCreationEditButtonsBottom'
import SelectTimezones from '../../../_components/SelectTimezones'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import Personal360Context from '../Personal360Context'
import Personal360StepsContext from '../Personal360StepsContext'

const { Title } = Typography

const MINIMUM_CYCLE_LENGTH_DAYS = 7
const STYLE_LAST_INPUT = {
  boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
  borderRadius: 16,
}
const DEFAULT_SPACING = 8

const StepDetails = props => {
  const user = useContext(UserContext)
  const context = useContext(Personal360Context)
  const stepsContext = useContext(Personal360StepsContext)

  const [nameValidationStatus, setNameValidationStatus] = useState('')
  const [nameHelp, setNameHelp] = useState('')

  const [startDateValidationStatus, setStartDateValidationStatus] = useState('')
  const [startDateHelp, setStartDateHelp] = useState('')
  const [endDateValidationStatus, setEndDateValidationStatus] = useState('')
  const [endDateHelp, setEndDateHelp] = useState('')

  const [timezoneValidationStatus, setTimezoneValidationStatus] = useState('')
  const [timezoneHelp, setTimezoneHelp] = useState('')
  const [timezoneSelected, setTimezoneSelected] = useState(null)

  useEffect(() => {
    if (!context.name && context.form) {
      context.setContext({
        name: context.form.title + moment.tz().format(' (MMM YYYY)'),
      })
    }
  }, [])

  useEffect(() => {
    // By default AntD had the local timezone for moment.js
    // so we need to do this to set the correct timezone
    // when no timezone is selected
    setTimezoneSelected(context.timezone ? context.timezone : moment.tz.guess())
  }, [context.timezone])

  const convertMomentTimezone = (oldMoment, newTimezone) => {
    return moment.tz(oldMoment.format('YYYY-MM-DD HH:mm'), newTimezone)
  }

  // if cycle is starting today, have it start right now
  // otherwise, have cycle start at 9AM on the day
  const setCycleDateStart = (startDate, timezone) => {
    let fixedStartDate
    const now = moment.tz(timezone)
    if (startDate) {
      fixedStartDate = !startDate.isSame(now, 'day')
        ? startDate.set('hour', 9).startOf('hour')
        : now
    }

    context.setContext({
      startDate: fixedStartDate ? fixedStartDate.unix() : null,
    })
  }

  // cycle ends at 17:00 on the last day
  const setCycleDateEnd = (endDate, timezone) => {
    context.setContext({
      endDate: endDate
        ? endDate.tz(timezone).set('hour', 17).startOf('hour').unix()
        : null,
    })
  }

  const startDateChange = (startDate, startDateString) => {
    startDate
      ? setCycleDateStart(
          moment.tz(startDateString, 'DD-MMM-YYYY', timezoneSelected),
          timezoneSelected,
        )
      : context.setContext({ startDate: null })
    context.setContext({ endDate: null })
  }

  const endDateChange = (endDate, endDateString) => {
    endDate
      ? setCycleDateEnd(
          moment.tz(endDateString, 'DD-MMM-YYYY', timezoneSelected),
          timezoneSelected,
        )
      : context.setContext({ endDate: null })
  }

  // When timezone changes the cycleDates have to change
  // because the timestamp isn't the same in different timezones
  // so we recalculate
  const setTimezone = timezone => {
    let changeDayStart
    let currentStartDate
    if (context.startDate) {
      currentStartDate = moment.unix(context.startDate).tz(timezoneSelected)
      const currentDayOldTimezone = moment.tz(timezoneSelected)
      const currentDayNewTimezone = moment.tz(timezone).format('YYYY-MM-DD')

      //Check if with the new timezone the day is changing
      if (
        currentStartDate.isSame(currentDayOldTimezone, 'day') &&
        moment
          .tz(currentDayNewTimezone, timezoneSelected)
          .isAfter(currentDayOldTimezone, 'day')
      ) {
        currentStartDate = moment
          .unix(context.startDate)
          .tz(timezoneSelected)
          .add(1, 'day')
        changeDayStart = true
      }
      // Create the news moment objects from the timestamps with the last timezone that we had
      setCycleDateStart(
        convertMomentTimezone(currentStartDate, timezone),
        timezone,
      )
    }
    if (context.endDate) {
      let currentEndDate = moment.unix(context.endDate).tz(timezoneSelected)
      if (
        changeDayStart &&
        currentEndDate.isBefore(
          currentStartDate
            .clone()
            .add(MINIMUM_CYCLE_LENGTH_DAYS, 'days')
            .startOf('day'),
          'day',
        )
      ) {
        currentEndDate.add(1, 'day')
      }
      setCycleDateEnd(convertMomentTimezone(currentEndDate, timezone), timezone)
    }
    context.setContext({ timezone: timezone })
  }

  const handleForm = () => {
    let hasError = false

    if (context.name === '') {
      setNameValidationStatus('error')
      setNameHelp('Please enter a name')
      hasError = true
    } else {
      setNameValidationStatus('')
      setNameHelp('')
    }

    if (context.startDate === null) {
      setStartDateValidationStatus('error')
      setStartDateHelp('Please select a start date')
      hasError = true
    } else {
      setStartDateValidationStatus('')
      setStartDateHelp('')
    }

    if (context.endDate === null) {
      setEndDateValidationStatus('error')
      setEndDateHelp('Please select an end date')
      hasError = true
    } else {
      setEndDateValidationStatus('')
      setEndDateHelp('')
    }

    if (context.timezone === null) {
      setTimezoneValidationStatus('error')
      setTimezoneHelp('Please select a time zone')
      hasError = true
    } else {
      setTimezoneValidationStatus('')
      setTimezoneHelp('')
    }

    if (!hasError) {
      track('personal360.cycle.finalDetailsNextStep', {
        user: user.email,
        org: user.org.name,
        cycleName: context.name,
        startDate: context.startDate,
        endDate: context.endDate,
        timezone: context.timezone,
      })
      const newUrl = context.id
        ? `/personal360/edit/${context.id}/4`
        : '/personal360/create/4'
      stepsContext.setStep(3)
      props.history.push(newUrl)
    }
  }

  const disabledStartDate = current => {
    current = convertMomentTimezone(current, timezoneSelected).startOf('day')
    return current && current.isBefore(moment.tz(timezoneSelected), 'day')
  }

  const disabledEndDate = current => {
    current = convertMomentTimezone(current, timezoneSelected).startOf('day')
    if (context.startDate) {
      return (
        current &&
        current.isBefore(
          moment
            .unix(context.startDate)
            .tz(timezoneSelected)
            .add(MINIMUM_CYCLE_LENGTH_DAYS, 'days'),
          'day',
        )
      )
    }
  }

  return (
    <div
      className="new-cycle-details"
      style={{ paddingTop: DEFAULT_SPACING * 2 }}
    >
      <Form colon={false}>
        <Card style={{ marginBottom: DEFAULT_SPACING * 2 }}>
          <div>
            <Title
              level={4}
              style={{
                color: '#1c1047',
                marginBottom: DEFAULT_SPACING,
                lineHeight: '32px',
              }}
            >
              Let’s give it a name
            </Title>
            <div style={{ marginBottom: DEFAULT_SPACING * 2, fontWeight: 300 }}>
              Giving the feedback event a name makes it easier to view feedback
              when you've used the same set of questions on multiple occasions.
            </div>
            <Form.Item
              validateStatus={nameValidationStatus}
              help={nameHelp}
              style={{ marginBottom: DEFAULT_SPACING * 3, width: 356 }}
            >
              <Input
                value={context.name}
                style={STYLE_LAST_INPUT}
                onChange={e => context.setContext({ name: e.target.value })}
              />
            </Form.Item>
          </div>
          <Row gutter={16}>
            <Col span={24}>
              <Title
                level={4}
                style={{ marginBottom: DEFAULT_SPACING, lineHeight: '32px' }}
              >
                Set the timing
              </Title>
              <div
                style={{
                  marginBottom: DEFAULT_SPACING * 2,
                  fontWeight: 300,
                  color: '#1c1047',
                }}
              >
                Please select the start date, end date and timezone for your
                feedback event.
              </div>
            </Col>
            <Col span={6}>
              <Form.Item
                validateStatus={startDateValidationStatus}
                help={startDateHelp}
                label={
                  <span style={{ fontWeight: 300 }}>
                    Start date
                    <Tooltip title="If your event starts today it will begin immediately. If it starts on a future date it will begin at 9am in the event timezone.">
                      <Icon
                        type="question-circle"
                        size={16}
                        style={{
                          color: '#77779f',
                          marginLeft: '6px',
                          position: 'relative',
                          top: '1px',
                        }}
                      />
                    </Tooltip>
                  </span>
                }
              >
                <DatePicker
                  disabledDate={disabledStartDate}
                  format={'DD MMM YYYY'}
                  style={STYLE_LAST_INPUT}
                  value={
                    context.startDate &&
                    moment.unix(context.startDate).tz(timezoneSelected)
                  }
                  placeholder="dd/mm/yyyy"
                  onChange={startDateChange}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                validateStatus={endDateValidationStatus}
                help={endDateHelp}
                label={
                  <span style={{ fontWeight: 300 }}>
                    End date
                    <Tooltip title="The event needs to run for 1-2 weeks so everyone has time to respond to feedback requests. The event will end at 5pm in the event timezone.">
                      <Icon
                        type="question-circle"
                        size={16}
                        style={{
                          color: '#77779f',
                          marginLeft: '6px',
                          position: 'relative',
                          top: '1px',
                        }}
                      />
                    </Tooltip>
                  </span>
                }
              >
                <DatePicker
                  disabled={context.startDate ? false : true}
                  disabledDate={disabledEndDate}
                  style={STYLE_LAST_INPUT}
                  format={'DD MMM YYYY'}
                  value={
                    context.endDate &&
                    moment.unix(context.endDate).tz(timezoneSelected)
                  }
                  placeholder="dd/mm/yyyy"
                  onChange={endDateChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <span style={{ fontWeight: 300 }}>
                    Event timezone
                    <Tooltip title="The event timezone determines the start and end times, and when reminder emails are sent.">
                      <Icon
                        type="question-circle"
                        size={16}
                        style={{
                          color: '#77779f',
                          marginLeft: '6px',
                          position: 'relative',
                          top: '1px',
                        }}
                      />
                    </Tooltip>
                  </span>
                }
                validateStatus={timezoneValidationStatus}
                help={timezoneHelp}
                style={{ marginBottom: 0 }}
              >
                <div>
                  <SelectTimezones
                    value={context.timezone}
                    onChange={setTimezone}
                    placeholder="Select timezone"
                    style={STYLE_LAST_INPUT}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <div
                style={{ color: '#77779f', fontSize: '14px', fontWeight: 300 }}
              >
                Between the start and end date, those you request feedback from
                will get a reminder on Tuesdays at 3pm and Fridays at 9am based
                on the timezone you select.
              </div>
            </Col>
          </Row>
        </Card>
        <Form.Item>
          <ButtonsBottomPersonal360
            step={3}
            handleContinueClick={handleForm}
            contextId={context.id}
            typePath={'/personal360'}
            fromWBYHT={context.fromWBYHT}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default withRouter(StepDetails)
