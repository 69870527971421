import './styles.less'

import { useMutation } from '@apollo/react-hooks'
import { Button, Modal, notification } from 'antd'
import gql from 'graphql-tag'
import React, { useContext, useState } from 'react'
import Emoji from 'react-emoji-render'

import { track } from '../_helpers/analytics'
import { UserContext } from '../UserContext'

export const PremiumContext = React.createContext()

const REQUEST_PREMIUM = gql`
  mutation requestPremium($feature: String!, $status: String!, $from: String) {
    requestPremium(feature: $feature, status: $status, from: $from)
  }
`

export default ({ children }) => {
  const defaultContext = {
    showPopUp: async (feature, from) => {
      setReferredFeature(feature)
      setFrom(from)
      setPremiumPopUpVisible(true)
      await requestPremium({
        variables: {
          feature,
          status: 'clicked',
          from,
        },
      })
    },
  }

  const user = useContext(UserContext)
  const [context] = useState(defaultContext)
  const [referredFeature, setReferredFeature] = useState(false)
  const [from, setFrom] = useState(false)
  const [premiumPopUpVisible, setPremiumPopUpVisible] = useState(false)
  const [confirmedPopUpVisible, setConfirmedPopUpVisible] = useState(false)
  const [requestPremium, { loading }] = useMutation(REQUEST_PREMIUM)

  const handlePremiumPopUpOk = async () => {
    if (!referredFeature) {
      notification.error({
        message: 'Oops!',
        description: 'It appears an error ocurred. Please try again later.',
      })
    }

    await requestPremium({
      variables: {
        feature: referredFeature,
        status: 'enrolled',
        from,
      },
    })

    track('user.premium.request', {
      user: user.email,
      org: user.org.name,
      feature: referredFeature,
    })

    setConfirmedPopUpVisible(true)
    setPremiumPopUpVisible(false)
  }

  const handlePremiumPopUpCancel = async () => {
    setPremiumPopUpVisible(false)
    await requestPremium({
      variables: {
        feature: referredFeature,
        status: 'cancelled',
        from,
      },
    })

    track('user.premium.dismiss', {
      user: user.email,
      org: user.org.name,
      feature: referredFeature,
    })
  }

  const handleConfirmedPopUpOk = () => {
    setConfirmedPopUpVisible(false)
  }

  return (
    <PremiumContext.Provider value={context}>
      {children}

      <Modal
        title={
          <div style={{ display: 'flex' }}>
            <div style={{ fontWeight: 500 }}>This feature is coming soon!</div>
            <div
              style={{ marginLeft: 'auto', fontWeight: 400, fontSize: '16px' }}
              className="color-tertiary"
            >
              Less than 50 spots left
            </div>
          </div>
        }
        closable={false}
        footer={
          <div style={{ textAlign: 'left', paddingTop: '8px' }}>
            <Button
              type="primary"
              onClick={handlePremiumPopUpOk}
              style={{ marginRight: '10px' }}
              disabled={loading}
              loading={loading}
            >
              I want this feature
            </Button>
            <Button
              type="ghost"
              onClick={handlePremiumPopUpCancel}
              disabled={loading}
            >
              No thanks, I'm fine
            </Button>
          </div>
        }
        visible={premiumPopUpVisible}
      >
        <Emoji text={':crown:'} className="premium-popup-crown-emoji" />
        <div style={{ fontWeight: 300 }}>
          Click below to be one of the first{' '}
          <span className="color-tertiary">100 teams</span> to receive free
          early access once it's released.
        </div>
      </Modal>
      <Modal
        title={<div style={{ fontWeight: 600 }}>Congrats!</div>}
        closable={false}
        footer={
          <div style={{ textAlign: 'left', paddingTop: '8px' }}>
            <Button type="primary" onClick={handleConfirmedPopUpOk}>
              Alright!
            </Button>
          </div>
        }
        visible={confirmedPopUpVisible}
      >
        Free early access is yours :-) We'll email you when this feature is
        ready.
      </Modal>
    </PremiumContext.Provider>
  )
}
