import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Input, Progress, Typography, notification } from 'antd'
import React, { useContext, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { track } from '../_helpers/analytics'
import { UserContext } from '../UserContext'
import { SET_JOB_TITLE, SET_ONBOARDING_STEP_STATUS } from './queries'

const { Title, Text } = Typography

const SetJobTitle = ({ history, onSubmit, onSkip, stepIndex, totalSteps }) => {
  const user = useContext(UserContext)
  const [jobTitle, setJobTitle] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSkipping, setIsSkipping] = useState(false)

  const [setJobTitleMutation] = useMutation(SET_JOB_TITLE, {
    variables: {
      jobTitle: jobTitle,
    },
  })

  const [setJobTitleDone] = useMutation(SET_ONBOARDING_STEP_STATUS, {
    variables: {
      step: 'JOB_TITLE',
      status: 'SKIPPED',
    },
  })

  const submit = async () => {
    if (jobTitle && jobTitle.length > 0) {
      setIsSubmitting(true)
      await setJobTitleMutation()
      track(
        user.isOwner()
          ? 'admin.onboarding.jobTitle'
          : 'user.onboarding.jobTitle',
        {
          user: user.email,
          org: user.org.name,
          jobTitle,
        },
      )
      setIsSubmitting(false)
      if (onSubmit) {
        onSubmit()
      } else {
        history.push('/onboarding/set-team')
      }
    } else {
      notification.warn({
        message: 'There is a problem with your job title.',
        description: 'You did not specify any job title. Please check again.',
      })
    }
  }

  const skip = async () => {
    setIsSkipping(true)
    await setJobTitleDone()
    track(
      user.isOwner()
        ? 'admin.onboarding.jobTitleSkipped'
        : 'user.onboarding.jobTitleSkipped',
      {
        user: user.email,
        org: user.org.name,
      },
    )
    setIsSkipping(false)
    skipStep()
  }

  const skipStep = () => {
    if (onSkip) {
      onSkip()
    } else {
      history.push('/onboarding/set-team')
    }
  }

  return (
    <div className="onboarding-set-job-title">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontSize: '24px', fontWeight: 500 }}
        >
          So tell us {user.firstName}, what's your role at {user.org.name}?
        </Title>

        <div>
          <Text style={{ fontWeight: 300 }}>I'm a</Text>
          <Input
            onChange={e => setJobTitle(e.target.value)}
            style={{
              marginTop: '8px',
              boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
            }}
            placeholder="Enter your role"
          />
        </div>

        <div style={{ marginTop: '32px' }}>
          <Button
            onClick={submit}
            disabled={
              isSubmitting || isSkipping || !jobTitle || jobTitle === ''
            }
            loading={isSubmitting}
            type="primary"
          >
            Continue
          </Button>
          <Button
            type="ghost"
            disabled={isSubmitting || isSkipping}
            loading={isSkipping}
            onClick={() => skip()}
            style={{ marginLeft: '10px' }}
          >
            Skip for now
          </Button>
        </div>
      </Card>
      <div style={{ marginTop: '48px' }}>
        <Text style={{ fontSize: '14px', fontWeight: 300 }}>
          Step {stepIndex} out of {totalSteps}
        </Text>
        <Progress
          style={{
            marginTop: '8px',
          }}
          strokeColor={'#77779f'}
          percent={(stepIndex * 100) / totalSteps}
          showInfo={false}
        />
      </div>
    </div>
  )
}

export default withRouter(SetJobTitle)
