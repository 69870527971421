import gql from 'graphql-tag'

export const CREATE_CYCLE_PERSONAL = gql`
  mutation createCyclePersonal(
    $type: CycleType!
    $orgId: String!
    $name: String!
    $startDate: Int!
    $endDate: Int!
    $formId: String!
    $requestedUsers: [String!]!
    $timezone: String!
  ) {
    createCyclePersonal(
      newCycleWithReviews: {
        type: $type
        orgId: $orgId
        name: $name
        startDate: $startDate
        endDate: $endDate
        formId: $formId
        requestedUsers: $requestedUsers
        timezone: $timezone
      }
    ) {
      id
    }
  }
`

export const UPDATE_CYCLE_PERSONAL = gql`
  mutation updateCyclePersonal(
    $cycleId: ID!
    $name: String
    $startDate: Int
    $endDate: Int
    $formId: String
    $requestedUsers: [String!]
    $timezone: String
  ) {
    updateCyclePersonal(
      updateCycleInput: {
        id: $cycleId
        name: $name
        startDate: $startDate
        endDate: $endDate
        formId: $formId
        requestedUsers: $requestedUsers
        timezone: $timezone
      }
    ) {
      id
    }
  }
`

export const GET_CYCLE_PERSONAL = gql`
  query getCycle($cycleId: String!) {
    cycle: getCycle(id: $cycleId) {
      id
      name
      startDate
      endDate
      settings
      form {
        id
      }
      reviews {
        id
        feedbacks {
          id
          user {
            id
          }
        }
      }
    }
  }
`
