import gql from 'graphql-tag'

const cycleObject = `
    cycle {
        id,
        name,
        type,
        startDate,
        endDate,
        form {
            id,
            sections
        }
    }
`

const feedbacksObject = `
    feedbacks {
        id,
        submittedAt,
        user {
          id,
          firstName,
          lastName,
          email,
          avatarUrl
          avatar
        }
        responses {
            id,
            data,
            question {
                id,
                description,
                titleVariants,
                template {
                    id,
                    type,
                    settings
                }
            }
        }
    }
`

export const GET_FEEDBACK_RESPONSES = gql`
    query getReview ($reviewId: String!) {
        finishedReview(id: $reviewId) {
            ${cycleObject},
            ${feedbacksObject}
        }
    }
`

export const GET_MANAGER_REVIEWS = gql`
    query getManagerReviewsForCycle($cycleId: ID!, $requestedUsers: [String!]!) {
        reviewsForManager(cycleReviewsForUsers: {id: $cycleId, requestedUsers: $requestedUsers}) {
            id,
            user {
                id,
                firstName,
                lastName,
                avatarUrl,
                avatar,
                email,
                jobTitle
            },
            ${cycleObject},
            ${feedbacksObject}
        }
    }
`
