import gql from 'graphql-tag'

export const GET_RUNNING_CYCLES = gql`
  query getCyclesForUser($type: String!) {
    cycles: getCyclesForUser(running: true, type: $type) {
      id
      type
      name
      startDate
      endDate
      reviews {
        id
        user {
          id
          avatarUrl
          avatar
          firstName
          lastName
          email
          jobTitle
        }
        feedbacks {
          id
          submittedAt
          user {
            id
            avatarUrl
            avatar
            firstName
            lastName
            email
            jobTitle
          }
        }
      }
    }
  }
`
