import './style.less'

import { Button, Card, Col, List, Progress, Row, Typography } from 'antd'
import shuffle from 'lodash/shuffle'
import React, { createRef, useEffect, useState } from 'react'

const { Title } = Typography
const DEFAULT_SPACING = 8
const MAX_HEIGHT = 376
export default ({ review }) => {
  const [sections, setSections] = useState([])
  const [collapsedSections, setCollapsedSections] = useState([])
  const [peopleAsked, setPeopleAsked] = useState(0)
  const [expandableSections, setExpandableSections] = useState([])
  const [sectionsRefs, setSectionsRefs] = useState([])

  useEffect(() => {
    if (review) {
      setPeopleAsked(review.feedbacks.length)
      const feedbacksWithResponses = review.feedbacks.filter(
        f => f.responses.length > 0,
      )
      if (feedbacksWithResponses.length > 0) {
        const newSections = review.cycle.form.sections.map(section => {
          const questions = section.questions.map(questionId => {
            // Create the question array
            const responseParam = feedbacksWithResponses[0].responses.find(
              response => response.question.id === questionId.id,
            )
            const titleQuestion = responseParam.question.titleVariants.find(
              title => title.target === 'other',
            )

            const answers =
              responseParam.question.template.type === 'MULTI_CHOICE'
                ? responseParam.question.template.settings.options.map(
                    option => {
                      return {
                        name: option,
                        count: 0,
                        percentage: 0,
                      }
                    },
                  )
                : []

            let question = {
              id: questionId.id,
              required: questionId.required,
              title: titleQuestion
                ? titleQuestion.title
                : responseParam.question.titleVariants[0].title,
              type: responseParam.question.template.type,
              answers: answers,
              countAnswers: 0,
            }
            // Add to the question the answers
            feedbacksWithResponses.forEach(feedback => {
              const response = feedback.responses.find(response => {
                return response.question.id === questionId.id
              })

              if (question.type === 'MULTI_CHOICE') {
                question.answers[response.data.value].count++
              } else {
                if (response.data.value !== '') {
                  question.answers.push({
                    value: response.data.value,
                    id: response.id,
                  })
                }
              }
            })

            if (question.type === 'MULTI_CHOICE') {
              question.answers.forEach(answer => {
                answer.percentage = parseInt(
                  (answer.count / feedbacksWithResponses.length) * 100,
                )
                question.countAnswers += answer.count
              })
            } else {
              question = {
                ...question,
                answers: shuffle(question.answers),
                countAnswers: question.answers.length,
              }
            }
            return question
          })
          return {
            name: section.title,
            questions: questions,
          }
        })
        setSectionsRefs(newSections.map(() => createRef()))
        setSections(newSections)
        setCollapsedSections([...newSections].fill(false))
      }
    }
  }, [review])

  useEffect(() => {
    setExpandableSections(
      sectionsRefs.map(section => section.current.clientHeight >= MAX_HEIGHT),
    )
  }, [sectionsRefs])

  const handleViewMore = indexSection => {
    setCollapsedSections(
      collapsedSections.map((collapsedSection, index) =>
        index === indexSection ? !collapsedSection : collapsedSection,
      ),
    )
  }

  const renderMultiChoiceAnswers = answers => {
    return (
      <Row>
        <Col span={16}>
          {answers.map(answer => (
            <div key={answer.name}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: DEFAULT_SPACING * 0.5,
                  color: '#1c1047',
                  fontWeight: 300,
                }}
              >
                {answer.name}
                <div style={{ fontWeight: 'normal' }}>
                  {answer.percentage}%
                  <span
                    style={{
                      fontWeight: 300,
                      fontSize: 12,
                    }}
                  >
                    {' '}
                    ({answer.count} {answer.count === 1 ? 'answer' : 'answers'})
                  </span>
                </div>
              </div>
              <Progress
                style={{
                  marginBottom: DEFAULT_SPACING * 1.5,
                }}
                percent={answer.percentage}
                showInfo={false}
                strokeColor="#77779f"
              />
            </div>
          ))}
        </Col>
      </Row>
    )
  }

  const renderTextAnswers = answers => {
    return (
      <div>
        {answers.length === 0
          ? 'No answer available.'
          : answers.map(answer => (
              <div
                key={answer.id}
                style={{
                  fontSize: 16,
                  fontWeight: 300,
                  color: '#1c1047',
                  marginBottom: DEFAULT_SPACING * 2,
                }}
              >
                <span
                  style={{
                    color: '#f95c4b',
                    fontWeight: 'bold',
                  }}
                >
                  •
                </span>{' '}
                {answer.value}
              </div>
            ))}
      </div>
    )
  }
  return (
    <div className="feedback-answers">
      {
        <List
          size="large"
          itemLayout="vertical"
          grid={{
            gutter: DEFAULT_SPACING * 2,
          }}
          style={{ marginTop: DEFAULT_SPACING * 4 }}
          dataSource={sections}
          renderItem={(section, index) => (
            <List.Item key={index}>
              <Card
                title={
                  <Title level={4} style={{ marginBottom: 0 }}>
                    {section.name}
                  </Title>
                }
              >
                <div>
                  <div
                    ref={sectionsRefs[index]}
                    className={
                      expandableSections[index]
                        ? !collapsedSections[index]
                          ? 'fadeout-container'
                          : ''
                        : ''
                    }
                  >
                    {section.questions.map((question, index) => (
                      <div
                        key={question.id}
                        style={{
                          borderBottom:
                            section.questions.length > 1 ||
                            section.questions.length - 1 !== index
                              ? '1px solid #e9e8ef'
                              : '',
                          paddingBottom: DEFAULT_SPACING * 3,
                          marginBottom: DEFAULT_SPACING * 4,
                        }}
                      >
                        <div
                          style={{
                            marginBottom: DEFAULT_SPACING,
                            fontSize: 18,
                          }}
                        >
                          {question.title}
                          {!question.required && ' (Optional)'}
                        </div>
                        <div
                          className="small-letter"
                          style={{
                            marginBottom: DEFAULT_SPACING * 3,
                          }}
                        >
                          {peopleAsked === question.countAnswers
                            ? 'Everybody answered'
                            : `${question.countAnswers} out of ${peopleAsked} answered`}
                        </div>
                        <div>
                          {question.type === 'MULTI_CHOICE'
                            ? renderMultiChoiceAnswers(question.answers)
                            : renderTextAnswers(question.answers)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div>
                    {expandableSections[index] && (
                      <Button
                        style={{ marginBottom: DEFAULT_SPACING * 2 }}
                        type="light-purple"
                        icon={!collapsedSections[index] ? 'down' : 'up'}
                        onClick={() => handleViewMore(index)}
                      >
                        {!collapsedSections[index]
                          ? 'View all responses'
                          : 'Show less'}
                      </Button>
                    )}
                  </div>
                </div>
              </Card>
            </List.Item>
          )}
        ></List>
      }
    </div>
  )
}
