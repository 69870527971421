import { Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'

import withAutoSave from '../AutoSaveContext/withAutoSave'

const { TextArea } = Input

const DEFAULT_SPACING = 8

const FormQuestionText = ({
  question,
  target,
  fieldDecorator,
  onChange,
  autoSaveIndicator,
}) => {
  const [title, setTitle] = useState('')
  const [characterLimit, setCharacterLimit] = useState(
    question.template.settings.characterLimit,
  )
  const [inputtedCharacters, setInputtedCharacters] = useState(0)

  useEffect(() => {
    // only set question title if its target is of type 'other' or 'self'
    //   and if that title is set in titleVariants
    if (['other', 'self'].indexOf(target) > -1) {
      const titleObj = question.titleVariants.find(q => q.target === target)
      const titleToSet = titleObj
        ? titleObj.title
        : `No question title of type '${target}' added.`
      setTitle(titleToSet)
    }
  }, [target, question.titleVariants])

  useEffect(() => {
    setCharacterLimit(question.template.settings.characterLimit)
  }, [question.template.settings.characterLimit])

  const handleChange = e => {
    const value = e.target.value
    setInputtedCharacters(e.target.value.length)
    onChange({ value })
  }

  if (!question) {
    return <span></span>
  }

  return (
    <div className="form-question form-question--text">
      <Form.Item
        label={
          <span style={{ fontSize: 18, fontWeight: 300 }}>
            {title}
            {!question.required && ' (Optional)'}
          </span>
        }
      >
        {fieldDecorator(question.id, {
          rules: [
            {
              required: question.required,
              message: 'Hold up! You need to say something here...',
            },
            { max: characterLimit, message: 'Your text is too long!' },
          ],
        })(
          <TextArea
            style={{
              marginTop: DEFAULT_SPACING,
              marginBottom: DEFAULT_SPACING * 2,
              boxShadow: `0 ${DEFAULT_SPACING * 2}px ${DEFAULT_SPACING *
                3}px 0 rgba(119, 119, 159, 0.12)`,
              padding: `${DEFAULT_SPACING * 2}px ${DEFAULT_SPACING * 2.5}px`,
            }}
            rows={3}
            placeholder={question.placeholder}
            onChange={handleChange}
          />,
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{autoSaveIndicator}</div>
          <div style={{ fontSize: 14, color: '#77779f' }}>
            {inputtedCharacters <= characterLimit ? (
              <span>
                {characterLimit - inputtedCharacters} character
                {characterLimit - inputtedCharacters !== 1 && 's'} remaining
              </span>
            ) : (
              <span>
                {Math.abs(inputtedCharacters - characterLimit)} character
                {Math.abs(inputtedCharacters - characterLimit) !== 1 && 's'} too
                long
              </span>
            )}
          </div>
        </div>
      </Form.Item>
    </div>
  )
}

export default withAutoSave(FormQuestionText)
