import gql from 'graphql-tag'

export const CREATE_CYCLE = gql`
  mutation createCycleWithReviews(
    $type: CycleType!
    $orgId: String!
    $name: String!
    $startDate: Int!
    $endDate: Int!
    $formId: String!
    $requesters: [String!]!
    $minRequests: Int!
    $maxRequests: Int!
    $timezone: String!
  ) {
    createCycleWithReviews(
      newCycleWithReviews: {
        type: $type
        orgId: $orgId
        name: $name
        startDate: $startDate
        endDate: $endDate
        formId: $formId
        requesters: $requesters
        minRequests: $minRequests
        maxRequests: $maxRequests
        timezone: $timezone
      }
    ) {
      id
    }
  }
`

export const UPDATE_CYCLE = gql`
  mutation updateCycle(
    $cycleId: ID!
    $name: String
    $startDate: Int
    $endDate: Int
    $formId: String
    $requesters: [String!]
    $minRequests: Int
    $maxRequests: Int
    $timezone: String
  ) {
    updateCycle(
      updateCycleInput: {
        id: $cycleId
        name: $name
        startDate: $startDate
        endDate: $endDate
        formId: $formId
        requesters: $requesters
        minRequests: $minRequests
        maxRequests: $maxRequests
        timezone: $timezone
      }
    ) {
      id
    }
  }
`

export const GET_CYCLE = gql`
  query getCycle($cycleId: String!) {
    cycle: getCycle(id: $cycleId) {
      id
      name
      startDate
      endDate
      settings
      form {
        id
        title
        description
        sections
        formQuestions {
          id
          question {
            id
            titleVariants
            description
            template {
              id
              type
              settings
            }
          }
        }
      }
      reviews {
        id
        user {
          id
          firstName
          lastName
          jobTitle
          email
          avatarUrl
        }
      }
    }
  }
`
