import gql from 'graphql-tag'

export const GET_COMPANY_TEAMS = gql`
  query {
    teams {
      id
      name
      members {
        id
        user {
          id
          firstName
          lastName
          username
          email
          avatarUrl
          jobTitle
        }
      }
    }
  }
`

export const CREATE_TEAM = gql`
  mutation createTeam($teamName: String!) {
    createTeam(teamName: $teamName) {
      id
      name
    }
  }
`

export const EDIT_TEAM_NAME = gql`
  mutation editTeamName($teamId: String!, $teamName: String!) {
    editTeamName(teamId: $teamId, teamName: $teamName) {
      id
      name
    }
  }
`

export const DELETE_TEAM = gql`
  mutation deleteTeam($teamId: String!) {
    deleteTeam(teamId: $teamId)
  }
`

export const REMOVE_USER_FROM_TEAM = gql`
  mutation removeUserFromTeam($userId: String!, $teamId: String!) {
    removeUserFromTeam(userId: $userId, teamId: $teamId)
  }
`

export const ADD_TEAM_MEMBERS = gql`
  mutation addTeamMembers($teamId: String!, $userIds: [String!]!) {
    addTeamMembers(addTeamMembersData: { teamId: $teamId, userIds: $userIds })
  }
`
