import { useApolloClient, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import deepClone from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { Intercom, identify } from '../_helpers/analytics'
import useInterval from '../hooks/useInterval'
import {
  GET_USER,
  KEEP_SESSION_ALIVE,
  LOGIN,
  RESET_PASSWORD,
  SEND_PASSWORD_RESET_LINK,
} from './queries'

const jwtDecode = require('jwt-decode')

const RENEW_SESSION_TIMEOUT = 20 // seconds
const REDEEM_MODAL_TIMEOUT = 5 * 60 // 5 minutes (in seconds)
const USER_ACTIVE_DEBOUNCE_TIMEOUT = 5 // seconds

export const UserContext = React.createContext()

const UserContextProvider = ({ children }) => {
  const client = useApolloClient()

  const setContext = newUserDetails => {
    updateUserContext(prevState => merge(deepClone(prevState), newUserDetails))
  }

  const setToken = token => {
    localStorage['HAIGToken'] = token
    const decodedToken = jwtDecode(token)
    setSessionExpiryDate(decodedToken.exp)
  }

  const defaultUserContext = {
    login: ({ username, password }) => {
      if (!username || !password) {
        throw new Error('Please enter a username and password')
      }

      return client
        .clearStore()
        .then(() => {
          return loginRequest({
            variables: {
              username,
              password,
            },
          })
        })
        .then(response => {
          if (response.data && !!response.data.login) {
            setToken(response.data.login)

            return getUser()
          } else {
            throw new Error('There was an error during login.')
          }
        })
        .catch(e => {
          throw new Error('Invalid credentials')
        })
    },

    logout: () => {
      client.clearStore().then(() => {
        if (localStorage['HAIGToken']) {
          localStorage.removeItem('HAIGToken')
        }

        updateUserContext({
          ...defaultUserContext,
          loading: false,
        })

        setSessionExpiryDate(false)

        Intercom.shutdown()
        Intercom.boot()
      })
    },

    sendPasswordResetLink: async email => {
      await sendPasswordResetLink({
        variables: {
          email,
        },
      })
    },

    resetPassword: ({ id, token, password }) => {
      return resetPassword({
        variables: {
          id,
          token,
          password,
        },
      }).then(response => {
        if (response.data && !!response.data.resetPassword) {
          return true
        } else {
          throw new Error('There was an error while resetting your password.')
        }
      })
    },

    isManager: function () {
      return this.orgRelationsFrom && this.orgRelationsFrom.length > 0
    },

    isAdmin: function () {
      const isUserAdmin =
        this.userRoles &&
        this.userRoles.find(userRole => userRole.role.type === 'COMPANY_ADMIN')

      return isUserAdmin !== undefined
    },

    isOwner: function () {
      const isUserOwner =
        this.userRoles &&
        this.userRoles.find(userRole => userRole.role.type === 'OWNER')

      return isUserOwner !== undefined
    },

    isSuperUser: function () {
      const isSuperUser =
        this.userRoles &&
        this.userRoles.find(userRole => userRole.role.type === 'ADMIN')

      return isSuperUser !== undefined
    },

    isOnboarding: false,
    isAuthenticated: false,
    loading: true,
    lastActive: false,

    refetchUser: () => {
      return client
        .query({
          query: GET_USER,
        })
        .then(response => {
          updateUserData(response.data)
        })
    },
    renewSession: async () => {
      if (localStorage['HAIGToken']) {
        const response = await renewSession()

        if (response && response.data && response.data.token) {
          setToken(response.data.token)
        }
      }
    },

    isShownWBYHT: true,

    resetWBYHT: () => {
      setContext({
        isShownWBYHT: false,
      })
    },
  }

  const [userContext, updateUserContext] = useState(defaultUserContext)
  const [timeoutModalVisible, setTimeoutModalVisible] = useState(false)
  const [sessionExpiryDate, setSessionExpiryDate] = useState(false)

  const [getUser, { data: userData, error: errorData }] = useLazyQuery(
    GET_USER,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [loginRequest] = useMutation(LOGIN)
  const [renewSession] = useMutation(KEEP_SESSION_ALIVE)
  const [sendPasswordResetLink] = useMutation(SEND_PASSWORD_RESET_LINK)
  const [resetPassword] = useMutation(RESET_PASSWORD)

  const updateUserData = userData => {
    if (!userData || !userData.user) {
      return
    }

    const fetchedUser = userData.user

    const isOnboarding = fetchedUser.status === 'ONBOARDING' ? true : false

    if (localStorage['HAIGToken'] && fetchedUser.renewalToken) {
      setToken(fetchedUser.renewalToken)
    }

    const clonedUserDetails = deepClone(fetchedUser)
    identify(fetchedUser)

    const getHighestUserRole = () => {
      const privileges = fetchedUser.userRoles.map(
        userRole => userRole.role.name,
      )
      if (privileges.findIndex(x => x.toLowerCase() === 'owner') !== -1) {
        return 'Admin'
      }
      if (privileges.findIndex(x => x.toLowerCase() === 'admin') !== -1) {
        return 'Admin'
      }
      if (
        privileges.findIndex(x => x.toLowerCase() === 'company admin') !== -1
      ) {
        return 'Admin'
      }
      if (privileges.findIndex(x => x.toLowerCase() === 'manager') !== -1) {
        return 'Manager'
      }
      if (privileges.findIndex(x => x.toLowerCase() === 'user') !== -1) {
        return 'User'
      }
      return 'User'
    }

    const IntercomOptions = {
      name: fetchedUser.firstName,
      created_at: fetchedUser.createdAt,
      email: fetchedUser.email,
      user_id: fetchedUser.id,
      job_title: fetchedUser.jobTitle,
      account_name: fetchedUser.org && fetchedUser.org.name,
      user_role: getHighestUserRole(),
    }

    Intercom.boot(IntercomOptions)

    setContext(
      merge(clonedUserDetails, {
        isAuthenticated: true,
        isOnboarding,
        loading: false,
      }),
    )
  }

  useEffect(() => {
    // on page load, fetch the user if we have a token for them
    // otherwise, initiate the log out procedure
    if (
      localStorage['HAIGToken'] &&
      window.location.pathname !== '/access' &&
      window.location.search.indexOf('?t=') === -1
    ) {
      getUser()
    } else {
      userContext.logout()
    }
  }, [])

  useEffect(() => {
    // when the user is loaded properly, set the context appropriately
    updateUserData(userData)
  }, [userData])

  useEffect(() => {
    // if the user did not load properly, initiate the log out procedure
    if (errorData) {
      userContext.logout()
    }
  }, [errorData])

  useInterval(() => {
    const now = Math.floor(Date.now() / 1000)

    // log out the user if their token has expired
    if (localStorage['HAIGToken']) {
      const decodedToken = jwtDecode(localStorage['HAIGToken'])

      if (decodedToken && decodedToken.exp && decodedToken.exp < now) {
        setTimeoutModalVisible(false)
        userContext.logout()
      }
    }

    // show the session expiration modal <REDEEM_MODAL_TIMEOUT> seconds
    // before automatically logging the user off

    if (
      sessionExpiryDate &&
      sessionExpiryDate - now > -1 &&
      sessionExpiryDate - now <= REDEEM_MODAL_TIMEOUT
    ) {
      setTimeoutModalVisible(true)
    }
  }, 1000)

  useInterval(() => {
    // renew the session if the user was active
    const now = Math.floor(Date.now() / 1000)

    if (now - userContext.lastActive <= RENEW_SESSION_TIMEOUT) {
      userContext.renewSession()
    }
  }, RENEW_SESSION_TIMEOUT * 1000)

  const [handleActivity] = useDebouncedCallback(() => {
    // the app rerenders when we update the context, so we
    // debounce this function in order to not update the context too often
    if (userContext.isAuthenticated) {
      const now = Math.floor(Date.now() / 1000)

      updateUserContext({
        ...userContext,
        lastActive: now,
      })
    }
  }, USER_ACTIVE_DEBOUNCE_TIMEOUT * 1000)

  const handleModalRenewal = () => {
    userContext.renewSession().then(() => {
      setTimeoutModalVisible(false)
    })
  }

  return (
    <div
      onClick={() => handleActivity()}
      onMouseMove={() => handleActivity()}
      onKeyPress={() => handleActivity()}
    >
      <UserContext.Provider value={userContext}>
        {children}
      </UserContext.Provider>
      <Modal
        title="Session about to expire!"
        visible={userContext.isAuthenticated && timeoutModalVisible}
        okText="Yes, please!"
        onOk={handleModalRenewal}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        Hey! Your session is about to expire! Do you want to keep yourself
        logged in?
      </Modal>
    </div>
  )
}

export default UserContextProvider
