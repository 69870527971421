import { Button, Card, Input, Progress, Typography, notification } from 'antd'
import React, { useContext, useState } from 'react'

import { SET_ONBOARDING_STEP_STATUS } from '../Onboarding/queries'
import { UPDATE_ACCOUNT_DETAILS } from '../Account/queries'
import { UserContext } from '../UserContext'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'

const { Title, Text } = Typography

export default ({ onSubmit }) => {
  const user = useContext(UserContext)
  const [firstName, setFirstName] = useState(false)
  const [lastName, setLastName] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [updateUserMutation] = useMutation(UPDATE_ACCOUNT_DETAILS, {
    variables: {
      id: user.id,
      firstName,
      lastName,
    },
  })

  const [setFullNameStepDone] = useMutation(SET_ONBOARDING_STEP_STATUS, {
    variables: {
      step: 'FULL_NAME',
      status: 'COMPLETE',
    },
  })

  const submit = async () => {
    if (firstName && firstName.length > 0 && lastName && lastName.length > 0) {
      setIsSubmitting(true)
      const { data } = await updateUserMutation()

      if (data.updateUser.id) {
        await setFullNameStepDone()

        track('user.onboarding.fullName', {
          user: user.email,
          org: user.org.name,
          firstName,
          lastName,
        })

        if (onSubmit) {
          onSubmit()
        }
      } else {
        setIsSubmitting(false)
      }
    } else {
      notification.warn({
        message: 'There is a problem with your name.',
        description: 'You did not specify any name. Please check again.',
      })
    }
  }

  return (
    <div className="onboarding-set-full-name">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontWeight: 500, fontSize: '24px' }}
        >
          Hey there! First things first:
          <br /> what should we call you?
        </Title>

        <div>
          <Text style={{ fontWeight: 300 }}>First name</Text>
          <Input
            onChange={e => setFirstName(e.target.value)}
            placeholder="Harriet"
            style={{ marginTop: '8px' }}
          />
        </div>

        <div style={{ marginTop: '16px' }}>
          <Text style={{ fontWeight: 300 }}>Last name</Text>
          <Input
            onChange={e => setLastName(e.target.value)}
            placeholder="Howmie"
            style={{ marginTop: '8px' }}
          />
        </div>

        <div style={{ marginTop: '32px' }}>
          <Button
            disabled={isSubmitting || !firstName || !lastName}
            loading={isSubmitting}
            onClick={submit}
            type="primary"
          >
            Continue
          </Button>
        </div>
      </Card>

      <div style={{ marginTop: '48px' }}>
        <Text style={{ fontSize: '14px', fontWeight: 300 }}>
          Step 1 out of 3
        </Text>
        <Progress
          style={{
            marginTop: '8px',
          }}
          strokeColor={'#77779f'}
          percent={33.3333}
          showInfo={false}
        />
      </div>
    </div>
  )
}
