import React, { useEffect, useState } from 'react'

import { Form } from 'antd'
import MultiChoiceSlider from '../_components/MultiChoiceSlider'
import withAutoSave from '../AutoSaveContext/withAutoSave'

const FormQuestionMultiChoice = ({
  question,
  target,
  fieldDecorator,
  autoSaveIndicator,
  onChange,
}) => {
  const [title, setTitle] = useState('')

  const handleChange = value => {
    if (onChange) {
      onChange({ value })
    }
  }

  useEffect(() => {
    // only set question title if its target is of type 'other' or 'self'
    //   and if that title is set in titleVariants
    if (['other', 'self'].indexOf(target) > -1) {
      const titleObj = question.titleVariants.find(q => q.target === target)
      const titleToSet = titleObj
        ? titleObj.title
        : `No question title of type '${target}' added.`
      setTitle(titleToSet)
    }
  }, [target, question.titleVariants])

  if (!question) {
    return <span></span>
  }

  return (
    <div
      className="form-question form-question--multi-choice"
      style={{ marginBottom: -24 }}
    >
      <Form.Item
        label={<span style={{ fontSize: 18, fontWeight: 300 }}>{title}</span>}
      >
        <div>
          {fieldDecorator(question.id, { initialValue: 0 })(
            <MultiChoiceSlider
              options={question.template.settings.options}
              onChange={handleChange}
            />,
          )}
        </div>
        <div style={{ marginRight: 'auto', paddingTop: '6px' }}>
          {autoSaveIndicator}
        </div>
      </Form.Item>
    </div>
  )
}

export default withAutoSave(FormQuestionMultiChoice)
