import { Button, Card, Checkbox, Form, Typography, notification } from 'antd'
import React, { useContext, useState } from 'react'
import { SET_COMMS, SET_TERMS } from './queries'

import { UserContext } from '../UserContext'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'

const { Title, Paragraph } = Typography

const WelcomeTermsForm = props => {
  const user = useContext(UserContext)
  const { getFieldDecorator, getFieldValue, validateFields } = props.form
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [setTerms] = useMutation(SET_TERMS)
  const [setComms] = useMutation(SET_COMMS)

  const validateTerms = (rule, value) => {
    // Need this workaround because AntD doesn't validate checkboxes in forms properly
    return value ? true : false
  }

  const submit = async e => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        try {
          setIsSubmitting(true)
          await setTerms({
            variables: {
              acceptance: values.terms ? true : false,
            },
          })

          await setComms({
            variables: {
              acceptance: values.comms ? true : false,
            },
          })

          track(
            user.isOwner()
              ? 'admin.onboarding.welcome'
              : 'user.onboarding.welcome',
            {
              user: user.email,
              org: user.org.name,
            },
          )
          props.afterSubmit()
        } catch (e) {
          setIsSubmitting(false)
          notification.error({
            message: 'There was a problem accepting the terms!',
            description:
              'An error happened when accepting the terms. Please try again later.',
          })
        }
      }
    })
  }

  return (
    <div className="onboarding-welcome">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Form onSubmit={submit} className="onboarding-welcome-form">
          <Title
            level={1}
            style={{
              fontWeight: 500,
              fontSize: '32px',
              lineHeight: '40px',
              marginBottom: '24px',
              textAlign: 'center',
            }}
          >
            {user.isOwner() ? (
              <>Congrats! You’re now a verified Howmie. </>
            ) : (
              <>Nice move! Welcome to Howamigoing.</>
            )}
          </Title>
          <div className="onboarding-welcome-content">
            <Paragraph
              style={{
                fontWeight: 300,
                lineHeight: '1.75',
                marginBottom: '32px',
                textAlign: 'center',
              }}
            >
              Let’s set up your account. It will only take a minute. <br />
              No, we mean it. Literally.
            </Paragraph>

            <div>
              <Form.Item style={{ marginBottom: '16px', fontWeight: 300 }}>
                {getFieldDecorator('terms', {
                  initialValue: false,
                  valuePropName: 'checked',
                  rules: [
                    {
                      validator: validateTerms,
                      message: 'Please accept the terms of use to continue',
                    },
                  ],
                })(
                  <Checkbox>
                    I accept the{' '}
                    <a
                      href="https://howamigoing.com/terms-of-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography.Text underline>Terms</Typography.Text>
                    </a>
                  </Checkbox>,
                )}
              </Form.Item>
            </div>

            <div>
              <Form.Item style={{ marginBottom: '24px', fontWeight: 300 }}>
                {getFieldDecorator('comms', {
                  initialValue: false,
                  valuePropName: 'checked',
                })(<Checkbox>Send me helpful content via email</Checkbox>)}
              </Form.Item>
            </div>
          </div>
          <Button
            htmlType="submit"
            disabled={isSubmitting || !getFieldValue('terms')}
            loading={isSubmitting}
            type="primary"
          >
            Get started
          </Button>
        </Form>
      </Card>
    </div>
  )
}

const WelcomeForm = Form.create({ name: 'WelcomeTermsForm' })(WelcomeTermsForm)

const WelcomeTerms = ({ onSubmit }) => {
  return <WelcomeForm afterSubmit={onSubmit} />
}

export default withRouter(WelcomeTerms)
