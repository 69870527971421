import './style.less'

import { Button, Col, Row, Typography } from 'antd'
import React from 'react'

import ComingSoonShape from '../_assets/img/comingsoon/coming-soon-shape.svg'
import PlayButton from '../_assets/img/comingsoon/play-circle.svg'
import PageTitle from '../_components/PageTitle'

const { Title, Paragraph } = Typography

export default ({
  title,
  subtitle,
  paragraphs,
  button,
  buttonLink,
  screenshot,
  video,
}) => {
  return (
    <div className="coming-soon-page">
      <PageTitle style={{ marginBottom: '32px' }}>{title}</PageTitle>
      <Row>
        <Col span={11} style={{ paddingRight: '32px' }}>
          <Title level={4} style={{ fontWeight: 300, marginBottom: '16px' }}>
            {subtitle}
          </Title>
          {paragraphs.map((p, index) => (
            <Paragraph
              className="coming-soon-paragraph"
              style={{ marginBottom: '24px' }}
              key={index}
            >
              {p}
            </Paragraph>
          ))}
          <a href={buttonLink} target="_blank" rel="noopener noreferrer">
            <Button type="primary">{button}</Button>
          </a>
        </Col>
        <Col span={13} style={{ paddingTop: '72px' }}>
          <div className="video-container">
            <a
              href={video}
              target="_blank"
              className="video-background"
              rel="noopener noreferrer"
            >
              <img src={screenshot} className="video-screenshot" alt={title} />
              <img
                src={PlayButton}
                className="video-play-button"
                alt="Play button"
              />
            </a>
            <img src={ComingSoonShape} className="video-shape" alt="" />
          </div>
        </Col>
      </Row>
    </div>
  )
}
