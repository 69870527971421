import { Typography } from 'antd'
import React from 'react'

import { Avatar, AvatarName } from './Avatar'

const { Text } = Typography

export default ({ user, truncateTextAt = '100%' }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        user={user}
        style={{
          opacity:
            ['INACTIVE', 'DELETED', 'INVITED'].indexOf(user.status) > -1
              ? 0.5
              : 1,
          flexShrink: 0,
          maxWidth: '100%',
        }}
      />
      <div
        style={{
          display: 'inline-block',
          marginLeft: '16px',
          maxWidth: truncateTextAt,
          opacity:
            ['INACTIVE', 'DELETED', 'INVITED'].indexOf(user.status) > -1
              ? 0.5
              : 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <AvatarName
          user={user}
          style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'museo' }}
        />
        <br />
        <Text
          title={user.jobTitle}
          style={{ fontSize: '14px', fontWeight: 300, color: '#281765' }}
        >
          {user.jobTitle}
        </Text>
      </div>
    </div>
  )
}
