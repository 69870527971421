import { Button, Tabs } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PageTitle from '../_components/PageTitle'
import { UserContext } from '../UserContext'
import EditCompany from './EditCompany'
import PeopleList from './PeopleList'
import TeamsList from './TeamsList'

const { TabPane } = Tabs

export default () => {
  const history = useHistory()
  const user = useContext(UserContext)
  const [selectedTab, setSelectedTab] = useState('1')
  const [tabsExtraContent, setTabsExtraContent] = useState(false)
  const [modalVisible, setModalVisible] = useState({
    createTeam: false,
    invitePeople: false,
  })

  useEffect(() => {
    if (user.isAdmin() || user.isOwner() || user.isSuperUser()) {
      return
    }
    history.push('/home')
  }, [user])

  useEffect(() => {
    switch (selectedTab) {
      case '2':
        setTabsExtraContent(
          <Button
            type="dark-purple"
            icon="plus-circle"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() =>
              setModalVisible({ teamList: true, peopleList: false })
            }
          >
            Create team
          </Button>,
        )
        break
      case '1':
        setTabsExtraContent(
          <Button
            type="dark-purple"
            icon="plus-circle"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={() =>
              setModalVisible({ teamList: false, peopleList: true })
            }
          >
            Invite people
          </Button>,
        )
        break
      default:
        setTabsExtraContent(null)
    }
  }, [selectedTab])

  const closeModals = () => {
    setModalVisible({ teamList: false, peopleList: false })
  }

  const getTitle = () => {
    switch (selectedTab) {
      case '1':
        return 'Manage people'
      case '2':
        return 'Manage teams'
      case '3':
        return 'Manage company'
      default:
        return 'Admin'
    }
  }

  return (
    <div className="company">
      <PageTitle style={{ marginBottom: '32px' }}>{getTitle()}</PageTitle>

      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={tabsExtraContent}
        onChange={activeKey => setSelectedTab(activeKey)}
        tabBarStyle={{ marginBottom: '32px' }}
      >
        <TabPane tab={<span>People</span>} key="1">
          <PeopleList
            modalVisible={modalVisible.peopleList}
            onModalOk={closeModals}
            onModalCancel={closeModals}
          />
        </TabPane>

        <TabPane tab={<span>Teams</span>} key="2">
          <TeamsList
            modalVisible={modalVisible.teamList}
            onModalOk={closeModals}
            onModalCancel={closeModals}
          />
        </TabPane>

        <TabPane tab={<span>Company</span>} key="3">
          <EditCompany />
        </TabPane>
      </Tabs>
    </div>
  )
}
