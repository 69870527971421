import { Button, Col, Form, Input, Row, notification } from 'antd'
import React, { useEffect, useState } from 'react'

import gql from 'graphql-tag'
import moment from 'moment'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const SIGN_UP = gql`
  mutation signUpMutation(
    $firstName: String!
    $email: String!
    $org: String!
    $referral: String
    $timezone: String
  ) {
    signUpOrg(
      signUpData: {
        firstName: $firstName
        email: $email
        orgName: $org
        referral: $referral
        timezone: $timezone
      }
    ) {
      id
      name
    }
  }
`

const SignUp = ({ form, finishSignUp }) => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    validateFields,
    isFieldTouched,
  } = form
  const [firstName, setFirstName] = useState(null)
  const [email, setEmail] = useState(null)
  const [org, setOrg] = useState(null)
  const [referral, setReferral] = useState(null)
  const [timezone] = useState(() => moment.tz.guess())
  const [isSubmitting, setIsSubmitting] = useState(false)

  const firstNameError =
    isFieldTouched('firstname') && getFieldError('firstname')
  const emailError = isFieldTouched('email') && getFieldError('email')
  const orgError = isFieldTouched('org') && getFieldError('org')
  const referralError = isFieldTouched('referral') && getFieldError('referral')

  const [signUp] = useMutation(SIGN_UP, {
    variables: {
      firstName,
      email,
      org,
      referral,
      timezone,
    },
    errorPolicy: 'all',
  })

  useEffect(() => {
    validateFields()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    validateFields(async (error, values) => {
      if (!error) {
        setIsSubmitting(true)
        signUp()
          .then(() => {
            track('orgs.signup.earlyaccess', {
              firstName,
              email,
              org,
              referral,
            })
            finishSignUp(true)
          })
          .catch(err => {
            setIsSubmitting(false)

            // TODO: feels like error handling is getting too fiddly
            if (
              err.networkError.result.errors &&
              err.networkError.result.errors[0].message.includes(
                'duplicate key value',
              )
            ) {
              notification.error({
                message: `Oops!`,
                description: `Looks like you're already with us. Please check your email for a link to verify your account.`,
              })
            } else {
              notification.error({
                message: 'Oops!',
                description: 'An error has occurred. Please try again.',
              })
            }
          })
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit} hideRequiredMark colon={false}>
      <Row type="flex">
        <Col xs={24} sm={24} md={24}>
          <Form.Item
            validateStatus={firstNameError ? 'error' : ''}
            help={firstNameError || ''}
            label={'First name'}
          >
            {getFieldDecorator('firstname', {
              rules: [
                { required: true, message: 'Please enter your first name' },
              ],
            })(
              <Input
                placeholder="Harriet"
                onChange={e => setFirstName(e.target.value)}
              />,
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Form.Item
            validateStatus={emailError ? 'error' : ''}
            help={emailError || ''}
            label={'Email'}
          >
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Please enter your email' },
                { type: 'email', message: 'Please enter a valid email' },
              ],
            })(
              <Input
                placeholder="harriet@howamigoing.com"
                onChange={e => setEmail(e.target.value)}
                autoCorrect="off"
                autoCapitalize="none"
              />,
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Form.Item
            validateStatus={orgError ? 'error' : ''}
            help={orgError || ''}
            label={'Company'}
          >
            {getFieldDecorator('org', {
              rules: [
                {
                  required: true,
                  message: `Please enter your company's name`,
                },
              ],
            })(
              <Input
                placeholder="Howamigoing"
                onChange={e => setOrg(e.target.value)}
              />,
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Form.Item
            validateStatus={referralError ? 'error' : ''}
            help={referralError || ''}
            label={'How’d you hear about us?'}
          >
            {getFieldDecorator('referral', {
              rules: [
                {
                  required: true,
                  message: `Please let us know where you found us`,
                },
              ],
            })(
              <Input
                placeholder="E.g. Word of mouth, LinkedIn, Google, Instagram, etc"
                onChange={e => setReferral(e.target.value)}
              />,
            )}
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24}>
          <Form.Item className="signup-form-button">
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting}
              disabled={isSubmitting || hasErrors(getFieldsError())}
            >
              Send verification email
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Form.create({ name: 'signUpForm' })(SignUp)
