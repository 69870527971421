import React from 'react'
import { Form, Input } from 'antd'

const EditTeamForm = ({ form, team, handleChange }) => {
  const { getFieldDecorator } = form

  return (
    <Form>
      <Form.Item>
        {getFieldDecorator('teamName', {
          initialValue: team.name,
          rules: [{ required: true, message: 'This field is required!' }],
        })(<Input onKeyUp={handleChange} placeholder="Enter team name" />)}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'EditTeamForm' })(EditTeamForm)
