import gql from 'graphql-tag'

export const GET_FEEDBACK = gql`
  query getFeedback($feedbackId: String!) {
    feedback(id: $feedbackId) {
      id
      submittedAt
      review {
        id
        cycle {
          id
          type
          name
          form {
            id
            title
            description
            sections
            formQuestions {
              id
              question {
                id
                titleVariants
                description
                template {
                  id
                  type
                  settings
                }
              }
            }
          }
        }
        user {
          id
          firstName
          lastName
          email
          avatarUrl
          avatar
        }
      }
    }
  }
`

export const COMPLETE_FEEDBACK = gql`
  mutation($feedbackId: ID!, $responses: [NewResponseInput!]!) {
    completeFeedback(
      completeFeedback: { id: $feedbackId, answers: $responses }
    ) {
      id
    }
  }
`
