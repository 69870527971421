import React, { useContext, useEffect } from 'react'

import AppLoading from '../AppLoading'
import { UserContext } from '../UserContext'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import { useMutation } from '@apollo/react-hooks'
import useQuery from '../hooks/routing/useQuery'

const ACCESS_BY_TOKEN = gql`
  mutation accessByToken($token: String!) {
    token: accessByToken(token: $token)
  }
`

export default ({ match, history }) => {
  const user = useContext(UserContext)
  const query = useQuery()
  const token = query.get('t')

  const [accessByToken] = useMutation(ACCESS_BY_TOKEN)

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useEffect(() => {
    if (!user.isAuthenticated) {
      return
    }
    const decodedAccessToken = jwtDecode(token)
    const path = decodedAccessToken.path
    history.push(path)
  }, [user])

  useEffect(() => {
    async function fetchData() {
      if (!token) {
        return
      }
      const response = await accessByToken({
        variables: {
          token: token,
        },
      })

      if (response && response.data && response.data.token) {
        localStorage.setItem('HAIGToken', response.data.token)
        await user.refetchUser()
      }
    }
    fetchData()
  }, [])

  return <AppLoading />
}
