import { Card, Col, Form, Input, Row, Typography } from 'antd'
import React from 'react'

const { Text } = Typography

export default ({ user, form }) => {
  const { getFieldDecorator } = form

  return (
    <Card
      title={<Text>Your contact details</Text>}
      style={{ marginTop: 16, marginBottom: '32px' }}
    >
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item label="First name">
            {getFieldDecorator('firstName', {
              initialValue: user.firstName ? user.firstName : undefined,
              rules: [{ required: true, message: 'This field is required' }],
            })(<Input placeholder="First name" />)}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Last name">
            {getFieldDecorator('lastName', {
              initialValue: user.lastName ? user.lastName : undefined,
              rules: [{ required: true, message: 'This field is required' }],
            })(<Input placeholder="Last name" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item label="Email">
            <Input
              placeholder="Email"
              defaultValue={user.email ? user.email : undefined}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="Job title">
            {getFieldDecorator('jobTitle', {
              initialValue: user.jobTitle ? user.jobTitle : undefined,
              rules: [{ required: true, message: 'This field is required' }],
            })(<Input placeholder="Job title" />)}
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
