import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Typography } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import TestimonialsBg from '../../_assets/img/dashboard/testimonials-bg.svg'

const { Paragraph } = Typography

export default props => {
  const history = useHistory()

  const { text, author, location, hook, button, route, routeState } = props

  const handleClick = () => {
    history.push(route, { ...routeState, fromWBYHT: true })
  }

  return (
    <Card
      bordered={false}
      bodyStyle={{
        background: `url(${TestimonialsBg}) no-repeat 100% 100%`,
        padding: '24px 82px 32px 32px',
      }}
    >
      <div style={{ marginBottom: 14 }}>
        <FontAwesomeIcon
          icon={['fas', 'quote-left']}
          style={{ color: '#dadae5', fontSize: '1.5em' }}
        />
      </div>
      <Paragraph
        style={{ color: '#1c1047', fontWeight: 300, marginBottom: 12 }}
      >
        {text}
      </Paragraph>
      <div style={{ fontSize: '15px', marginBottom: 20 }}>
        <span
          style={{ color: '#281765', fontFamily: 'museo', fontWeight: 500 }}
        >
          {author}
        </span>
        <span> </span>
        <span style={{ color: '#77779f', fontWeight: 300 }}>{location}</span>
      </div>
      <div
        style={{
          color: '#f95c4b',
          fontSize: '14px',
          fontWeight: 300,
          marginBottom: 10,
        }}
      >
        {hook}
      </div>
      <Button type="primary" size="small" onClick={handleClick}>
        {button}
      </Button>
    </Card>
  )
}
