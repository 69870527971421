import { Button, Modal, Row } from 'antd'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { track } from '../_helpers/analytics'
import { UserContext } from '../UserContext'
import BackButton from './BackButton'

const DEFAULT_SPACING = 8

export default ({
  step,
  handleContinueClick,
  showDismissButton = true,
  contextId,
  typePath,
  fromWBYHT,
}) => {
  const history = useHistory()
  const user = useContext(UserContext)
  const [showModalDismiss, setShowModalDismiss] = useState(false)

  const backRoute = () => {
    if (step === 1) {
      if (fromWBYHT) {
        return '/home'
      } else {
        return contextId ? typePath + '/scheduled' : typePath + '/ongoing'
      }
    } else {
      return contextId
        ? `${typePath}/edit/${contextId}/${step - 1}`
        : `${typePath}/create/${step - 1}`
    }
  }

  const modalDismissButtons = () => {
    return (
      <div style={{ marginBottom: -DEFAULT_SPACING }}>
        <Button type="ghost" onClick={() => setShowModalDismiss(false)}>
          Stay
        </Button>
        <Button
          type="primary"
          onClick={() => {
            track(typePath.substring(1) + '.cycle.dismissLeave', {
              user: user.email,
              org: user.org.name,
              currentStep: step,
            })
            if (fromWBYHT) {
              history.push('/home')
            } else {
              history.push(
                contextId ? typePath + '/scheduled' : typePath + '/ongoing',
              )
            }
          }}
        >
          Leave
        </Button>
      </div>
    )
  }

  return (
    <Row type="flex" justify="space-between">
      {showDismissButton && (
        <div>
          <Modal
            title={
              <div style={{ fontFamily: 'museo', fontWeight: 500 }}>
                Are you sure?
              </div>
            }
            visible={showModalDismiss}
            onCancel={() => setShowModalDismiss(false)}
            centered
            footer={modalDismissButtons()}
          >
            If you leave now, you'll lose the data you've entered.
          </Modal>
          <div>
            <Button
              type="ghost"
              icon="close"
              style={{
                marginTop: DEFAULT_SPACING * 3,
                marginRight: DEFAULT_SPACING * 3,
              }}
              onClick={() => setShowModalDismiss(true)}
            >
              Dismiss
            </Button>
          </div>
        </div>
      )}
      <div style={{ marginTop: -DEFAULT_SPACING * 2 }}>
        <BackButton route={backRoute()} />
        <Button type="primary" onClick={handleContinueClick}>
          {step !== 4 ? 'Next step' : 'Schedule event'}
        </Button>
      </div>
    </Row>
  )
}
