import gql from 'graphql-tag'

export const DELETE_CYCLE = gql`
  mutation removeCycle($cycleId: ID!) {
    removeCycle(removeCycle: { id: $cycleId }) {
      id
    }
  }
`

export const DELETE_CYCLE_PERSONAL = gql`
  mutation removeCyclePersonal($cycleId: ID!) {
    removeCyclePersonal(removeCycle: { id: $cycleId }) {
      id
    }
  }
`

export const FINISH_CYCLE = gql`
  mutation removeCycle($cycleId: String!) {
    finishCycle(id: $cycleId) {
      id
    }
  }
`

export const SEND_NOTIFICATION = gql`
  mutation sendNotification($cycleId: String!, $type: String!) {
    sendNotification(cycleId: $cycleId, type: $type) {
      id
    }
  }
`
