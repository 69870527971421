import React from 'react'
import { Form, Input } from 'antd'

const CreateTeamForm = ({ form, handleChange }) => {
  const { getFieldDecorator } = form

  return (
    <Form>
      <Form.Item>
        {getFieldDecorator('teamName', {
          rules: [{ required: true, message: 'This field is required!' }],
        })(<Input onKeyUp={handleChange} placeholder="Enter team name" />)}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'CreateTeamForm' })(CreateTeamForm)
