import { useMutation } from '@apollo/react-hooks'
import { Button, Form, notification } from 'antd'
import React, { useContext } from 'react'

import { UserContext } from '../UserContext'
import AccountDetails from './AccountDetails'
import PrivacySettings from './PrivacySettings'
import { UPDATE_ACCOUNT_DETAILS } from './queries'

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const AccountForm = ({ form }) => {
  const user = useContext(UserContext)
  const [updateUserDetails, { loading }] = useMutation(UPDATE_ACCOUNT_DETAILS)

  const { validateFields, getFieldsError, isFieldsTouched } = form

  const handleSubmit = e => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (err) {
        notification.error({
          message: 'Oops!',
          description: 'It appears an error ocurred. Please try again later.',
        })
        return
      }

      const updateUserData = Object.assign({ id: user.id }, values)

      if (
        !updateUserData.currentPassword ||
        !updateUserData.newPassword ||
        !updateUserData.confirmPassword
      ) {
        delete updateUserData.currentPassword
        delete updateUserData.newPassword
        delete updateUserData.confirmPassword
      }

      try {
        await updateUserDetails({
          variables: updateUserData,
        })
      } catch (err) {
        notification.error({
          message: 'Oops!',
          description: err.networkError.result.errors[0].message,
        })
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit} colon={false}>
      <AccountDetails user={user} form={form} />

      <PrivacySettings form={form} />

      <Form.Item>
        <Button
          loading={loading}
          disabled={
            !isFieldsTouched() || loading || hasErrors(getFieldsError())
          }
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'AccountForm' })(AccountForm)
