import gql from 'graphql-tag'

export const GET_MANAGER_CYCLES = gql`
  query getManagerCycles($type: String!) {
    cycles: getCyclesForManager(type: $type) {
      managerCycleId: id
      type
      name
      startDate
      endDate
      settings
      creator {
        id
      }
      reviews {
        id
        user {
          id
          firstName
          lastName
          jobTitle
          email
          avatarUrl
          avatar
        }
        feedbacks {
          id
          submittedAt
          user {
            id
            avatarUrl
            avatar
            firstName
            lastName
            email
            jobTitle
          }
        }
      }
    }
  }
`

export const GET_MANAGER_CYCLE = gql`
  query getManagerCycle($cycleId: String!) {
    getCycleForManager(cycleId: $cycleId) {
      id
      reviews {
        id
        user {
          id
          firstName
          lastName
          jobTitle
          email
          avatarUrl
          avatar
        }
      }
    }
  }
`
