import gql from 'graphql-tag'

export const SET_ONBOARDING_STEP_STATUS = gql`
  mutation setOnboardingStepStatus(
    $step: OnboardingSteps!
    $status: OnboardingStatus!
  ) {
    setOnboardingStepStatus(
      onboardingStepStatus: { step: $step, status: $status }
    )
  }
`

export const SET_TERMS = gql`
  mutation setTerms($acceptance: Boolean!) {
    setTermsAcceptance(termsAcceptance: $acceptance)
  }
`

export const SET_COMMS = gql`
  mutation setComms($acceptance: Boolean!) {
    setCommsAcceptance(commsAcceptance: $acceptance)
  }
`

export const SET_JOB_TITLE = gql`
  mutation setJobTitle($jobTitle: String!) {
    setJobTitle(jobTitle: $jobTitle)
  }
`

export const SET_TEAM = gql`
  mutation setTeam($team: String!) {
    setTeam(team: $team)
  }
`

export const INVITE_PEOPLE = gql`
  mutation invitePeople($emails: [String!]!) {
    invitePeople(invitedPeople: { users: $emails })
  }
`

export const SET_ONBOARDING_STATUS = gql`
  mutation setOnboardingStatus($onboardingStatus: Boolean!) {
    setOnboardingStatus(onboardingStatus: $onboardingStatus)
  }
`
