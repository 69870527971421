//import { useMutation } from '@apollo/react-hooks'
import { Button, Icon, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Avatar } from '../../_components/Avatar'
import { track } from '../../_helpers/analytics'
import CycleProgressPersonal from '../../Personal360/CycleProgress'
import Overview from '../../Team360/Overview'
import { UserContext } from '../../UserContext'
import OrgAvatar from '../OrgAvatar'
import CycleProgress from './CycleProgress'

//import { SEND_NOTIFICATION } from './queries'

const { Paragraph, Text } = Typography

const DEFAULT_PX_UNIT = 8

export default ({ cycle, typePath = '' }) => {
  const location = useLocation()
  const user = useContext(UserContext)
  const [canSeeProgress, setCanSeeProgress] = useState(false)
  const [canSeeTask, setCanSeeTask] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const userReview = cycle.reviews.find(review => review.user.id === user.id)

  const requestedFeedbacks = cycle.feedbacks.requested
  const feedbackRequests = cycle.feedbacks.requests.filter(
    f => f.submittedAt === null,
  )

  useEffect(() => {
    if (cycle.type === 'PERSONAL') {
      if (user.id === cycle.creator.id) {
        setCanSeeProgress(true)
        setCanSeeTask(false)
      }
    } else {
      if (
        user.isAdmin() ||
        user.isOwner() ||
        (user.isManager() && user.id === cycle.creator.id)
      ) {
        setCanSeeProgress(true)
      }
    }
  }, [cycle])

  /*const [cascaderOption, setCascaderOption] = useState(null)

  const [sendNotification] = useMutation(SEND_NOTIFICATION)

   const notificationOptions = [
    {
      value: 'welcome',
      label: 'Welcome to the CYCLE(TM)',
    },
    {
      value: 'noFeedbackRequested',
      label: 'User did not request any feedback yet',
    },
    {
      value: 'outstandingFirst',
      label: 'User has feedback to give - 3 days in',
    },
    {
      value: 'outstandingSecond',
      label: 'User has feedback to give - 5 days in',
    },
    {
      value: 'outstandingThird',
      label: 'User has feedback to give - 10 days in',
    },
    {
      value: 'almostDone',
      label: 'User has feedback to give - 24 hours to go',
    },
    {
      value: 'done',
      label: 'The CYCLE(TM) has ended',
    },
  ]

  const cascaderOptionChange = value => {
    setCascaderOption(value)
  }

  const handleSendButton = () => {
    sendNotification({
      variables: {
        cycleId: cycle.id,
        type: cascaderOption[0],
      },
    })
      .then(() => {
        notification.success({
          message: 'Yey!',
          description: 'The notification was scheduled successfully.',
        })
      })
      .catch((error, data) => {
        notification.error({
          message: 'Oops!',
          description: 'It appears an error ocurred. Please try again later.',
        })
      })
  } */

  return (
    <div className="cycle-list-item">
      {canSeeProgress && (
        <div
          style={{
            borderBottom: cycle.type !== 'PERSONAL' ? '1px solid #e8e8e8' : '',
            marginBottom: DEFAULT_PX_UNIT * 2,
          }}
        >
          {cycle.type === 'PERSONAL' ? (
            <CycleProgressPersonal cycle={cycle} />
          ) : (
            <CycleProgress cycle={cycle} />
          )}
        </div>
      )}
      {canSeeTask && (
        <div>
          {userReview || feedbackRequests.length > 0 ? (
            <div
              style={{
                marginBottom: DEFAULT_PX_UNIT * 3,
                color: '#77779f',
              }}
            >
              Your tasks
            </div>
          ) : (
            <div>No tasks pending.</div>
          )}

          {canSeeTask && userReview && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: DEFAULT_PX_UNIT * 3,
                paddingBottom: DEFAULT_PX_UNIT * 3,
                borderBottom: '1px solid #f3f2f7',
              }}
            >
              <div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <OrgAvatar />
                  <div style={{ marginLeft: DEFAULT_PX_UNIT * 2 }}>
                    <Paragraph
                      style={{
                        fontFamily: 'museo, serif',
                        fontWeight: 300,
                        marginBottom: 0,
                      }}
                    >
                      {requestedFeedbacks.length > 0
                        ? `You requested feedback from 
                                  ${requestedFeedbacks.length} people`
                        : `Request feedback from at least
                                  ${cycle.settings.minRequests} people`}
                    </Paragraph>
                    <Button
                      type="link"
                      style={{
                        display: 'flex',
                        fontSize: 14,
                        color: '#f95c4b',
                        padding: 0,
                        height: 'auto',
                      }}
                      onClick={() => setShowModal(true)}
                    >
                      <Icon type="eye" style={{ fontSize: 16 }} />
                      <span
                        style={{
                          lineHeight: '16px',
                          marginLeft: '6px',
                          textDecoration: 'underline',
                        }}
                      >
                        See how others are going
                      </span>
                    </Button>

                    {showModal && (
                      <Overview
                        cycleId={cycle.id}
                        showModal={showModal}
                        handleCloseModal={() => setShowModal(false)}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <Link
                  to={typePath + '/requestFeedback/' + userReview.id}
                  onClick={() => {
                    if (location.pathname === '/home') {
                      track(
                        typePath.substring(1) +
                          '.feedback.requestFeedbackDashboard',
                        {
                          user: user.email,
                          org: user.org.name,
                          reviewId: userReview.id,
                        },
                      )
                    }
                  }}
                >
                  <Button type="primary" shape="round" size="default">
                    {requestedFeedbacks.length > 0
                      ? `Edit request`
                      : `Request feedback`}
                  </Button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
      {feedbackRequests.length > 0 &&
        feedbackRequests.map((feedback, index) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom:
                cycle.type === 'PERSONAL'
                  ? DEFAULT_PX_UNIT * 2
                  : index === feedbackRequests.length - 1
                  ? 0
                  : DEFAULT_PX_UNIT * 3,
              paddingBottom:
                cycle.type === 'PERSONAL'
                  ? 0
                  : index === feedbackRequests.length - 1
                  ? DEFAULT_PX_UNIT * 2
                  : DEFAULT_PX_UNIT * 3,
              borderBottom:
                cycle.type === 'PERSONAL'
                  ? 'none'
                  : index === feedbackRequests.length - 1
                  ? 'none'
                  : '1px solid #f3f2f7',
            }}
            key={feedback.id}
          >
            <div style={{ display: 'flex', alignItems: 'stretch' }}>
              <Avatar user={feedback.toUser} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginLeft: DEFAULT_PX_UNIT * 2,
                }}
              >
                <Text
                  style={{
                    fontFamily: 'museo',
                    fontWeight: 300,
                    color: '#1c1047',
                  }}
                >
                  {feedback.toUser.firstName} {feedback.toUser.lastName} asked
                  you for feedback
                </Text>
                <Text style={{ fontSize: 14, fontWeight: 300 }}>
                  {feedback.toUser.jobTitle}
                </Text>
              </div>
            </div>
            <div>
              <Link
                to={typePath + '/giveFeedback/' + feedback.id}
                onClick={() => {
                  const dashboard =
                    location.pathname === '/home' ? 'Dashboard' : ''
                  track(
                    typePath.substring(1) +
                      '.feedback.giveFeedback' +
                      dashboard,
                    {
                      provider: user.email,
                      org: user.org.name,
                      feedbackId: feedback.id,
                    },
                  )
                }}
              >
                <Button type="primary" shape="round" size="default">
                  Give feedback
                </Button>
              </Link>
            </div>
          </div>
        ))}

      {/* <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `${DEFAULT_PX_UNIT*2}px 0`,
          borderTop: '1px solid #e8e8e8',
        }}
      >
        <div>
          <Title level={4} type="danger">
            DEBUG - Trigger a new notification
          </Title>
          <Cascader
            options={notificationOptions}
            placeholder="Please select one"
            onChange={cascaderOptionChange}
            style={{ marginBottom: DEFAULT_PX_UNIT*2, width: 320 }}
          />
          <Paragraph strong type="danger">
            Disclaimer:
          </Paragraph>
          <Paragraph style={{ marginBottom: 0 }}>
            The notification will be sent only if its corresponding{' '}
            <b>conditions</b> to trigger are met.
            <br />
            Also, it will be sent to <b>every individual</b> who meets the
            conditions.
          </Paragraph>
        </div>
        <div>
          <Button
            type="danger"
            shape="round"
            size="default"
            onClick={handleSendButton}
          >
            Send Notification
          </Button>
        </div>
      </div> */}
    </div>
  )
}
