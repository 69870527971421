import { Button, Card, Input, Popover, Progress, Typography } from 'antd'
import React, { useContext, useState } from 'react'

import { SET_ONBOARDING_STEP_STATUS } from '../Onboarding/queries'
import { UPDATE_ACCOUNT_DETAILS } from '../Account/queries'
import { UserContext } from '../UserContext'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'
import { withRouter } from 'react-router-dom'

const { Title, Text } = Typography

const errorStyle = {
  color: '#f5222d',
  fontWeight: 300,
  fontSize: '14px',
  lineHeight: '22px',
  marginTop: '8px',
}

const SetPassword = ({ stepIndex, totalSteps, history, onSubmit }) => {
  const user = useContext(UserContext)
  const [password, setPassword] = useState(false)
  const [passwordRepeat, setPasswordRepeat] = useState(false)
  const [passwordLengthCheck, setPasswordLengthCheck] = useState(false)
  const [passwordUppercaseCheck, setPasswordUppercaseCheck] = useState(false)
  const [passwordNumberCheck, setPasswordNumberCheck] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [passwordError, setPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)

  const [updateUserMutation] = useMutation(UPDATE_ACCOUNT_DETAILS, {
    variables: {
      id: user.id,
      newPassword: password,
      confirmPassword: passwordRepeat,
    },
  })

  const [setPasswordStepDone] = useMutation(SET_ONBOARDING_STEP_STATUS, {
    variables: {
      step: 'PASSWORD',
      status: 'COMPLETE',
    },
  })

  const validatePassword = e => {
    setPassword(e.target.value)

    if (e.target.value.length >= 8) {
      setPasswordLengthCheck(true)
    } else {
      setPasswordLengthCheck(false)
    }

    if (/[A-Z]/.test(e.target.value)) {
      setPasswordUppercaseCheck(true)
    } else {
      setPasswordUppercaseCheck(false)
    }

    if (/\d/.test(e.target.value)) {
      setPasswordNumberCheck(true)
    } else {
      setPasswordNumberCheck(false)
    }
  }

  const submit = async () => {
    setPasswordError(false)
    setConfirmPasswordError(false)

    if (!password) {
      setPasswordError('Please enter a password')
    } else if (
      !passwordLengthCheck ||
      !passwordUppercaseCheck ||
      !passwordNumberCheck
    ) {
      setPasswordError(`Please choose a password matching the requirements`)
      return
    } else if (password !== passwordRepeat) {
      setConfirmPasswordError(
        `Your two passwords don't match. Please check again.`,
      )
      return
    } else {
      setIsSubmitting(true)
      const { data } = await updateUserMutation()

      if (data.updateUser.id) {
        await setPasswordStepDone()
        track(
          user.isOwner()
            ? 'admin.onboarding.password'
            : 'user.onboarding.password',
          {
            user: user.email,
            org: user.org.name,
          },
        )
        setIsSubmitting(false)
        if (onSubmit) {
          onSubmit()
        }
      }
    }
  }

  const passwordTooltipTemplate = (
    <div>
      <div>
        <Text
          type={passwordLengthCheck ? 'secondary' : 'danger'}
          style={{
            fontWeight: 300,
            color: passwordLengthCheck ? '#77779f' : '#f5222d',
          }}
        >
          At least 8 characters
        </Text>
      </div>

      <div>
        <Text
          type={passwordUppercaseCheck ? 'secondary' : 'danger'}
          style={{
            fontWeight: 300,
            color: passwordUppercaseCheck ? '#77779f' : '#f5222d',
          }}
        >
          At least one uppercase
        </Text>
      </div>

      <div>
        <Text
          type={passwordNumberCheck ? 'secondary' : 'danger'}
          style={{
            fontWeight: 300,
            color: passwordNumberCheck ? '#77779f' : '#f5222d',
          }}
        >
          At least one number
        </Text>
      </div>
    </div>
  )

  return (
    <div className="onboarding-set-password">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontWeight: 500, fontSize: '24px' }}
        >
          {user.firstName ? `Nice to meet you, ${user.firstName}! ` : null}
          {user.firstName ? <br /> : null}
          {stepIndex === 1 ? `Let's start with setting ` : `Let's set `} your
          bulletproof password:
        </Title>

        <div>
          <Text style={{ fontWeight: 300 }}>Password</Text>
          <Popover
            placement="bottom"
            content={passwordTooltipTemplate}
            trigger="focus"
          >
            <Input.Password
              onChange={validatePassword}
              style={{ marginTop: '8px' }}
              placeholder="MyFirstPuppy123"
              onFocus={() => setPasswordError(false)}
            />
            {passwordError && <div style={errorStyle}>{passwordError}</div>}
          </Popover>
        </div>

        <div style={{ marginTop: '16px' }}>
          <Text style={{ fontWeight: 300 }}>Confirm password</Text>
          <Input.Password
            onChange={e => setPasswordRepeat(e.target.value)}
            style={{ marginTop: '8px' }}
            placeholder="MyFirstPuppy123"
          />
          {confirmPasswordError && (
            <div style={errorStyle}>{confirmPasswordError}</div>
          )}
        </div>

        <div style={{ marginTop: '32px' }}>
          <Button
            onClick={submit}
            loading={isSubmitting}
            disabled={isSubmitting}
            type="primary"
          >
            Set password
          </Button>
        </div>
      </Card>
      <div style={{ marginTop: '48px' }}>
        <Text style={{ fontSize: '14px', fontWeight: 300 }}>
          Step {stepIndex} out of {totalSteps}
        </Text>
        <Progress
          style={{
            marginTop: '8px',
          }}
          strokeColor={'#77779f'}
          percent={(stepIndex * 100) / totalSteps}
          showInfo={false}
        />
      </div>
    </div>
  )
}

export default withRouter(SetPassword)
