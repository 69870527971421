import { Card, Col, Form, Input, Popover, Row, Typography } from 'antd'
import React, { useState } from 'react'

const { Text } = Typography

export default ({ form }) => {
  const [passwordLengthCheck, setPasswordLengthCheck] = useState(false)
  const [passwordUppercaseCheck, setPasswordUppercaseCheck] = useState(false)
  const [passwordNumberCheck, setPasswordNumberCheck] = useState(false)

  const { getFieldValue, getFieldDecorator, setFields } = form

  const checkPasswordTooltip = e => {
    const value = e.target.value

    if (value && value.length >= 8) {
      setPasswordLengthCheck(true)
    } else {
      setPasswordLengthCheck(false)
    }

    if (value && /[A-Z]/.test(value)) {
      setPasswordUppercaseCheck(true)
    } else {
      setPasswordUppercaseCheck(false)
    }

    if (value && /\d/.test(value)) {
      setPasswordNumberCheck(true)
    } else {
      setPasswordNumberCheck(false)
    }
  }

  const validatePasswords = (rule, value, callback) => {
    const currentPassword = getFieldValue('currentPassword')
    const newPassword = getFieldValue('newPassword')
    const confirmPassword = getFieldValue('confirmPassword')

    let hasErrors = false

    if (
      (currentPassword && (!newPassword || !confirmPassword)) ||
      (newPassword && (!currentPassword || !confirmPassword)) ||
      (confirmPassword && (!currentPassword || !newPassword))
    ) {
      hasErrors = true
    } else if (currentPassword === newPassword) {
      hasErrors = true
    } else if (newPassword !== confirmPassword) {
      hasErrors = true
    } else if (
      newPassword.length < 8 ||
      !/[A-Z]/.test(newPassword) ||
      !/\d/.test(newPassword)
    ) {
      hasErrors = true
    }

    if (hasErrors) {
      callback(true)

      setFields({
        currentPassword: {
          value: currentPassword,
          error: [new Error(true)],
        },
        newPassword: {
          value: newPassword,
          error: [new Error(true)],
        },
        confirmPassword: {
          value: confirmPassword,
          error: [new Error(true)],
        },
      })
    }

    return true
  }

  const passwordTooltipTemplate = (
    <div>
      <div>
        <Text
          type={passwordLengthCheck ? 'secondary' : 'danger'}
          style={{
            fontWeight: 300,
            color: passwordLengthCheck ? '#77779f' : '#281765',
          }}
        >
          At least 8 characters
        </Text>
      </div>

      <div>
        <Text
          type={passwordUppercaseCheck ? 'secondary' : 'danger'}
          style={{
            fontWeight: 300,
            color: passwordUppercaseCheck ? '#77779f' : '#281765',
          }}
        >
          At least one uppercase
        </Text>
      </div>

      <div>
        <Text
          type={passwordNumberCheck ? 'secondary' : 'danger'}
          style={{
            fontWeight: 300,
            color: passwordNumberCheck ? '#77779f' : '#281765',
          }}
        >
          At least one number
        </Text>
      </div>
    </div>
  )

  return (
    <Card
      title={<Text>Your privacy settings</Text>}
      style={{ marginTop: 16, marginBottom: '40px' }}
    >
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item label="Current password" validateStatus={''} help={''}>
            {getFieldDecorator('currentPassword', {
              rules: [
                {
                  validator: validatePasswords,
                },
              ],
            })(<Input.Password placeholder="Enter current password" />)}
          </Form.Item>
          <Form.Item label="New password" validateStatus={''} help={''}>
            <Popover
              placement="bottom"
              content={passwordTooltipTemplate}
              trigger="focus"
            >
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    validator: validatePasswords,
                  },
                ],
              })(
                <Input.Password
                  onChange={checkPasswordTooltip}
                  placeholder="Enter new password"
                />,
              )}
            </Popover>
          </Form.Item>
          <Form.Item label="Confirm new password" validateStatus={''} help={''}>
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  validator: validatePasswords,
                },
              ],
            })(<Input.Password placeholder="Confirm new password" />)}
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
