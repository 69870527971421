import './styles.less'

import { Avatar, AvatarName } from '../../_components/Avatar'
import { Checkbox, Col, Form, Input, Row, Select, Tooltip } from 'antd'
import React, { useState } from 'react'

import FormFieldDescription from '../../_components/FormFieldDescription'

const EditUserForm = ({ user, colleagues, teams, form, canChangeOwner }) => {
  const { getFieldDecorator } = form

  const [managedByUsers, setManagedByUsers] = useState(
    () =>
      colleagues &&
      colleagues
        .filter(
          colleague =>
            colleague.orgRelationsFrom &&
            colleague.orgRelationsFrom.length > 0 &&
            colleague.orgRelationsFrom.find(rel => rel.to.id === user.id),
        )
        .map(c => c.id),
  )

  const [managesUsers, setManagesUsers] = useState(
    () =>
      colleagues &&
      colleagues
        .filter(
          colleague =>
            colleague.orgRelationsTo &&
            colleague.orgRelationsTo.length > 0 &&
            colleague.orgRelationsTo.find(rel => rel.from.id === user.id),
        )
        .map(c => c.id),
  )

  const [team] = useState(
    () =>
      user &&
      user.groupUsers &&
      user.groupUsers.length > 0 &&
      user.groupUsers.find(groupUser => groupUser.group.type === 'TEAM'),
  )

  const [isAdmin] = useState(
    user.userRoles &&
      user.userRoles &&
      user.userRoles.find(x => x.role.type.toLowerCase() === 'company_admin')
      ? true
      : false,
  )
  const [isOwner] = useState(
    user.userRoles &&
      user.userRoles &&
      user.userRoles.find(x => x.role.type.toLowerCase() === 'owner')
      ? true
      : false,
  )

  const managedByChange = newUsersIds => {
    const newUsersList = colleagues.filter(
      colleague => newUsersIds.indexOf(colleague.id) > -1,
    )

    setManagedByUsers(newUsersList)
  }

  const managesChange = newUsersIds => {
    const newUsersList = colleagues.filter(
      colleague => newUsersIds.indexOf(colleague.id) > -1,
    )

    setManagesUsers(newUsersList)
  }

  const filterOption = (input, option) => {
    const colleague = option.props.children.props.user

    return (
      (colleague.firstName &&
        colleague.firstName.toLowerCase().includes(input.toLowerCase())) ||
      (colleague.lastName &&
        colleague.lastName.toLowerCase().includes(input.toLowerCase())) ||
      (colleague.email &&
        colleague.email.toLowerCase().includes(input.toLowerCase()))
    )
  }

  return (
    <Form layout="vertical" hideRequiredMark={true}>
      <Row type="flex" gutter={16} style={{ marginBottom: '8px' }}>
        <Col
          className="edit-user-avatar-container"
          style={{ flex: '0 0 80px', flexDirection: 'row' }}
        >
          <Avatar
            user={user}
            size={80}
            disableTooltip={true}
            style={{
              borderRadius: '28px',
              borderWidth: '5px',
            }}
          />
        </Col>
        <Col style={{ flex: '1 1 auto', width: '200px' }}>
          <Row type="flex" gutter={16}>
            <Col md={12}>
              <Form.Item label="First name" style={{ marginBottom: 0 }}>
                {getFieldDecorator('firstName', {
                  initialValue: user.firstName,
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item label="Last name" style={{ marginBottom: 0 }}>
                {getFieldDecorator('lastName', {
                  initialValue: user.lastName,
                })(<Input />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item label="E-mail" style={{ marginBottom: 0 }}>
            {getFieldDecorator('email', {
              initialValue: user.email,
              rules: [
                {
                  type: 'email',
                  message: `The email doesn't have the right format.`,
                },
                {
                  required: true,
                  message: 'The email is mandatory.',
                },
              ],
            })(<Input autoCorrect="off" autoCapitalize="none" />)}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item label="Job title" style={{ marginBottom: 0 }}>
            {getFieldDecorator('jobTitle', {
              initialValue: user.jobTitle,
            })(<Input />)}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item label="Team" style={{ marginBottom: 0 }}>
            {getFieldDecorator('teamId', {
              initialValue: team.group ? team.group.id : null,
            })(
              <Select allowClear>
                {teams
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(team => (
                    <Select.Option key={team.id}>{team.name}</Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item label="Managed by" style={{ marginBottom: 0 }}>
            {getFieldDecorator('managedBy', {
              initialValue: managedByUsers,
            })(
              <Select
                mode={'multiple'}
                onChange={managedByChange}
                filterOption={filterOption}
              >
                {colleagues.map(colleague => (
                  <Select.Option key={colleague.id}>
                    <AvatarName user={colleague} />
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item label="Manages" style={{ marginBottom: 0 }}>
            {getFieldDecorator('manages', {
              initialValue: managesUsers,
            })(
              <Select
                mode={'multiple'}
                onChange={managesChange}
                filterOption={filterOption}
              >
                {colleagues.map(colleague => (
                  <Select.Option key={colleague.id}>
                    <AvatarName user={colleague} />
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '16px', fontWeight: 300 }}>
        <Col>User privileges</Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item
            extra={
              <FormFieldDescription>
                Admins can add, edit and delete other users and set up
                company-wide events (e.g. Team 360 feedback). Each company
                account can have multiple Admins.
              </FormFieldDescription>
            }
          >
            {getFieldDecorator('isAdmin', {
              initialValue: isAdmin,
              valuePropName: 'checked',
            })(<Checkbox style={{ fontWeight: 300 }}>Admin</Checkbox>)}
          </Form.Item>
        </Col>
        <Col
          md={12}
          style={{ visibility: canChangeOwner ? 'visible' : 'hidden' }}
        >
          <Tooltip title="Please contact support to change the owner">
            <Form.Item
              extra={
                <FormFieldDescription>
                  The Owner has all Admin privileges plus they can close the
                  company’s Howamigoing account. There is only 1 Owner for each
                  company account.
                </FormFieldDescription>
              }
            >
              {getFieldDecorator('isOwner', {
                initialValue: isOwner,
                valuePropName: 'checked',
              })(<Checkbox disabled={canChangeOwner}>Owner</Checkbox>)}
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  )
}

export default Form.create({ name: 'EditUserForm' })(EditUserForm)
