import {} from 'react-router-dom'
import '../ResetPassword/style.less'

import { Button, Card, Col, Form, Input, Row, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Logo from '../_assets/img/logo.svg'
import { UserContext } from '../UserContext'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import useQuery from '../hooks/routing/useQuery'

const ResetPasswordWithToken = ({ form }) => {
  const user = useContext(UserContext)

  const { userId } = useParams()
  const query = useQuery()
  const token = query.get('t')
  const history = useHistory()

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useMobileOptimized(true)

  const hasErrors = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field])
  }

  const {
    getFieldDecorator,
    validateFields,
    isFieldTouched,
    getFieldError,
    getFieldsError,
    getFieldValue,
  } = form

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    validateFields(async (errors, values) => {
      if (!errors) {
        setIsSubmitting(true)

        try {
          await user.resetPassword({
            id: userId,
            token,
            password: values.password,
          })
          history.push('/resetPassword/confirm')
        } catch (err) {
          setIsSubmitting(false)
          notification.error({
            message: 'Error!',
            description:
              'There was a problem resetting your password. Please try again.',
          })
        }
      }
    })
  }

  const validatePassword = (rule, value) => {
    if (value.length < 8 || !/[A-Z]/.test(value) || !/\d/.test(value)) {
      return false
    }

    return true
  }

  const checkPasswordsMatch = ({ value }) => {
    return getFieldValue('password') === getFieldValue('repeatPassword')
  }

  const passwordError = isFieldTouched('password') && getFieldError('password')
  const repeatPasswordError =
    isFieldTouched('repeatPassword') && getFieldError('repeatPassword')

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login">
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
        <Card className="screen-container-card">
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 12,
            }}
          >
            Please enter your new bulletproof password:
          </div>

          <Form
            layout="horizontal"
            onSubmit={handleSubmit}
            hideRequiredMark
            colon={false}
          >
            <Form.Item
              style={{ marginBottom: 16 }}
              label="New password"
              help={passwordError || ''}
              validateStatus={passwordError ? 'error' : ''}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'You need to fill your password!',
                  },
                  {
                    validator: validatePassword,
                    message:
                      'Your password does not match our security requirements!',
                  },
                ],
              })(
                <Input.Password
                  style={{
                    boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                  }}
                  placeholder="Enter your new password"
                />,
              )}
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 32 }}
              label="Confirm new password"
              help={repeatPasswordError || ''}
              validateStatus={repeatPasswordError ? 'error' : ''}
            >
              {getFieldDecorator('repeatPassword', {
                rules: [
                  {
                    validator: checkPasswordsMatch,
                    message: 'Both passwords need to match!',
                  },
                ],
              })(
                <Input.Password
                  style={{
                    boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                  }}
                  placeholder="Confirm your new password"
                />,
              )}
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Button
                disabled={isSubmitting || hasErrors(getFieldsError())}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Set new password
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default Form.create({ name: 'changePasswordForm' })(
  ResetPasswordWithToken,
)
