import { Steps } from 'antd'
import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import CycleContext from './Personal360Context'

const { Step } = Steps

const StepsComponent = props => {
  const context = useContext(CycleContext)
  const baseUrl = context.id
    ? '/personal360/edit/' + context.id + '/'
    : '/personal360/create/'

  useEffect(() => {
    const locationStepNumber = props.history.location.pathname.split('/').pop()

    if (locationStepNumber > 1 && checkStepInvalid(locationStepNumber - 1)) {
      props.setCurrentStep(0)
      props.history.push(baseUrl + 1)
    }

    props.setCurrentStep(locationStepNumber - 1)
  }, [props.history.location.key])

  const steps = [
    { title: 'Select form' },
    { title: 'Feedback providers' },
    { title: 'Final details' },
    { title: 'Confirm' },
  ]

  const checkStepInvalid = step => {
    return (
      (step > 0 && context.form === null) ||
      (step > 1 && context.requestedUsers.length < 2) ||
      (step > 2 &&
        (context.name === '' ||
          context.startDate === null ||
          context.endDate === null ||
          context.timezone === null))
    )
  }

  const setStep = newStep => {
    if (checkStepInvalid(newStep)) {
      return
    }

    props.setCurrentStep(newStep)
    props.history.push(baseUrl + (newStep + 1))
  }

  return (
    <Steps
      className="steps-cycle-creation"
      current={props.currentStep}
      onChange={setStep}
    >
      {steps.map((step, index) => (
        <Step
          status={index === props.currentStep ? 'process' : 'wait'}
          title={step.title}
          key={index}
        />
      ))}
    </Steps>
  )
}

export default withRouter(StepsComponent)
