import '../ResetPassword/style.less'
import './styles.less'

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Logo from '../_assets/img/logo.svg'
import { track } from '../_helpers/analytics'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import useQuery from '../hooks/routing/useQuery'
import { UserContext } from '../UserContext'

const { Text } = Typography

const LogIn = ({ history, form }) => {
  const user = useContext(UserContext)
  const query = useQuery()
  const redirect = query.get('redirect')

  const { getFieldDecorator, validateFields, getFieldError } = form

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  useMobileOptimized(true)

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useEffect(() => {
    if (!user.isAuthenticated) {
      return
    }
    track('accounts.user.login', { email: user.email })
    const redirectUrl = redirect ? redirect : '/welcome'
    history.push(redirectUrl)
  }, [user.isAuthenticated])

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((errors, values) => {
      if (!errors) {
        setIsSubmitting(true)
        user
          .login({ username: values.email, password: values.password })
          .catch(err => {
            notification.error({ message: 'Oops!', description: err.message })
          })
          .finally(() => {
            setIsSubmitting(false)
          })
      } else {
        setEmailError(getFieldError('email'))
        setPasswordError(getFieldError('password'))
      }
    })
  }

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login">
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>

        <Card className="screen-container-card">
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 12,
            }}
          >
            Knock-knock! Who's there?
          </div>
          <div
            className="color-primary"
            style={{ fontWeight: 300, marginBottom: '24px' }}
          >
            We know, it’s you. But just to confirm, please enter your details
            below to log in to your account.
          </div>
          <Form
            layout="horizontal"
            onSubmit={handleSubmit}
            hideRequiredMark
            colon={false}
            className="login-form"
          >
            <Form.Item
              style={{ marginBottom: 16 }}
              label={<Text style={{ fontWeight: 300 }}>Email</Text>}
              help={emailError || ''}
              validateStatus={emailError ? 'error' : ''}
            >
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    message: 'You need to fill your email!',
                  },
                  {
                    type: 'email',
                    message: `Your email doesn't have the right format!`,
                  },
                ],
              })(
                <Input
                  style={{
                    boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                  }}
                  placeholder="harriet@howamigoing.com"
                  autoCorrect="off"
                  autoCapitalize="none"
                />,
              )}
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 24 }}
              label={<Text style={{ fontWeight: 300 }}>Password</Text>}
              help={passwordError || ''}
              validateStatus={passwordError ? 'error' : ''}
            >
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: 'You need to fill your password!',
                  },
                ],
              })(
                <Input.Password
                  style={{
                    boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                    borderRadius: '12px',
                  }}
                  placeholder="MyFirstPuppy123"
                />,
              )}
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Button
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                style={{ marginRight: 16, marginTop: 8 }}
              >
                Log in
              </Button>
              <Button type="link" style={{ marginTop: 8, padding: 0 }}>
                <Link to="/resetPassword" style={{ color: '#ec5747' }}>
                  Oops, I forgot my password
                </Link>
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default Form.create({ name: 'loginForm' })(LogIn)
