import './style.less'

import { Button, Icon, Menu } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { ReactComponent as AdministrationIcon } from '../../_assets/img/menu/admin.svg'
import { ReactComponent as AmaIcon } from '../../_assets/img/menu/ama.svg'
// import { ReactComponent as CatchupsIcon } from '../../_assets/img/menu/catchups.svg'
import { ReactComponent as FeedbackIcon } from '../../_assets/img/menu/feedback.svg'
import { ReactComponent as GoodVibesIcon } from '../../_assets/img/menu/goodVibes.svg'
import { ReactComponent as HomeIcon } from '../../_assets/img/menu/home.svg'
// import { ReactComponent as MilestoneIcon } from '../../_assets/img/menu/milestone.svg'
import { ReactComponent as Personal360Icon } from '../../_assets/img/menu/personal360.svg'
import ResourcesGraphic from '../../_assets/img/menu/resources.svg'
// import { ReactComponent as ProbationIcon } from '../../_assets/img/menu/probation.svg'
// import { ReactComponent as PulseIcon } from '../../_assets/img/menu/pulse.svg'
import { ReactComponent as Team360Icon } from '../../_assets/img/menu/team360.svg'
import { track } from '../../_helpers/analytics'
import { PremiumContext } from '../../PremiumContext'
import UserAvatar from '../../UserAvatar'
import { UserContext } from '../../UserContext'
import { AvatarName } from '../Avatar'

const { SubMenu } = Menu

const STYLE_ARROW_SUBMENU = {
  fontSize: 10,
  marginLeft: 16,
  color: '#6b6b8f',
}
const STYLE_MENU_LINK = {
  fontFamily: 'Europa',
  fontSize: 15,
}

const routes = [
  {
    key: 'home',
    link: '/home',
    activeLink: null,
    name: 'Home',
    icon: <Icon component={HomeIcon} className="menu-icon" />,
  },
  {
    key: 'feedback',
    name: 'Feedback',
    icon: <Icon component={FeedbackIcon} className="menu-icon" />,
    subroutes: [
      {
        key: 'team360',
        link: '/team360/ongoing',
        activeLink: '/team360',
        name: 'Team 360',
        icon: <Icon component={Team360Icon} className="menu-icon" />,
      },
      {
        key: 'personal360',
        link: '/personal360/ongoing',
        activeLink: '/personal360',
        name: 'Personal 360',
        icon: <Icon component={Personal360Icon} className="menu-icon" />,
      },
      /* {
        key: 'probation',
        link: '#',
        name: 'Probation',
        icon: <Icon component={ProbationIcon} className="menu-icon" />,
      },
      {
        key: 'pulse',
        link: '#',
        name: 'Pulse',
        icon: <Icon component={PulseIcon} className="menu-icon" />,
      }, */
    ],
  },
  {
    key: 'goodVibes',
    link: '/goodVibes',
    activeLink: '/goodVibes',
    name: 'Good vibes',
    icon: <Icon component={GoodVibesIcon} className="menu-icon" />,
  },
  /* {
    key: 'milestones',
    link: '#',
    name: 'Milestones',
    icon: <Icon component={MilestoneIcon} className="menu-icon" />,
    premium: true,
  },
  {
    key: 'catchUp',
    link: '#',
    name: 'Catch-ups',
    icon: <Icon component={CatchupsIcon} className="menu-icon" />,
    premium: true,
  }, */
  {
    key: 'askMeAnything',
    link: '/askMeAnything',
    activeLink: '/askMeAnything',
    name: 'Ask me anything',
    icon: <Icon component={AmaIcon} className="menu-icon" />,
  },
]
const adminRoute = {
  key: 'administration',
  link: '/admin',
  icon: <Icon component={AdministrationIcon} className="menu-icon" />,
  name: 'Admin',
}

const SideMenu = props => {
  const user = useContext(UserContext)
  const premiumContext = useContext(PremiumContext)
  const [activeKey, setActiveKey] = useState(null)

  useEffect(() => {
    let activeRoute = null
    const openRoute = []
    if (props.location.pathname.indexOf(adminRoute.link) > -1) {
      activeRoute = adminRoute.key
    } else if (props.location.pathname === '/home') {
      activeRoute = 'home'
    } else {
      routes.forEach(route => {
        if (
          route.activeLink &&
          props.location.pathname.indexOf(route.link) > -1
        ) {
          activeRoute = route.key
        } else if (route.subroutes) {
          const subroute = route.subroutes.find(
            subroute =>
              props.location.pathname.indexOf(subroute.activeLink) > -1,
          )
          if (subroute) {
            activeRoute = subroute.key
            openRoute.push(route.key)
          }
        }
      })
    }
    setActiveKey(activeRoute)
  }, [props.location.pathname])

  const renderMenuLink = route => {
    return (
      <Menu.Item
        key={route.key}
        onClick={
          route.premium
            ? () => premiumContext.showPopUp(route.key, 'sideMenu')
            : undefined
        }
      >
        <Link to={route.link} style={{ display: 'flex', alignItems: 'center' }}>
          {route.icon}
          <span style={{ ...STYLE_MENU_LINK, flexGrow: 2 }}>{route.name}</span>
          {route.premium && (
            <div>
              <span className="premium-tag">Coming soon</span>
            </div>
          )}
        </Link>
      </Menu.Item>
    )
  }

  const handleMenuItemClicked = ({ key }) => {
    track(`menu.${key}.clicked`, {
      user: user.email,
      org: user.org.name,
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        paddingTop: 40,
      }}
    >
      <UserAvatar user={user} />
      <div style={{ paddingLeft: '16px' }}>
        <div
          className="font-secondary color-primary"
          style={{ fontWeight: 500, fontSize: 16 }}
        >
          <AvatarName user={user} />
        </div>
        <div
          className="font-primary color-primary"
          style={{ fontWeight: 300, fontSize: 14 }}
        >
          {user.jobTitle}
        </div>
      </div>

      <Menu
        className="side-menu"
        inlineIndent={16}
        selectedKeys={[activeKey]}
        onClick={handleMenuItemClicked}
        defaultOpenKeys={['feedback']}
        mode="inline"
        style={{
          marginTop: 28,
          fontFamily: 'Europa',
        }}
      >
        {routes.map(route =>
          route.subroutes ? (
            <SubMenu
              expandIcon={props =>
                props.isOpen ? (
                  <Icon style={STYLE_ARROW_SUBMENU} type="up" />
                ) : (
                  <Icon style={STYLE_ARROW_SUBMENU} type="down" />
                )
              }
              key={route.key}
              title={
                <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                  {route.icon}
                  <span style={STYLE_MENU_LINK}>{route.name}</span>
                </span>
              }
            >
              {route.subroutes.map(subroute => renderMenuLink(subroute))}
            </SubMenu>
          ) : (
            renderMenuLink(route)
          ),
        )}
        {(user.isAdmin() || user.isOwner() || user.isSuperUser()) && (
          <Menu.ItemGroup>
            <Menu.Divider style={{ marginBottom: 12 }} />
            {renderMenuLink(adminRoute)}
          </Menu.ItemGroup>
        )}
      </Menu>

      <div style={{ marginTop: 'auto', paddingTop: 48, position: 'relative' }}>
        <div
          style={{
            fontSize: '12px',
            fontWeight: 300,
            marginBottom: '10px',
            paddingLeft: 18,
          }}
        >
          Get better at feedback
        </div>
        <a
          href="https://howamigoing.com/resources"
          style={{
            display: 'inline-block',
            margin: '0 0 56px 16px',
            position: 'relative',
            zIndex: 1,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="light-purple" size="small">
            Resources
          </Button>
        </a>
        <img
          src={ResourcesGraphic}
          alt="Resources"
          style={{ bottom: 0, left: 0, position: 'absolute' }}
        />
      </div>
    </div>
  )
}

export default withRouter(SideMenu)
