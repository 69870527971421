import gql from 'graphql-tag'

export const GET_CYCLE_OVERVIEW = gql`
  query getCycle($cycleId: String!) {
    cycle: getCycle(id: $cycleId) {
      overviewCycleId: id
      reviews {
        id
        user {
          id
          avatar
          avatarUrl
          firstName
          lastName
          email
          jobTitle
        }
        feedbacks {
          id
          submittedAt
          user {
            id
            avatar
            avatarUrl
            firstName
            lastName
            email
            jobTitle
          }
        }
      }
    }
  }
`
