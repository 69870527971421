// Reference: https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCircle,
  faPlusCircle,
  faUsers,
  faCheckCircle as falCheckCircle,
} from '@fortawesome/pro-light-svg-icons'
import {
  faArrowLeft,
  faCheckCircle as fasCheckCircle,
  faQuoteLeft as fasQuoteLeft,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faCircle,
  faUsers,
  falCheckCircle,
  fasCheckCircle,
  faPlusCircle,
  faArrowLeft,
  fasQuoteLeft,
)
