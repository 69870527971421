import { Badge, Button, Card, Icon, Typography } from 'antd'
import unionWith from 'lodash/unionWith'
import React, { useEffect, useState } from 'react'

import BackButton from '../_components/BackButton'
import PreviewForm from '../_components/PreviewForm'
import ColleagueList from './ColleagueList/ColleagueList'

const { Paragraph, Text, Title } = Typography

const DEFAULT_SPACING = 8

export default ({
  visible = true,
  colleagues,
  submitCallback,
  minRequests,
  maxRequests,
  modalForm,
  managersMandatory = false,
}) => {
  const [selectedColleagues, setSelectedColleagues] = useState([])
  const [managers, setManagers] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [colleaguesSubmitted, setColleaguesSubmitted] = useState([])

  useEffect(() => {
    setColleaguesSubmitted(
      colleagues.filter((colleague) => colleague.submitted),
    )
  }, [colleagues])

  useEffect(() => {
    setSelectedColleagues(
      colleagues.concat(
        managers.filter(
          (manager) =>
            !colleagues.find((colleague) => colleague.id === manager.id),
        ),
      ),
    )
  }, [colleagues, managers])

  const selectColleague = (selectedColleague) => {
    const selectedColleagueIndex = selectedColleagues.findIndex((colleague) => {
      return colleague.key === selectedColleague.key
    })

    if (selectedColleagueIndex === -1) {
      setSelectedColleagues([...selectedColleagues, ...[selectedColleague]])
    } else {
      const selectedColleaguesCopy = [...selectedColleagues]
      selectedColleaguesCopy.splice(selectedColleagueIndex, 1)
      setSelectedColleagues(selectedColleaguesCopy)
    }
  }

  const selectManagers = (managers) => {
    setManagers([...managers])
  }

  const handleSelectMultiple = (allColleagues) => {
    const newColleagues = unionWith(
      selectedColleagues,
      allColleagues,
      (firstVal, secondVal) => {
        return firstVal.key === secondVal.key
      },
    )
    setSelectedColleagues(newColleagues)
  }

  const handleDeselectMultiple = (colleagues = false) => {
    if (colleagues) {
      setSelectedColleagues(
        unionWith(
          selectedColleagues.filter(
            (colleague) =>
              !colleagues.find(
                (colleagueItem) => colleagueItem.id === colleague.id,
              ),
          ),
          colleaguesSubmitted,
          managers,
          (firstVal, secondVal) => {
            return firstVal.key === secondVal.key
          },
        ),
      )
    } else {
      setSelectedColleagues([...managers, ...colleaguesSubmitted])
    }
  }

  if (!visible) return <span></span>

  return (
    <div className="get-feedback-request">
      <Card className="top-title" style={{ marginBottom: DEFAULT_SPACING * 3 }}>
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Ask colleagues for feedback
        </Text>
      </Card>

      <Card
        className="top-title"
        style={{ cursor: 'pointer', marginBottom: DEFAULT_SPACING * 3 }}
        onClick={() => setModalVisible(true)}
      >
        <Text
          style={{
            fontFamily: 'Europa, serif',
            color: '#f95c4b',
            fontSize: '16px',
          }}
        >
          <Icon type="eye" style={{ marginRight: DEFAULT_SPACING }} />
          Click here to preview the feedback questions that you'll be getting
          answers on.
        </Text>
      </Card>

      <PreviewForm
        form={modalForm}
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSelect={false}
      />

      <Title
        level={4}
        style={{
          color: '#1c1047',
          marginBottom: DEFAULT_SPACING,
        }}
      >
        Who would you like to receive feedback from?
        <Badge
          showZero
          overflowCount={1000}
          count={selectedColleagues.length}
          style={{
            background: '#f95c4b',
            fontFamily: 'Europa',
            marginLeft: DEFAULT_SPACING,
            fontSize: DEFAULT_SPACING * 2,
          }}
        />
      </Title>

      <Paragraph style={{ fontWeight: 300 }}>
        You need to ask a minimum of {minRequests} and a maximum of{' '}
        {maxRequests} people for feedback. Your manager(s) are automatically
        included in a Team 360 by default.
      </Paragraph>

      <ColleagueList
        selectedColleagues={selectedColleagues}
        selectColleagueCallback={selectColleague}
        managersMandatory={managersMandatory}
        selectManagerCallback={selectManagers}
        withSearch
        showAvatarSelected
        height={340}
        withSelectAll
        handleSelectMultiple={handleSelectMultiple}
        handleDeselectMultiple={handleDeselectMultiple}
      />

      <div style={{ textAlign: 'right' }}>
        <BackButton />
        <Button
          type={'primary'}
          loading={isSubmitting}
          onClick={() => {
            setIsSubmitting(true)
            submitCallback(selectedColleagues)
          }}
          disabled={
            selectedColleagues.length < minRequests ||
            selectedColleagues.length > maxRequests
          }
        >
          Send request
        </Button>
      </div>
    </div>
  )
}
