import React from 'react'
import { Form, Select } from 'antd'

const UsersSearchForm = ({ form, data, handleChange, multiple }) => {
  const { getFieldDecorator } = form

  const filterOption = (input, option) => {
    const user = option.props.children.props.user

    return (
      (user.firstName &&
        user.firstName.toLowerCase().includes(input.toLowerCase())) ||
      (user.lastName &&
        user.lastName.toLowerCase().includes(input.toLowerCase())) ||
      (user.email && user.email.toLowerCase().includes(input.toLowerCase()))
    )
  }

  return (
    <Form>
      {getFieldDecorator('data', {
        rules: [{ required: true, message: 'This field is required!' }],
      })(
        <Select
          onChange={handleChange}
          filterOption={filterOption}
          mode={multiple ? 'multiple' : 'default'}
        >
          {data.map(item => (
            <Select.Option key={item.id}>
              <span user={item}>
                {item.firstName && item.lastName
                  ? `${item.firstName} ${item.lastName}`
                  : item.email}
              </span>
            </Select.Option>
          ))}
        </Select>,
      )}
    </Form>
  )
}

export default Form.create({ name: 'UsersSearchForm' })(UsersSearchForm)
